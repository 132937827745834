<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <!--<v-toolbar-title>{{ $t("OrderListView.page_title") }}</v-toolbar-title>-->
      <v-toolbar-title>
        {{ $t("ReportOrderIgnoredAccepted.page_title") }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-store</v-icon>
    </v-toolbar>
    <v-row justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="$t('ReportOrderIgnoredAccepted.mobileBreakpointSwitch_label')"
          hide-details
        ></v-switch> </v-col
      ><v-col cols="12" md="3">
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          :label="$t('ReportOrderIgnoredAccepted.selectedHeaders_label')"
          multiple
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption"
              >(+{{ selectedHeaders.length - 2 }})</span
            >
          </template>
        </v-select></v-col
      >
    </v-row>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="10">
        <datetime-range-picker filled v-model="dateRange" eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" large @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col></v-row
    >
    <v-data-table
      :loading="loadingData"
      :headers="showHeaders"
      :items="gridItems"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 250, -1],
      }"
      class="elevation-1"
      :search="filter.search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      dense
      :mobile-breakpoint="mobileBreakpointTable"
    >
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td :hidden="actionsHidden">
            <v-icon>mdi-filter</v-icon>
          </td>
          <td :hidden="idHidden">
            <v-text-field
              v-model="filter.id"
              type="text"
              clearable
              :label="$t('ReportSalesCompany.id')"
            ></v-text-field>
          </td>
          <td :hidden="statusHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Status')"
              clearable
              v-model="filter.status"
              :items="Object.values($t('orderStatus'))"
            ></v-select>
          </td>
          <td :hidden="typeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Type')"
              clearable
              v-model="filter.type"
              :items="Object.values($t('orderType'))"
            ></v-select>
          </td>
          <td :hidden="sourceHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Source')"
              clearable
              v-model="filter.source"
              :items="Object.values($t('source'))"
            ></v-select>
          </td>
          <td :hidden="paymentTypeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.PaymentType')"
              clearable
              v-model="filter.paymentType"
              :items="Object.values($t('paymentType'))"
            ></v-select>
          </td>
          <td :hidden="accountIdHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.AccountId')"
              v-model="filter.accountId"
              type="text"
              clearable
            ></v-text-field>
          </td>
          <td :hidden="accountNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.AccountName')"
              v-model="filter.accountName"
              type="text"
              clearable
            ></v-text-field>
          </td>
          <td :hidden="createdAtHidden"></td>
          <td :hidden="acceptedAtHidden"></td>
          <td :hidden="clientIdHidden"></td>
          <td :hidden="clientNicknameHidden"></td>
          <td :hidden="clientPhoneHidden"></td>
          <td :hidden="driverIdHidden"></td>
          <td :hidden="driverFirstNameHidden"></td>
          <td :hidden="driverLastNameHidden"></td>
          <td :hidden="vehicleLpnHidden"></td>
          <td :hidden="vehicleBrandHidden"></td>
          <td :hidden="vehicleModelHidden"></td>
          <td :hidden="boardAddressHidden"></td>
          <td :hidden="destAddressHidden"></td>
          <td :hidden="estimatedDistanceHidden"></td>
          <td :hidden="estimatedPriceTotalHidden"></td>
          <td :hidden="estimatedTimeHidden"></td>
          <td :hidden="finalDistanceHidden"></td>
          <td :hidden="finalTimeHidden"></td>
          <td :hidden="infoDistanceHidden"></td>
          <td :hidden="infoTimeHidden"></td>
          <td :hidden="finalPriceWaitingHidden"></td>
          <td :hidden="finalPriceJourneyHidden"></td>
          <td :hidden="finalPriceExtraHidden"></td>
          <td :hidden="finalPriceDiscountHidden"></td>
          <td :hidden="finalPriceTotalHidden"></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6" :hidden="actionsHidden"></v-col>
          <v-col cols="12" sm="6" :hidden="idHidden">
            <v-text-field
              v-model="filter.id"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportSalesCompany.id')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="statusHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Status')"
              single-line
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.status"
              :items="Object.values($t('orderStatus'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="typeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Type')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.type"
              :items="Object.values($t('orderType'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="sourceHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Source')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.source"
              :items="Object.values($t('source'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="paymentTypeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.PaymentType')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.paymentType"
              :items="Object.values($t('paymentType'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="accountIdHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.AccountId')"
              v-model="filter.accountId"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="accountNameHidden">
            <v-text-field
              :label="$t('OrderListView.filterLabel.AccountName')"
              v-model="filter.accountName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <!--      <template #item="{ item }">
        <tr>
          <td
            style="border-left: 5px solid red"
            v-for="(col, key) in item"
            :key="key"
          >
            {{ item }}
          </td>
        </tr>
      </template>-->
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon
          small
          @click="detail(item.id)"
          @contextmenu.prevent="openInNewTab(item.id)"
          >fa-eye</v-icon> -->
        <v-icon small @click="previewItem(item.id)"
          >mdi-information-variant</v-icon
        >
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{
          item.createdAt ? $options.filters.formatDateLong(item.createdAt) : "-"
        }}
      </template>

      <template v-slot:[`item.acceptedAt`]="{ item }">
        {{
          item.acceptedAt
            ? $options.filters.formatDateLong(item.acceptedAt)
            : "-"
        }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getStatusColor(item.status)"
          :class="
            getStatusColorDark(item.status) == true
              ? 'ma-1 pa-1 white--text'
              : 'ma-1 pa-1 black--text'
          "
          label
          small
          :title="$t('orderStatus_tooltip.' + item.status)"
        >
          {{ $t("orderStatus." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          :title="$t('orderType_tooltip.' + item.type)"
          :color="getTypeColor(item.type)"
          dark
          label
          small
        >
          {{ $t("orderType." + item.type) }}
        </v-chip>
      </template>

      <template v-slot:[`item.paymentType`]="{ item }">
        <v-chip
          :title="$t('paymentType_tooltip.' + item.paymentType)"
          :color="getPaymentTypeColor(item.paymentType)"
          dark
          label
          small
        >
          {{ $t("paymentType." + item.paymentType) }}
        </v-chip>
      </template>

      <template v-slot:[`item.source`]="{ item }">
        <v-chip
          :title="$t('source_tooltip.' + item.source)"
          :color="SourceColor[item.source]"
          dark
          label
          small
        >
          {{ $t("source." + item.source) }}
        </v-chip>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <span
          :title="$t('OrderListView.Id_tooltip', { msg: item.id })"
          dark
          label
        >
          {{ item.id.slice(0, 8) }}
        </span>
      </template>

      <template v-slot:[`item.destinationAddress`]="{ item }">
        <span dark label class="text-wrap">
          {{ item.destinationAddress }}
        </span>
      </template>

      <template v-slot:[`item.boardAddress`]="{ item }">
        <span dark label class="text-wrap">
          {{ item.boardAddress }}
        </span>
      </template>
    </v-data-table>
    <v-spacer></v-spacer>
    <br />
    <!--<download-excel
      :data="gridItems"
      worksheet="txm_export"
      name="txm_expor.xls"
      ><v-btn color="info" outlined> Download Data</v-btn>
    </download-excel>-->
    <export-data
      :jsonData="gridItems"
      :jsonFields="jsonFields"
      :outlined="true"
      dense
    >
    </export-data>
    <!-- dialog s detailom objednavky -->
    <v-dialog v-model="detailDialog" eager max-width="90vw">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("OrderListView.detailDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <template>
                <v-chip dark label class="ma-1 pa-1">
                  {{ item.id }}
                </v-chip>
              </template>
              <template>
                <v-chip
                  dark
                  :color="getSourceColor(item.source)"
                  class="ma-1 pa-1"
                  label
                  v-show="item.source != ''"
                >
                  {{ $t("source." + item.source) }}
                </v-chip>
              </template>
              <template>
                <v-chip
                  dark
                  :color="getTypeColor(item.type)"
                  class="ma-1 pa-1"
                  label
                  :title="$t('orderType_tooltip.' + item.type)"
                  v-show="item.type != ''"
                >
                  {{ $t("orderType." + item.type) }}
                </v-chip>
              </template>
              <v-chip
                dark
                label
                class="ma-1 pa-1"
                :title="
                  $t('ConsoleOrderDetail_tooltip.createdAtChip') +
                  item.createdAt
                "
                v-show="item.createdAt != null"
              >
                {{ item.createdAt | formatDateLong }}
              </v-chip>
              <template>
                <v-chip
                  :class="
                    getStatusColorDark(item.status) == true
                      ? 'ma-1 pa-1 white--text'
                      : 'ma-1 pa-1 black--text'
                  "
                  :color="getStatusColor(item.status)"
                  label
                  v-show="item.status != ''"
                  :title="
                    $t('orderStatus_tooltip.' + item.status) +
                    ' | ' +
                    $t('ConsoleOrderDetail_tooltip.changeStatusAtChip')
                  "
                >
                  {{ $t("orderStatus." + item.status) }}
                </v-chip>
              </template>
              <template>
                <v-chip
                  :color="getPaymentTypeColor(item.paymentType)"
                  class="ma-1 pa-1"
                  label
                  dark
                  v-show="item.paymentType != ''"
                  :title="$t('paymentType_tooltip.' + item.paymentType)"
                >
                  {{ $t("paymentType." + item.paymentType) }}
                </v-chip>
              </template>
            </v-col>
          </v-row>
          <v-row v-if="item.account" dense>
            <v-col cols="12">
              <span>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.account')"
                  >mdi-briefcase-variant</v-icon
                >
                {{ $t("ConsoleOrderDetail.account") }}
                {{ item.account.id }} -
                {{ item.account.name }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="item.client" dense>
            <v-col cols="12">
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.client')"
                >mdi-human-greeting</v-icon
              >
              {{ !item.client ? $t("ConsoleOrderDetail.clientNone") : "" }}
              {{
                item.client
                  ? !item.client.id
                    ? $t("ConsoleOrderDetail.clientNone")
                    : ""
                  : ""
              }}

              {{
                item.client
                  ? item.client.nickname
                    ? item.client.nickname
                    : ""
                  : ""
              }}

              {{
                item.client
                  ? item.client.mark
                    ? " | " + $t("clientMark." + item.client.mark)
                    : ""
                  : ""
              }}

              {{
                item.client
                  ? item.client.phone
                    ? " | " + item.client.phone
                    : ""
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row v-if="item.driver" dense>
            <v-col cols="12">
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.driver')"
                >fas fa-user-tie</v-icon
              >
              {{ !item.driver ? $t("ConsoleOrderDetail.driverNone") : "" }}
              {{
                item.driver
                  ? item.driver.firstName
                    ? item.driver.firstName
                    : ""
                  : ""
              }}
              {{
                item.driver
                  ? item.driver.lastName
                    ? item.driver.lastName
                    : ""
                  : ""
              }}

              {{ item.vehicle ? (item.vehicle.id ? " | " : "") : "" }}
              {{
                item.vehicle
                  ? item.vehicle.internalName
                    ? item.vehicle.internalName
                    : ""
                  : ""
              }}
              {{
                item.vehicle
                  ? item.vehicle.brand
                    ? item.vehicle.brand
                    : ""
                  : ""
              }}
              {{
                item.vehicle
                  ? item.vehicle.model
                    ? item.vehicle.model
                    : ""
                  : ""
              }}
              {{
                item.preferredDriver
                  ? item.preferredDriver.id
                    ? " | &#9825;"
                    : ""
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row v-if="item.note" dense>
            <v-col cols="12">
              <span>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.note')"
                  >far fa-file-alt</v-icon
                >
                {{ $t("ConsoleOrderDetail.note") }}
                {{ item.note }}
              </span>
            </v-col>
          </v-row>
          <v-divider :inset="false"></v-divider>
          <v-list>
            <template v-for="(waypoint, index2) in item.waypointList">
              <v-icon
                :key="waypoint.id"
                :color="waypoint.status == 'COMPLETED' ? 'green' : 'gray'"
                >{{ addressIcons[waypoint.type] }}</v-icon
              >
              <span
                :key="'W' + waypoint.id"
                class="d-inline-block"
                :style="[
                  waypoint.status == 'DISABLED'
                    ? {
                        'text-decoration': 'line-through',
                      }
                    : waypoint.type == 'BOARD' || waypoint.type == 'DESTINATION'
                    ? { 'font-weight': 'bold' }
                    : {},
                ]"
                >{{ waypoint.address }}</span
              >
              <v-divider
                v-if="index2 < item.waypointList.length - 1"
                :key="index2"
                :inset="false"
              ></v-divider>
            </template>
          </v-list>
          <v-divider :inset="false"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.price')"
                >mdi-cash-multiple</v-icon
              >{{ $t("ConsoleOrderDetail.price") }} :
              {{
                item.estimatedPriceTotal
                  ? $t("ConsoleOrderDetail.priceEstimated") +
                    " " +
                    item.estimatedPriceTotal +
                    (item.currency ? item.currency : " ")
                  : " "
              }}
              {{
                item.finalPriceTotal
                  ? " | " +
                    $t("ConsoleOrderDetail.priceFinal") +
                    " " +
                    item.finalPriceTotal +
                    (item.currency ? item.currency : " ")
                  : " "
              }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click.stop="detailDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import ExportData from "../components/ExportData.vue";
import {
  Status,
  Type,
  Source,
  PaymentType,
  PaymentTypeColor,
  StatusColor,
  StatusColorDarkContrast,
  TypeColor,
  SourceColor,
} from "../enums/OrderEnum";

export default {
  components: {
    ExportData,
  },
  data() {
    return {
      module: moduleDefaultSettings,
      Status,
      Type,
      Source,
      PaymentType,
      PaymentTypeColor,
      StatusColor,
      StatusColorDarkContrast,
      TypeColor,
      SourceColor,

      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,

      //dialog s detailom objednavky
      detailDialog: false,
      //objekt s datami pre detail objednavky
      item: {
        id: "",
        status: "",
        type: "",
        note: "",
        createdAt: null,
      },
      addressIcons: {
        BOARD: "fas fa-street-view",
        DESTINATION: "fas fa-flag-checkered",
        TRANSIT: "mdi-transit-connection-variant",
      },

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        id: "",
        type: "",
        status: "",
        note: "",
        driverId: "",
        boardAddress: "",
        destinationAddress: "",
        createdAt: "",
        acceptedAt: "",
        source: "",
        paymentType: "",
        accountId: "",
        accountName: "",
      },

      // gridHeaders: [
      //   {
      //     text: "Id",
      //     align: "start",
      //     sortable: false,
      //     value: "id",
      //   },
      //   { text: this.$t("Status"), value: "status" },
      //   { text: this.$t("Type"), value: "type" },
      //   { text: this.$t("Note"), value: "note" },
      //   {
      //     text: this.$t("CreatedAt"),
      //     value: "createdAt",
      //   },
      // ],
      gridItems: [],
      headers: [],
      selectedHeaders: [],
      headersList: [], //lista stlpcov pre vyber stlpcov
      sortBy: null,
      sortDesc: true,
      defaultHiddenColumns: [
        "accountId",
        "clientId",
        "driverId",
        "infoDistance",
        "infoTime",
        "estimatedDistance",
        "estimatedTime",
        "clientPhone",
        "createdAt",
        "vehicleLpn",
        "vehicleBrand",
        "vehicleModel",
        "estimatedPriceTotal",
        "infoDistance",
        "infoTime",
        "finalTime",
        "finalPriceWaiting",
        "finalPriceJourney",
        "finalPriceExtra",
        "finalPriceDiscount",
      ],
      slotList: [
        "actions",
        "id",
        "status",
        "type",
        "source",
        "paymentType",
        "accountId",
        "accountName",
        "createdAt",
        "acceptedAt",
        "clientId",
        "clientNickname",
        "clientPhone",
        "driverId",
        "driverFirstName",
        "driverLastName",
        "vehicleLpn",
        "vehicleBrand",
        "vehicleModel",
        "boardAddress",
        "destAddress",
        "estimatedDistance",
        "estimatedPriceTotal",
        "estimatedTime",
        "finalDistance",
        "finalTime",
        "infoDistance",
        "infoTime",
        "finalPriceWaiting",
        "finalPriceJourney",
        "finalPriceExtra",
        "finalPriceDiscount",
        "finalPriceTotal",
      ],
      //nazvy stlpcov v tabulke, aby sa skryvali filtrovacie polia
      actionsHidden: false,
      idHidden: false,
      statusHidden: false,
      typeHidden: false,
      sourceHidden: false,
      paymentTypeHidden: false,
      accountIdHidden: false,
      accountNameHidden: false,
      createdAtHidden: false,
      acceptedAtHidden: false,
      clientIdHidden: false,
      clientNicknameHidden: false,
      clientPhoneHidden: false,
      driverIdHidden: false,
      driverFirstNameHidden: false,
      driverLastNameHidden: false,
      vehicleLpnHidden: false,
      vehicleBrandHidden: false,
      vehicleModelHidden: false,
      boardAddressHidden: false,
      destAddressHidden: false,
      estimatedDistanceHidden: false,
      estimatedPriceTotalHidden: false,
      estimatedTimeHidden: false,
      finalDistanceHidden: false,
      finalTimeHidden: false,
      infoDistanceHidden: false,
      infoTimeHidden: false,
      finalPriceWaitingHidden: false,
      finalPriceJourneyHidden: false,
      finalPriceExtraHidden: false,
      finalPriceDiscountHidden: false,
      finalPriceTotalHidden: false,

      //Zoznam stlpcov pre export do excelu
      jsonFields: {
        ID: { field: "id" },
        STATUS: { field: "status" },
        TYPE: { field: "type" },
        SOURCE: { field: "source" },
        PAYMENT_TYPE: { field: "paymentType" },
        ACCOUNT_ID: { field: "accountId" },
        ACCOUNT_NAME: { field: "accountName" },
        CREATED_AT: { field: "createdAt", type: "datetime" },
        ACCEPTED_AT: { field: "acceptedAt", type: "datetime" },
        CLIENT_ID: { field: "clientId" },
        CLIENT_NICKNAME: { field: "clientNickname" },
        CLIENT_PHONE: { field: "clientPhone", type: "string" },
        DRIVER_ID: { field: "driverId" },
        DRIVER_FIRST_NAME: { field: "driverFirstName" },
        DRIVER_LAST_NAME: { field: "driverLastName" },
        VEHICLE_LPN: { field: "vehicleLpn" },
        VEHICLE_BRAND: { field: "vehicleBrand" },
        VEHICLE_MODEL: { field: "vehicleModel" },
        BOARD_ADDRESS: { field: "boardAddress" },
        DEST_ADDRESS: { field: "destAddress" },
        ESTIMATED_DISTANCE: { field: "estimatedDistance" },
        ESTIMATED_PRICE_TOTAL: {
          field: "estimatedPriceTotal",
          type: "decimal",
        },
        ESTIMATED_TIME: { field: "estimatedTime" },
        FINAL_DISTANCE: { field: "finalDistance" },
        FINAL_TIME: { field: "finalTime" },
        INFO_DISTANCE: { field: "infoDistance" },
        INFO_TIME: { field: "infoTime" },
        FINAL_PRICE_WAITING: { field: "finalPriceWaiting", type: "decimal" },
        FINAL_PRICE_JOURNEY: { field: "finalPriceJourney", type: "decimal" },
        FINAL_PRICE_EXTRA: { field: "finalPriceExtra", type: "decimal" },
        FINAL_PRICE_DISCOUNT: { field: "finalPriceDiscount", type: "decimal" },
        FINAL_PRICE_TOTAL: { field: "finalPriceTotal", type: "decimal" },
      },

      loadingData: false,
      //DateRangePicker - vyber rozhadu datumov pre zoznam
      dateRange: [], //["2021-07-22","2021-07-24"],
      distance: 2500,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    var headersMap = this.headersMap;
    this.headers = Object.values(headersMap);
    //Potrebujeme sa zbavit hodnoty "divide" v headersList. Ale upravu som nepozil vo v-select, musel som hodnotu odstranit z headerMap. Doplnala do v-select oddelovace miesto textu. Mozno sa este zamyslim co s tym urobit.
    //divide bolo treba za ucelom formatovania v tabulke, aby boli oddlene stlpce ciarami
    /*headersMap.forEach((header) => {
      this.headersList.push({
        text: header.text,
        value: header.value,
      });
    });*/
    //this.headersList = Object.values(headersMap);

    this.selectedHeaders = this.headers;

    //Zistenie zoznamu skrytych stlpcov z Cookies
    var tempHiddenColumns = this.getCookie(
      "actualHiddenColumnsReportSalesCompany"
    )
      ? JSON.parse(this.getCookie("actualHiddenColumnsReportSalesCompany"))
      : [];
    //ak existuje cookie, nacitat ulozenu hodnotu (zoznam stlpcov, ktore sa maju skryt)
    if (tempHiddenColumns.length > 0) {
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !tempHiddenColumns.includes(el.value)
      );
    } else {
      //ak neexistuje cookie, nastavit defaultne hodnoty
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !this.defaultHiddenColumns.includes(el.value)
      );
    }
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //console.log("dispatcherId", dispatcherId);
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //console.log("mounted - this.dateRange", this.dateRange);

    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }

    //Niekedy pri prvom nacitany stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },

  watch: {
    showHeaders: function () {
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      var tempShowHearders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          tempShowHearders.some(function (e) {
            return e.value == item;
          })
        ) {
          this[item + "Hidden"] = false;
        } else {
          this[item + "Hidden"] = true;
        }
      });
    },
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  computed: {
    showHeaders() {
      //console.log("computed - this.selectedHeaders", this.selectedHeaders);
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },

    headersMap() {
      return [
        {
          text: this.$t("ReportSalesCompany.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("ReportSalesCompany.id"),
          align: "start",
          sortable: true,
          value: "id",
          filter: (value) => {
            if (!this.filter.id) {
              return value;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.date.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.Status"),
          value: "status",
          filter: (value) => {
            if (!this.filter.status) {
              return true;
            }
            return this.$t("orderStatus." + value).includes(this.filter.status);
          },
        },
        {
          text: this.$t("OrderListView.Type"),
          value: "type",
          filter: (value) => {
            if (!this.filter.type) {
              return true;
            }
            return this.$t("orderType." + value)
              .toLowerCase()
              .includes(this.filter.type.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.Source"),
          value: "source",
          filter: (value) => {
            if (!this.filter.source) {
              return true;
            }
            return this.$t("source." + value)
              .toLowerCase()
              .includes(this.filter.source.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.PaymentType"),
          value: "paymentType",
          filter: (value) => {
            if (!this.filter.paymentType) {
              return true;
            }
            return this.$t("paymentType." + value)
              .toLowerCase()
              .includes(this.filter.paymentType.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.AccountId"),
          align: "start",
          sortable: true,
          value: "accountId",
          filter: (value) => {
            if (!this.filter.accountId) return true;
            if (value) return value === parseInt(this.filter.accountId);
          },
        },
        {
          text: this.$t("OrderListView.AccountName"),
          align: "start",
          sortable: true,
          value: "accountName",
          filter: (value) => {
            if (!this.filter.accountName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.accountName.toLowerCase());
            }
          },
        },

        {
          text: this.$t("OrderListView.CreatedAt"),
          value: "createdAt",
          filter: (value) => {
            if (!this.filter.createdAt) {
              return true;
            }
            return value
              .toLowerCase()
              .includes(this.filter.createdAt.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.AcceptedAt"),
          value: "acceptedAt",
          filter: (value) => {
            if (!this.filter.acceptedAt) {
              return true;
            }
            return value
              .toLowerCase()
              .includes(this.filter.acceptedAt.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.ClientId"),
          //value: "driver.id",
          value: "clientId",
          filter: (value) => {
            if (!this.filter.clientId) return true;
            if (value) return value === parseInt(this.filter.clientId);
          },
        },
        {
          text: this.$t("OrderListView.ClientNickname"),
          align: "start",
          sortable: true,
          value: "clientNickname",
          filter: (value) => {
            if (!this.filter.clientNickname) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.clientNickname.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.ClientPhone"),
          align: "start",
          sortable: true,
          value: "clientPhone",
          filter: (value) => {
            if (!this.filter.clientPhone) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.clientPhone.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.DriverId"),
          //value: "driver.id",
          value: "driverId",
          filter: (value) => {
            if (!this.filter.driverId) return true;
            if (value) return value === parseInt(this.filter.driverId);
          },
        },
        {
          text: this.$t("OrderListView.DriverFirstName"),
          align: "start",
          sortable: true,
          value: "driverFirstName",
          filter: (value) => {
            if (!this.filter.driverFirstName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.driverFirstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.DriverLastName"),
          align: "start",
          sortable: true,
          value: "driverLastName",
          filter: (value) => {
            if (!this.filter.driverLastName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.driverLastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.VehicleLpn"),
          //value: "driver.id",
          value: "vehicleLpn",
          filter: (value) => {
            if (!this.filter.vehicleLpn) return true;
            if (value) return value.id === parseInt(this.filter.vehicleLpn);
          },
        },
        {
          text: this.$t("OrderListView.VehicleBrand"),
          align: "start",
          sortable: true,
          value: "vehicleBrand",
          filter: (value) => {
            if (!this.filter.vehicleBrand) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.vehicleBrand.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.VehicleModel"),
          align: "start",
          sortable: true,
          value: "vehicleModel",
          filter: (value) => {
            if (!this.filter.vehicleModel) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.vehicleModel.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.boardAddress"),
          sortable: false,
          value: "boardAddress",
          filter: (value) => {
            if (!this.filter.boardAddress) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.boardAddress.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.destinationAddress"),
          sortable: false,
          value: "destAddress",
          filter: (value) => {
            if (!this.filter.destAddress) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.destAddress.toLowerCase());
            }
          },
        },

        {
          text: this.$t("OrderListView.EstimatedDistance"),
          value: "estimatedDistance",
        },
        {
          text: this.$t("OrderListView.EstimatedPriceTotal"),
          value: "estimatedPriceTotal",
        },
        {
          text: this.$t("OrderListView.EstimatedTime"),
          value: "estimatedTime",
        },
        {
          text: this.$t("OrderListView.FinalDistance"),
          value: "finalDistance",
        },
        {
          text: this.$t("OrderListView.FinalTime"),
          value: "finalTime",
        },
        {
          text: this.$t("OrderListView.InfoDistance"),
          value: "infoDistance",
        },
        {
          text: this.$t("OrderListView.InfoTime"),
          value: "infoTime",
        },
        {
          text: this.$t("OrderListView.FinalPriceWaiting"),
          value: "finalPriceWaiting",
        },
        {
          text: this.$t("OrderListView.FinalPriceJourney"),
          value: "finalPriceJourney",
        },
        {
          text: this.$t("OrderListView.FinalPriceExtra"),
          value: "finalPriceExtra",
        },
        {
          text: this.$t("OrderListView.FinalPriceDiscount"),
          value: "finalPriceDiscount",
        },
        {
          text: this.$t("OrderListView.FinalPriceTotal"),
          value: "finalPriceTotal",
        },
      ];
    },
  },

  methods: {
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      //console.log("getAll - dateRange ", this.dateRange);
      //Ulozit si nastavenie rozsahu datumov do cookies
      //this.setCookie("dateRange", JSON.stringify(this.dateRange), 1);
      //console.log("getAll - setCookie - dateRange",JSON.stringify(this.dateRange));
      this.setHiddenColumnListCookie();
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/order-ignored-accepted?from=${this.dateRange[0]}&to=${this.dateRange[1]}&distance=${this.distance}`
        )
        .then((response) => {
          //console.log("response", response);
          this.loadingData = false;
          this.gridItems = response.data;
          //remove duplicates from array by id
          /*this.gridItems = this.gridItems.filter(
            (thing, index, self) =>
              index ===
              self.findIndex((t) => t.id === thing.id && t.id !== undefined)
          );*/
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },
    setHiddenColumnListCookie() {
      //aktualny zoznam poli, ktore sa nemaju zobrazit, ulozit do cookies
      var actualHiddenColumns = [];
      //compare slotList and showHeaders
      var tempShowHeaders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          !tempShowHeaders.some(function (e) {
            return e.value == item;
          })
        ) {
          actualHiddenColumns.push(item);
        }
      });
      this.setCookie(
        "actualHiddenColumnsReportSalesCompany",
        JSON.stringify(actualHiddenColumns),
        30
      );
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getStatusColorDark(status) {
      if (status == undefined) {
        return this.StatusColorDarkContrast["NEW"];
      } else {
        return this.StatusColorDarkContrast[status];
      }
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
    getSourceColor(source) {
      return this.SourceColor[source];
    },
    getPaymentTypeColor(type) {
      return this.PaymentTypeColor[type];
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },

    //Konverzia mesiaca na nazov
    toMonthName(monthNumber) {
      //console.log("toMonthName: " + monthNumber);
      if (isNaN(monthNumber)) {
        //Ak nie je cislo, konvertova na cislo
        monthNumber = parseInt(monthNumber);
      }
      var localeLanguage = this.$store.state.appLocale;
      if (localeLanguage == "en" || localeLanguage == "us") {
        localeLanguage = "en-US";
      } else if (localeLanguage == "cs" || localeLanguage == "cz") {
        localeLanguage = "cs-CZ";
      } else if (localeLanguage == "sk") {
        localeLanguage = "sk-SK";
      } else {
        localeLanguage = "en-US";
      }
      //console.log("localeLanguage", localeLanguage);
      const date = new Date();
      date.setMonth(monthNumber - 1);
      //console.log("date", date);
      return date.toLocaleString(localeLanguage, {
        month: "long",
      });
    },

    previewItem(id) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/order/` +
            id +
            `/history`
        )
        .then((response) => {
          this.item = response.data;
          this.detailDialog = true;
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
          console.log("error", e);
        });
    },
  },
};
</script>
