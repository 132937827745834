<template>
  <div v-if="gridItemsLocalityPrice">
    <v-row>
      <v-col cols="12">
        <shapes-google-map :passDataOnMap="passDataOnMap"></shapes-google-map
      ></v-col>
    </v-row>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("LocalityList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-web</v-icon>
      <template v-slot:extension>
        <v-tabs v-model="tabs" align-with-title center-active show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            key="tab1"
            href="#tab-pickup"
            @click="getItemsLocalityPickup()"
            >{{ $t("LocalityList.tabLocalityPickup") }}</v-tab
          >
          <v-tab
            key="tab2"
            href="#tab-price"
            @click="getItemsLocalityPrice()"
            >{{ $t("LocalityList.tabLocalityPrice") }}</v-tab
          >
          <v-tab
            key="tab3"
            href="#tab-checkin"
            @click="getItemsLocalityCheckin()"
            >{{ $t("LocalityList.tabLocalityCheckin") }}</v-tab
          >
          <v-tab
            key="tab4"
            href="#tab-stand"
            @click="getItemsLocalityStand()"
            >{{ $t("LocalityList.tabStands") }}</v-tab
          >
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs" touchless>
      <!-- TAB1 Locality Pickup-->
      <v-tab-item value="tab-pickup" eager>
        <v-card flat>
          <v-btn
            class="ma-2"
            color="success"
            :disabled="localityPickupCreateButtonDisabled"
            @click.stop="
              (defaultLocalityItemData.type = 'CIRCLE'),
                (defaultLocalityItemData.object = 'PICKUP'),
                viewLocalityOnDialogMap(0, 'CREATE', 'PICKUP')
            "
          >
            {{ $t("LocalityList.btnCreateCircle") }}
          </v-btn>
          <v-chip>
            {{ localityPickupCreateCurrent }}
            /
            {{ localityPickupCreateMax }}
          </v-chip>
          <v-data-table
            :loading="loadingDataLocalityPickup"
            :headers="gridHeaders"
            :items="gridItemsLocalityPickup"
            class="elevation-1"
            sort-by="priority"
            sort-desc
            :items-per-page="5"
            :footer-props="{
              itemsPerPageOptions: [5, 15, 25, 50, 100, -1],
              showFirstLastPage: true,
            }"
          >
            <template v-slot:no-data>
              <v-btn color="error" @click="getItemsLocalityPickup()">{{
                $t("btnReload")
              }}</v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-icon
                  small
                  @click="viewLocalityOnDialogMap(item.id, 'VIEW', 'PICKUP')"
                  >fa-eye</v-icon
                >
                <v-icon
                  small
                  class="ml-2"
                  @click="viewLocalityOnDialogMap(item.id, 'UPDATE', 'PICKUP')"
                  >mdi-square-edit-outline</v-icon
                >
              </div>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-simple-checkbox
                v-model="item.active"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                :title="$t('mapShape.' + item.type)"
                :color="item.type == 'CIRCLE' ? 'blue' : 'orange'"
                dark
                label
                small
              >
                {{ $t("mapShape." + item.type) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-price" eager>
        <!-- TAB2 Locality Price-->
        <v-card flat>
          <div style="display: flex; align-items: center" class="my-2">
            <v-btn
              class="ma-2"
              color="success"
              :disabled="localityPriceCreateButtonDisabled"
              @click.stop="
                (defaultLocalityItemData.type = 'CIRCLE'),
                  (defaultLocalityItemData.object = 'PRICE'),
                  viewLocalityOnDialogMap(0, 'CREATE', 'PRICE')
              "
            >
              {{ $t("LocalityList.btnCreateCircle") }}
            </v-btn>
            <v-btn
              class="ma-2"
              color="success"
              :disabled="localityPriceCreateButtonDisabled"
              @click.stop="
                (defaultLocalityItemData.type = 'POLYGON'),
                  (defaultLocalityItemData.object = 'PRICE'),
                  viewLocalityOnDialogMap(0, 'CREATE', 'PRICE')
              "
            >
              {{ $t("LocalityList.btnCreatePolygon") }}
            </v-btn>
            <v-chip>
              {{ localityPriceCreateCurrent }}
              /
              {{ localityPriceCreateMax }}
            </v-chip>
            <!-- v-switch to display only active -->
            <v-switch
              class="pl-8 mt-0"
              :label="$t('LocalityList.activeOnlySwitch')"
              v-model="localityPriceActiveOnly"
              @change="getItemsLocalityPrice()"
              dense
              hide-details
            ></v-switch>
            <v-spacer></v-spacer>
          </div>
          <v-data-table
            :loading="loadingDataLocalityPrice"
            :headers="gridHeadersLocalityPrice"
            :items="gridItemsLocalityPrice"
            class="elevation-1"
            sort-by="priority"
            sort-desc
            :items-per-page="50"
            :footer-props="{
              itemsPerPageOptions: [15, 25, 50, 100, -1],
              showFirstLastPage: true,
            }"
          >
            <template v-slot:no-data>
              <v-btn color="error" @click="getItemsLocalityPrice()">{{
                $t("btnReload")
              }}</v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-icon
                  small
                  @click="viewLocalityOnDialogMap(item.id, 'VIEW', 'PRICE')"
                  >fa-eye</v-icon
                >
                <v-icon
                  small
                  class="ml-2"
                  @click="viewLocalityOnDialogMap(item.id, 'UPDATE', 'PRICE')"
                  >mdi-square-edit-outline</v-icon
                >
                <v-icon
                  small
                  class="ml-2"
                  @click="
                    viewLocalityOnDialogEditPoints(item.id, 'UPDATE', 'PRICE')
                  "
                  v-show="item.type == 'POLYGON' && displayOnlyForAdmin"
                  >mdi-vector-polyline-edit</v-icon
                >
              </div>
            </template>

            <template v-slot:[`item.latitude`]="{ item }">
              {{
                item.type == "CIRCLE" || displayOnlyForAdmin
                  ? item.latitude
                  : "-"
              }}
            </template>
            <template v-slot:[`item.longitude`]="{ item }">
              {{
                item.type == "CIRCLE" || displayOnlyForAdmin
                  ? item.longitude
                  : "-"
              }}
            </template>
            <template v-slot:[`item.radius`]="{ item }">
              {{
                item.type == "CIRCLE" || displayOnlyForAdmin ? item.radius : "-"
              }}
            </template>
            <template v-slot:[`item.path`]="{ item }">
              {{
                item.type == "POLYGON" || displayOnlyForAdmin ? item.path : "-"
              }}
            </template>

            <template v-slot:[`item.active`]="{ item }">
              <v-simple-checkbox
                v-model="item.active"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                :title="$t('mapShape.' + item.type)"
                :color="item.type == 'CIRCLE' ? 'blue' : 'orange'"
                dark
                label
                small
              >
                {{ $t("mapShape." + item.type) }}
              </v-chip>
            </template>
            <template v-slot:[`item.showDriverPhoneButton`]="{ item }">
              <v-chip
                label
                :title="
                  $t('showDriverPhoneButton.' + item.showDriverPhoneButton)
                "
              >
                {{
                  $t("showDriverPhoneButtonShort." + item.showDriverPhoneButton)
                }}
              </v-chip>
            </template>
            <template v-slot:[`item.showPhone`]="{ item }">
              <v-simple-checkbox
                v-model="item.showPhone"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.showAllAddresses`]="{ item }">
              <v-simple-checkbox
                v-model="item.showAllAddresses"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.onlyManualSending`]="{ item }">
              <v-simple-checkbox
                v-model="item.onlyManualSending"
                disabled
              ></v-simple-checkbox>
            </template>
            <!-- Podla standId vybrat zo zoznamu dropdownStand hodnotu "name", prvych 10 znakov, ak je viac tak ... a do title cely text "name" -->
            <template v-slot:[`item.standId`]="{ item }">
              <v-chip
                label
                :title="
                  item.standId
                    ? item.standId +
                      ' - ' +
                      (dropdownStand.find((stand) => stand.id == item.standId)
                        ?.name || '')
                    : ''
                "
                v-if="item.standId"
              >
                {{
                  dropdownStand.find((stand) => stand.id == item.standId)?.name
                    .length > 10
                    ? dropdownStand
                        .find((stand) => stand.id == item.standId)
                        ?.name.substring(0, 8) + "..."
                    : dropdownStand.find((stand) => stand.id == item.standId)
                        ?.name
                }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-checkin">
        <!-- TAB3 Locality Checkin -->
        <v-card flat>
          <v-btn
            class="ma-2"
            color="success"
            :disabled="localityCheckinCreateButtonDisabled"
            @click.stop="
              (defaultLocalityItemData.type = 'CIRCLE'),
                (defaultLocalityItemData.object = 'CHECKIN'),
                viewLocalityOnDialogMap(0, 'CREATE', 'CHECKIN')
            "
          >
            {{ $t("LocalityList.btnCreateCircle") }}
          </v-btn>
          <v-btn
            class="ma-2"
            color="success"
            :disabled="localityCheckinCreateButtonDisabled"
            @click.stop="
              (defaultLocalityItemData.type = 'POLYGON'),
                (defaultLocalityItemData.object = 'CHECKIN'),
                viewLocalityOnDialogMap(0, 'CREATE', 'CHECKIN')
            "
          >
            {{ $t("LocalityList.btnCreatePolygon") }}
          </v-btn>
          <v-chip>
            {{ localityCheckinCreateCurrent }}
            /
            {{ localityCheckinCreateMax }}
          </v-chip>
          <v-data-table
            :loading="loadingDataLocalityCheckin"
            :headers="gridHeadersCheckin"
            :items="gridItemsLocalityCheckin"
            class="elevation-1"
            sort-by="priority"
            sort-desc
            :items-per-page="50"
            :footer-props="{
              itemsPerPageOptions: [15, 25, 50, 100, -1],
              showFirstLastPage: true,
            }"
          >
            <template v-slot:no-data>
              <v-btn color="error" @click="getItemsLocalityCheckin()">{{
                $t("btnReload")
              }}</v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-icon
                  small
                  @click="viewLocalityOnDialogMap(item.id, 'VIEW', 'CHECKIN')"
                  >fa-eye</v-icon
                >
                <v-icon
                  small
                  class="ml-2"
                  @click="viewLocalityOnDialogMap(item.id, 'UPDATE', 'CHECKIN')"
                  >mdi-square-edit-outline</v-icon
                >
              </div>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-simple-checkbox
                v-model="item.active"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                :title="$t('mapShape.' + item.type)"
                :color="item.type == 'CIRCLE' ? 'blue' : 'orange'"
                dark
                label
                small
              >
                {{ $t("mapShape." + item.type) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-stand">
        <!-- TAB4 Stands -->
        <v-card flat>
          <v-btn
            class="ma-2"
            color="success"
            :disabled="localityStandCreateButtonDisabled"
            @click.stop="
              (defaultLocalityItemData.type = 'CIRCLE'),
                (defaultLocalityItemData.object = 'STAND'),
                viewLocalityOnDialogMap(0, 'CREATE', 'STAND')
            "
          >
            {{ $t("LocalityList.btnCreateCircle") }}
          </v-btn>
          <v-chip>
            {{ localityStandCreateCurrent }}
            /
            {{ localityStandCreateMax }}
          </v-chip>
          <v-data-table
            :loading="loadingDataLocalityStand"
            :headers="gridHeadersStand"
            :items="gridItemsLocalityStand"
            class="elevation-1"
            sort-by="priority"
            sort-desc
            :items-per-page="15"
            :footer-props="{
              itemsPerPageOptions: [15, 25, 50, 100, -1],
              showFirstLastPage: true,
            }"
          >
            <template v-slot:no-data>
              <v-btn color="error" @click="getItemsLocalityStand()">{{
                $t("btnReload")
              }}</v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <v-icon
                  small
                  @click="viewLocalityOnDialogMap(item.id, 'VIEW', 'STAND')"
                  >fa-eye</v-icon
                >
                <v-icon
                  small
                  class="ml-2"
                  @click="viewLocalityOnDialogMap(item.id, 'UPDATE', 'STAND')"
                  >mdi-square-edit-outline</v-icon
                >
              </div>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-simple-checkbox
                v-model="item.active"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                :title="$t('mapShape.' + item.type)"
                :color="item.type == 'CIRCLE' ? 'blue' : 'orange'"
                dark
                label
                small
              >
                {{ $t("mapShape." + item.type) }}
              </v-chip>
            </template>
            <template v-slot:[`item.defaultStand`]="{ item }">
              <v-simple-checkbox
                v-model="item.defaultStand"
                disabled
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="showDialog"
      transition="dialog-top-transition"
      max-width="600px"
      eager
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{ $t("LocalityList.dialogTitle") }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <!--<v-form ref="formEditSupport" v-model="formEditSupportValidation">-->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"
                ><div id="dialogMap" ref="dialogMap"></div
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col v-show="displayOnlyForAdmin" cols="6" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.id')"
                  filled
                  dense
                  v-model="updatedLocalityItemData.id"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col v-show="displayOnlyForAdmin" cols="6" class="py-0">
                <v-checkbox
                  :label="$t('LocalityList.showMarkers')"
                  v-model="showMarkers"
                  @change="displayMarkersOnMap()"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.name')"
                  filled
                  dense
                  v-model="updatedLocalityItemData.name"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editDialogFields"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.priority')"
                  v-if="
                    updatedLocalityItemData.object != 'PICKUP' &&
                    updatedLocalityItemData.object != 'STAND'
                  "
                  filled
                  dense
                  v-model="updatedLocalityItemData.priority"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  type="number"
                  :disabled="!editDialogFields"
                ></v-text-field>
                <v-text-field
                  :label="$t('LocalityList.capacity')"
                  v-if="updatedLocalityItemData.object == 'STAND'"
                  filled
                  dense
                  v-model="updatedLocalityItemData.capacity"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  type="number"
                  :disabled="!editDialogFields"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  :label="$t('LocalityList.active')"
                  v-model="updatedLocalityItemData.active"
                  dense
                  required
                  filled
                  :disabled="!editDialogFields"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-select
                  :label="$t('LocalityList.type')"
                  v-if="updatedLocalityItemData.object != 'STAND'"
                  dense
                  v-model="updatedLocalityItemData.type"
                  :items="$t('mapShapeArray')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editDialogFields"
                ></v-select>
                <v-checkbox
                  v-model="updatedLocalityItemData.defaultStand"
                  :label="$t('LocalityList.defaultStand')"
                  v-if="updatedLocalityItemData.object == 'STAND'"
                  dense
                  required
                  filled
                  :disabled="!editDialogFields"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.latitude')"
                  filled
                  dense
                  v-model="updatedLocalityItemData.latitude"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!(displayOnlyForAdmin && editDialogFields)"
                  v-show="
                    updatedLocalityItemData.type == 'CIRCLE' ||
                    displayOnlyForAdmin
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.longitude')"
                  filled
                  dense
                  v-model="updatedLocalityItemData.longitude"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!(displayOnlyForAdmin && editDialogFields)"
                  v-show="
                    updatedLocalityItemData.type == 'CIRCLE' ||
                    displayOnlyForAdmin
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.radius')"
                  filled
                  dense
                  type="number"
                  suffix="m"
                  v-model="updatedLocalityItemData.radius"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!(displayOnlyForAdmin && editDialogFields)"
                  v-show="
                    updatedLocalityItemData.type == 'CIRCLE' ||
                    displayOnlyForAdmin
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.distance')"
                  v-if="updatedLocalityItemData.object == 'CHECKIN'"
                  filled
                  dense
                  type="number"
                  suffix="m"
                  v-model="updatedLocalityItemData.distance"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editDialogFields"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.path')"
                  v-if="
                    updatedLocalityItemData.object != 'PICKUP' &&
                    updatedLocalityItemData.object != 'STAND'
                  "
                  filled
                  dense
                  v-model="updatedLocalityItemData.path"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!(displayOnlyForAdmin && editDialogFields)"
                  v-show="
                    updatedLocalityItemData.type == 'POLYGON' ||
                    displayOnlyForAdmin
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="updatedLocalityItemData.object == 'STAND'">
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.internalName')"
                  v-if="updatedLocalityItemData.object == 'STAND'"
                  filled
                  dense
                  v-model="updatedLocalityItemData.internalName"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editDialogFields"
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.city')"
                  v-if="updatedLocalityItemData.object == 'STAND'"
                  filled
                  dense
                  v-model="updatedLocalityItemData.city"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editDialogFields"
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.country')"
                  v-if="updatedLocalityItemData.object == 'STAND'"
                  filled
                  dense
                  v-model="updatedLocalityItemData.country"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editDialogFields"
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.street1')"
                  v-if="updatedLocalityItemData.object == 'STAND'"
                  filled
                  dense
                  v-model="updatedLocalityItemData.street1"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editDialogFields"
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.street2')"
                  v-if="updatedLocalityItemData.object == 'STAND'"
                  filled
                  dense
                  v-model="updatedLocalityItemData.street2"
                  :disabled="!editDialogFields"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row v-if="updatedLocalityItemData.object == 'PRICE'">
              <v-col cols="6" class="py-0">
                <v-select
                  :label="$t('LocalityList.showDriverPhoneButton')"
                  dense
                  v-model="updatedLocalityItemData.showDriverPhoneButton"
                  :items="$t('showDriverPhoneButtonArray')"
                ></v-select>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  :label="$t('LocalityList.showPhone')"
                  v-model="updatedLocalityItemData.showPhone"
                  v-if="updatedLocalityItemData.object == 'PRICE'"
                  dense
                  filled
                  :disabled="!editDialogFields"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  :label="$t('LocalityList.showAllAddresses')"
                  v-model="updatedLocalityItemData.showAllAddresses"
                  v-if="updatedLocalityItemData.object == 'PRICE'"
                  dense
                  filled
                  :disabled="!editDialogFields"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  :label="$t('LocalityList.onlyManualSending')"
                  v-model="updatedLocalityItemData.onlyManualSending"
                  v-if="updatedLocalityItemData.object == 'PRICE'"
                  dense
                  filled
                  :disabled="!editDialogFields"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.forcedPickupRadius')"
                  dense
                  v-model="updatedLocalityItemData.forcedPickupRadius"
                  v-if="updatedLocalityItemData.object == 'PRICE'"
                  type="number"
                  suffix="m"
                  :rules="rulesForcedPickupRadius"
                  :disabled="!editDialogFields"
                ></v-text-field>
              </v-col>
              <!-- select from dropdownStand by standId and display name -->
              <v-col cols="6" class="py-0">
                <v-select
                  :label="$t('LocalityList.standId')"
                  dense
                  v-model="updatedLocalityItemData.standId"
                  :items="dropdownStand"
                  item-text="name"
                  item-value="id"
                  :disabled="!editDialogFields"
                ></v-select>
              </v-col> </v-row
            ><v-row
              v-show="
                editDialogFields && updatedLocalityItemData.type == 'POLYGON'
              "
            >
              <!-- <v-col cols="12">
                <v-divider></v-divider>
              </v-col> -->
              <v-col cols="12">
                {{ $t("LocalityList.editPolygonHelpText") }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click.stop="showDialog = false">{{
            $t("btnClose")
          }}</v-btn>
          <v-btn
            color="success"
            v-show="editDialogFields"
            text
            @click.stop="(processingUpdateDialogData = true), saveDialogData()"
            :loading="processingUpdateDialogData"
            :disabled="processingUpdateDialogData"
            >{{ $t("btnSave") }}</v-btn
          >
        </v-card-actions>
        <!--</v-form>-->
      </v-card>
    </v-dialog>
    <!-- Dialog to edit polygon path. Max 10 gps point is edited -->
    <v-dialog v-model="showDialogEditPolygon" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("LocalityList.editPolygonPointsTitle")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!--<v-row>
              <v-col cols="12"
                ><div id="dialogMap" ref="dialogMap"></div
              ></v-col>
            </v-row>-->
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.name')"
                  filled
                  dense
                  v-model="updatedLocalityItemData.name"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  disabled
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.path')"
                  v-if="
                    updatedLocalityItemData.object != 'PICKUP' &&
                    updatedLocalityItemData.object != 'STAND'
                  "
                  filled
                  dense
                  v-model="updatedLocalityItemData.path"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  disabled
                  v-show="
                    updatedLocalityItemData.type == 'POLYGON' ||
                    displayOnlyForAdmin
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 1'"
                  filled
                  dense
                  v-model="pathPoint1Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 1'"
                  filled
                  dense
                  v-model="pathPoint1Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint1Lng == 0 && closePathPoint1Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint1Lat +
                    ' Lng: ' +
                    closePathPoint1Lng
                  "
                  @click="
                    (pathPoint1Lng = closePathPoint1Lng),
                      (pathPoint1Lat = closePathPoint1Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint1Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 2'"
                  filled
                  dense
                  v-model="pathPoint2Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 2'"
                  filled
                  dense
                  v-model="pathPoint2Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint2Lng == 0 && closePathPoint2Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint2Lat +
                    ' Lng: ' +
                    closePathPoint2Lng
                  "
                  @click="
                    (pathPoint2Lng = closePathPoint2Lng),
                      (pathPoint2Lat = closePathPoint2Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint2Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 3'"
                  filled
                  dense
                  v-model="pathPoint3Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 3'"
                  filled
                  dense
                  v-model="pathPoint3Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint3Lng == 0 && closePathPoint3Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint3Lat +
                    ' Lng: ' +
                    closePathPoint3Lng
                  "
                  @click="
                    (pathPoint3Lng = closePathPoint3Lng),
                      (pathPoint3Lat = closePathPoint3Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint3Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 4'"
                  filled
                  dense
                  v-model="pathPoint4Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 4'"
                  filled
                  dense
                  v-model="pathPoint4Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint4Lng == 0 && closePathPoint4Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint4Lat +
                    ' Lng: ' +
                    closePathPoint4Lng
                  "
                  @click="
                    (pathPoint4Lng = closePathPoint4Lng),
                      (pathPoint4Lat = closePathPoint4Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint4Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 5'"
                  filled
                  dense
                  v-model="pathPoint5Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 5'"
                  filled
                  dense
                  v-model="pathPoint5Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint5Lng == 0 && closePathPoint5Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint5Lat +
                    ' Lng: ' +
                    closePathPoint5Lng
                  "
                  @click="
                    (pathPoint5Lng = closePathPoint5Lng),
                      (pathPoint5Lat = closePathPoint5Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint5Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 6'"
                  filled
                  dense
                  v-model="pathPoint6Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 6'"
                  filled
                  dense
                  v-model="pathPoint6Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint6Lng == 0 && closePathPoint6Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint6Lat +
                    ' Lng: ' +
                    closePathPoint6Lng
                  "
                  @click="
                    (pathPoint6Lng = closePathPoint6Lng),
                      (pathPoint6Lat = closePathPoint6Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint6Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 7'"
                  filled
                  dense
                  v-model="pathPoint7Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 7'"
                  filled
                  dense
                  v-model="pathPoint7Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint7Lng == 0 && closePathPoint7Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint7Lat +
                    ' Lng: ' +
                    closePathPoint7Lng
                  "
                  @click="
                    (pathPoint7Lng = closePathPoint7Lng),
                      (pathPoint7Lat = closePathPoint7Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint7Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 8'"
                  filled
                  dense
                  v-model="pathPoint8Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 8'"
                  filled
                  dense
                  v-model="pathPoint8Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint8Lng == 0 && closePathPoint8Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint8Lat +
                    ' Lng: ' +
                    closePathPoint8Lng
                  "
                  @click="
                    (pathPoint8Lng = closePathPoint8Lng),
                      (pathPoint8Lat = closePathPoint8Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint8Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 9'"
                  filled
                  dense
                  v-model="pathPoint9Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 9'"
                  filled
                  dense
                  v-model="pathPoint9Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="closePathPoint9Lng == 0 && closePathPoint9Lat == 0"
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint9Lat +
                    ' Lng: ' +
                    closePathPoint9Lng
                  "
                  @click="
                    (pathPoint9Lng = closePathPoint9Lng),
                      (pathPoint9Lat = closePathPoint9Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint9Label }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLatitude') + ' 10'"
                  filled
                  dense
                  v-model="pathPoint10Lat"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field
              ></v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  :label="$t('LocalityList.pathLongitude') + ' 10'"
                  filled
                  dense
                  v-model="pathPoint10Lng"
                  @change="createPolygonFromDialogPoints()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-chip
                  color="secondary"
                  label
                  :disabled="
                    closePathPoint10Lng == 0 && closePathPoint10Lat == 0
                  "
                  text-color="white"
                  :title="
                    $t('LocalityList.closePathChange') +
                    ' Lat: ' +
                    closePathPoint10Lat +
                    ' Lng: ' +
                    closePathPoint10Lng
                  "
                  @click="
                    (pathPoint10Lng = closePathPoint10Lng),
                      (pathPoint10Lat = closePathPoint10Lat),
                      createPolygonFromDialogPoints()
                  "
                  >{{ closePathPoint10Label }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showDialogEditPolygon = false"
            :disabled="!editDialogFields"
          >
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            color="success"
            v-show="editDialogFields"
            text
            @click.stop="(processingUpdateDialogData = true), saveDialogData()"
            :loading="processingUpdateDialogData"
            :disabled="processingUpdateDialogData"
            >{{ $t("btnSave") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>

  <div v-else>
    <v-btn color="error" @click="getItemsLocalityPickup()">{{
      $t("btnReload")
    }}</v-btn>
  </div>
</template>
<style scoped>
#dialogMap {
  height: 50vh;
}
</style>
<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import ShapesGoogleMap from "../components/ShapesGoogleMap.vue";
/*
import {
  PriceStartFareType,
  PriceDriveType,
  Status,
} from "../enums/LocalityEnum";
import Prices from "../components/Prices.vue";
*/
export default {
  components: {
    ShapesGoogleMap,
  },
  data() {
    return {
      tabs: null,
      errors: null,
      //zobrazenie vybranych objektov iba pre admina
      displayOnlyForAdmin: false,
      //Nastavenia modulov
      module: moduleDefaultSettings,
      //Defaultne centrum zobrazenia stredu vsetkych map
      mapCenter: { lat: 48.148, lng: 18.127 },
      //
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 4000,
      },
      //========== ALL TABS ============
      // Univerzalne premenne na zaslanie udajov do mapy so vsetkymi objektami
      passDataOnMap: [],
      // Hlavicka tabulky na vsetkych zalozkach
      gridHeaders: [
        {
          text: this.$t("LocalityList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
        { text: this.$t("LocalityList.tableHeaders.id"), value: "id" },
        {
          text: this.$t("LocalityList.tableHeaders.priority"),
          value: "priority",
        },
        { text: this.$t("LocalityList.tableHeaders.name"), value: "name" },
        { text: this.$t("LocalityList.tableHeaders.active"), value: "active" },
        { text: this.$t("LocalityList.tableHeaders.type"), value: "type" },
        {
          text: this.$t("LocalityList.tableHeaders.latitude"),
          value: "latitude",
        },
        {
          text: this.$t("LocalityList.tableHeaders.longitude"),
          value: "longitude",
        },
        { text: this.$t("LocalityList.tableHeaders.radius"), value: "radius" },
        { text: this.$t("LocalityList.tableHeaders.path"), value: "path" },
      ],
      //========== NEW/EDIT DIALOG ============
      //Zablokovanie editovacich poli, ak je needitacne zobrazenie View Dialogoveho okna
      editDialogFields: false,

      //Mapa lokalit v Dialogovom okne
      dialogMap: null,
      showDialog: false,

      //Zoznam vygenerovanych needitovatelnych objektov na mape
      localityShapes: [],
      showMarkers: true,
      localityMarkers: [],

      // Tlacidlo SAVE v Dialogovom okne - blokovanie pocas odosielania udajov
      processingUpdateDialogData: false,

      // Defaultne nastavenia lokalit v Dialogovom okne
      defaultLocalityItemData: {
        id: null,
        latitude: 48.148,
        longitude: 18.127,
        radius: 100000,
        path: "[[49.18,19.25],[48.25,17.47],[48.82,21.68]]",
        priority: 1,
        name: "New",
        active: false,
        type: "POLYGON",
        object: "NONE",
        showDriverPhoneButton: "NONE",
        showPhone: false,
        showAllAddresses: false,
        onlyManualSending: false,
        forcedPickupRadius: 0,
        standId: null,
        distance: 0,
      },
      // Udaje z Dialogoveho okna, ktore sa odosielaju na server pri ulozeni zmien alebo vytvarani novej lokality
      updatedLocalityItemData: {
        id: 0,
        latitude: 48.149,
        longitude: 18.128,
        radius: 100000,
        path: "[[49.18,19.25],[48.25,17.47],[48.82,21.68]]",
        priority: 0,
        name: "default",
        active: false,
        type: "POLYGON",
        object: "NONE",
        showDriverPhoneButton: "NONE",
        showPhone: false,
        showAllAddresses: false,
        onlyManualSending: false,
        forcedPickupRadius: 0,
        standId: null,
        distance: 0,
      },
      //========== Locality PICKUP ============
      //Tab Locality-PICKUP LIST Data
      loadingDataLocalityPickup: false,
      gridItemsLocalityPickup: [],
      cachedItemLocalityPickup: [],

      //Zablokovanie tlacidiel na vytvaranie PICKUP lokalit, ak je ich vela
      localityPickupCreateButtonDisabled: true,
      localityPickupCreateMax: 1,
      localityPickupCreateCurrent: 0,

      //========== Locality PRICES ============
      //Tab Locality-PRICES LIST Data
      loadingDataLocalityPrice: false,
      gridItemsLocalityPrice: [],
      cachedItemLocalityPrice: [],

      //Zablokovanie tlacidiel na vytvaranie PRICE lokalit, ak je ich vela
      localityPriceCreateButtonDisabled: true,
      localityPriceCreateMax: 10,
      localityPriceCreateCurrent: 0,

      //========== Locality CHECKIN ============
      //Tab Locality-CHECKIN LIST Data
      loadingDataLocalityCheckin: false,
      gridItemsLocalityCheckin: [],
      cachedItemLocalityCheckin: [],

      //Zablokovanie tlacidiel na vytvaranie CHECKIN lokalit, ak je ich vela
      localityCheckinCreateButtonDisabled: true,
      localityCheckinCreateMax: 4,
      localityCheckinCreateCurrent: 0,
      localityPriceActiveOnly: false,

      // Hlavicka tabulky na zalozke PRICES
      gridHeadersLocalityPrice: [
        {
          text: this.$t("LocalityList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
        { text: this.$t("LocalityList.tableHeaders.id"), value: "id" },
        {
          text: this.$t("LocalityList.tableHeaders.priority"),
          value: "priority",
        },
        { text: this.$t("LocalityList.tableHeaders.name"), value: "name" },
        { text: this.$t("LocalityList.tableHeaders.active"), value: "active" },
        { text: this.$t("LocalityList.tableHeaders.type"), value: "type" },
        {
          text: this.$t("LocalityList.tableHeaders.latitude"),
          value: "latitude",
        },
        {
          text: this.$t("LocalityList.tableHeaders.longitude"),
          value: "longitude",
        },
        { text: this.$t("LocalityList.tableHeaders.radius"), value: "radius" },
        { text: this.$t("LocalityList.tableHeaders.path"), value: "path" },
        {
          text: this.$t("LocalityList.tableHeaders.showDriverPhoneButton"),
          value: "showDriverPhoneButton",
        },
        {
          text: this.$t("LocalityList.tableHeaders.showPhone"),
          value: "showPhone",
        },
        {
          text: this.$t("LocalityList.tableHeaders.showAllAddresses"),
          value: "showAllAddresses",
        },
        {
          text: this.$t("LocalityList.tableHeaders.onlyManualSending"),
          value: "onlyManualSending",
        },
        {
          text: this.$t("LocalityList.tableHeaders.forcedPickupRadius"),
          value: "forcedPickupRadius",
        },
        {
          text: this.$t("LocalityList.tableHeaders.standId"),
          value: "standId",
        },
      ],

      rulesForcedPickupRadius: [
        (v) => !!v || this.$t("msgRequiredField"),
        (v) => (v && v >= 0) || "Should be 0 or more",
        (v) => (v && v <= 1000) || "Max 1000m",
      ],

      //========== Locality CHECKIN ============
      gridHeadersCheckin: [
        {
          text: this.$t("LocalityList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
        { text: this.$t("LocalityList.tableHeaders.id"), value: "id" },
        {
          text: this.$t("LocalityList.tableHeaders.priority"),
          value: "priority",
        },
        { text: this.$t("LocalityList.tableHeaders.name"), value: "name" },
        { text: this.$t("LocalityList.tableHeaders.active"), value: "active" },
        { text: this.$t("LocalityList.tableHeaders.type"), value: "type" },
        {
          text: this.$t("LocalityList.tableHeaders.latitude"),
          value: "latitude",
        },
        {
          text: this.$t("LocalityList.tableHeaders.longitude"),
          value: "longitude",
        },
        { text: this.$t("LocalityList.tableHeaders.radius"), value: "radius" },
        { text: this.$t("LocalityList.tableHeaders.path"), value: "path" },
        {
          text: this.$t("LocalityList.tableHeaders.distance"),
          value: "distance",
        },
      ],

      //========== STANDS ============
      //Tab Locality-CHECKIN LIST Data
      loadingDataLocalityStand: false,
      gridItemsLocalityStand: [],
      cachedItemLocalityStand: [],
      dropdownStand: [],

      //Zablokovanie tlacidiel na vytvaranie CHECKIN lokalit, ak je ich vela
      localityStandCreateButtonDisabled: true,
      localityStandCreateMax: 3,
      localityStandCreateCurrent: 0,

      //Osobitna hlavicka pre Stand
      gridHeadersStand: [
        {
          text: this.$t("LocalityList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
        { text: this.$t("LocalityList.tableHeaders.id"), value: "id" },
        {
          text: this.$t("LocalityList.tableHeaders.priority"),
          value: "priority",
        },
        { text: this.$t("LocalityList.tableHeaders.name"), value: "name" },
        { text: this.$t("LocalityList.tableHeaders.active"), value: "active" },
        {
          text: this.$t("LocalityList.tableHeaders.defaultStand"),
          value: "defaultStand",
        },
        {
          text: this.$t("LocalityList.tableHeaders.latitude"),
          value: "latitude",
        },
        {
          text: this.$t("LocalityList.tableHeaders.longitude"),
          value: "longitude",
        },
        { text: this.$t("LocalityList.tableHeaders.radius"), value: "radius" },
        {
          text: this.$t("LocalityList.tableHeaders.capacity"),
          value: "capacity",
        },
      ],
      // Defaultne nastavenia lokalit v Dialogovom okne
      defaultStandItemData: {
        id: null,
        latitude: 48.148,
        longitude: 18.127,
        radius: 100000,
        capacity: 1,
        name: "New",
        internalName: "NX",
        active: false,
        defaultStand: false,
        city: "",
        country: "",
        street1: "",
        street2: "",
        object: "STAND",
        type: "CIRCLE",
        standId: null,
      },

      showDialogEditPolygon: false,
      polygonPathToEditDefault: [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ],
      pathPoint1Lat: 0,
      pathPoint1Lng: 0,
      pathPoint2Lat: 0,
      pathPoint2Lng: 0,
      pathPoint3Lat: 0,
      pathPoint3Lng: 0,
      pathPoint4Lat: 0,
      pathPoint4Lng: 0,
      pathPoint5Lat: 0,
      pathPoint5Lng: 0,
      pathPoint6Lat: 0,
      pathPoint6Lng: 0,
      pathPoint7Lat: 0,
      pathPoint7Lng: 0,
      pathPoint8Lat: 0,
      pathPoint8Lng: 0,
      pathPoint9Lat: 0,
      pathPoint9Lng: 0,
      pathPoint10Lat: 0,
      pathPoint10Lng: 0,

      closePathPoint1Lng: 0,
      closePathPoint1Lat: 0,
      closePathPoint2Lat: 0,
      closePathPoint2Lng: 0,
      closePathPoint3Lat: 0,
      closePathPoint3Lng: 0,
      closePathPoint4Lat: 0,
      closePathPoint4Lng: 0,
      closePathPoint5Lat: 0,
      closePathPoint5Lng: 0,
      closePathPoint6Lat: 0,
      closePathPoint6Lng: 0,
      closePathPoint7Lat: 0,
      closePathPoint7Lng: 0,
      closePathPoint8Lat: 0,
      closePathPoint8Lng: 0,
      closePathPoint9Lat: 0,
      closePathPoint9Lng: 0,
      closePathPoint10Lat: 0,
      closePathPoint10Lng: 0,

      closePathPoint1Label: "",
      closePathPoint2Label: "",
      closePathPoint3Label: "",
      closePathPoint4Label: "",
      closePathPoint5Label: "",
      closePathPoint6Label: "",
      closePathPoint7Label: "",
      closePathPoint8Label: "",
      closePathPoint9Label: "",
      closePathPoint10Label: "",
    };
  },
  //computed:{
  //formatDate() {
  //return moment(this.item.validFrom).local();
  //}
  //},
  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
      this.localityPriceCreateMax = modules.limitLocalityPrice;
      this.localityStandCreateMax = modules.limitStand;
    }
  },

  mounted() {
    this.getUserRole();
    this.getItemsLocalityPickup();
    this.getStandsForDropdown();
  },

  methods: {
    /* enableEditMode(enabled) {
      this.editMode = enabled;
    },*/
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getItemsLocalityPickup() {
      //console.log("Function Name", "getItemsLocalityPickup");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-pickup`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList) {
            if (itemList.constructor.name != "Array") {
              var newItemList = [];
              newItemList.push(itemList);
              itemList = newItemList;
            }
          }
          this.gridItemsLocalityPickup = itemList || [];
          this.passDataOnMap = itemList || [];
          //this.cachedItemLocalityPrice = Object.assign({}, this.gridItemsLocalityPrice);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          //this.initLocalityPriceListMap();
          //Nastavenia blokovani tlacidiel na vytvania novych lokalit a counter lokalit
          //localityPriceShapes.length = 0;
          //this.localityPriceCreateMax = 10;
          this.localityPickupCreateCurrent = itemList.length;
          var localityPickupCreateMax = this.localityPickupCreateMax;
          var localityPickupCreateCurrent = this.localityPickupCreateCurrent;
          //Zablokovanie tlacidiel na vytvaranie novy lokalit, ak je ich viac ako 10
          //console.log(            "localityPickupCreateCurrent",            localityPickupCreateCurrent,            "localityPickupCreateMax",            localityPickupCreateMax          );
          if (localityPickupCreateCurrent >= localityPickupCreateMax) {
            this.localityPickupCreateButtonDisabled = true;
          } else {
            this.localityPickupCreateButtonDisabled = false;
          }
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },
    getStandsForDropdown() {
      //console.log("Function Name", "getItemsLocalityStand");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/stand`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          itemList.push({ id: null, name: "-", active: true });
          itemList = itemList.filter((item) => item.active == true);
          this.dropdownStand = itemList;
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },

    getItemsLocalityStand() {
      //console.log("Function Name", "getItemsLocalityStand");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/stand`)
        .then((response) => {
          var itemList = response.data;
          //ID zobrazovať iba adminovi
          if (this.displayOnlyForAdmin == false) {
            this.gridHeadersStand = this.gridHeadersStand.filter(
              (item) => item.value != "id"
            );
          }
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          this.gridItemsLocalityStand = itemList || [];
          this.passDataOnMap = itemList || [];

          //skontrolovat ci je defaultna lokalita existuje

          let defaultStandExistsCounter = 0;
          itemList.forEach((item) => {
            if (item.defaultStand == true) {
              //increment counter
              defaultStandExistsCounter++;
            }
          });
          if (defaultStandExistsCounter == 0) {
            //snakebar
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "LocalityList.msgDefaultStandNotSet"
            );
            this.snackbar.color = "warning";
          } else if (defaultStandExistsCounter > 1) {
            //snakebar
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "LocalityList.msgDefaultStandMoreThenOne"
            );
            this.snackbar.color = "warning";
          }
          //this.cachedItemLocalityPrice = Object.assign({}, this.gridItemsLocalityPrice);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          //this.initLocalityPriceListMap();
          //Nastavenia blokovani tlacidiel na vytvania novych lokalit a counter lokalit
          //localityPriceShapes.length = 0;
          //this.localityPriceCreateMax = 10;
          this.localityStandCreateCurrent = itemList.length;
          var localityStandCreateMax = this.localityStandCreateMax;
          var localityStandCreateCurrent = this.localityStandCreateCurrent;
          //Zablokovanie tlacidiel na vytvaranie novy lokalit, ak je ich viac ako 10
          //console.log(            "localityStandCreateCurrent",            localityStandCreateCurrent,            "localityStandCreateMax",            localityStandCreateMax          );
          if (localityStandCreateCurrent >= localityStandCreateMax) {
            this.localityStandCreateButtonDisabled = true;
          } else {
            this.localityStandCreateButtonDisabled = false;
          }
          this.getStandsForDropdown();
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },
    getItemsLocalityCheckin() {
      //console.log("Function Name", "getItemsCheckin");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-check`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          this.gridItemsLocalityCheckin = itemList || [];
          this.passDataOnMap = itemList || [];
          //this.cachedItemLocalityPrice = Object.assign({}, this.gridItemsLocalityPrice);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          //this.initLocalityPriceListMap();
          //Nastavenia blokovani tlacidiel na vytvania novych lokalit a counter lokalit
          //localityPriceShapes.length = 0;
          //this.localityPriceCreateMax = 10;
          this.localityCheckinCreateCurrent = itemList.length;
          var localityCheckinCreateMax = this.localityCheckinCreateMax;
          var localityCheckinCreateCurrent = this.localityCheckinCreateCurrent;
          //Zablokovanie tlacidiel na vytvaranie novy lokalit, ak je ich viac ako 10
          //console.log(            "localityCheckinCreateCurrent",            localityCheckinCreateCurrent,            "localityCheckinCreateMax",            localityCheckinCreateMax          );
          if (localityCheckinCreateCurrent >= localityCheckinCreateMax) {
            this.localityCheckinCreateButtonDisabled = true;
          } else {
            this.localityCheckinCreateButtonDisabled = false;
          }
        })
        .catch((e) => {
          this.passDataOnMap = [];
          //this.errors.push(e);
        });
    },
    getItemsLocalityPrice() {
      //console.log("Function Name", "getItemsLocalityPrice");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-price`)
        .then((response) => {
          //ID zobrazovať iba adminovi
          if (this.displayOnlyForAdmin == false) {
            this.gridHeadersLocalityPrice =
              this.gridHeadersLocalityPrice.filter(
                (item) => item.value != "id"
              );
          }
          this.gridItemsLocalityPrice = response.data;
          this.passDataOnMap = response.data || [];
          //display only active items
          if (this.localityPriceActiveOnly) {
            this.gridItemsLocalityPrice = this.gridItemsLocalityPrice.filter(
              (item) => item.active == true
            );
            this.passDataOnMap = this.passDataOnMap.filter(
              (item) => item.active == true
            );
          }
          //this.cachedItemLocalityPrice = Object.assign({}, this.gridItemsLocalityPrice);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          //this.initLocalityPriceListMap();
          //Nastavenia blokovani tlacidiel na vytvania novych lokalit a counter lokalit
          //localityPriceShapes.length = 0;
          //this.localityPriceCreateMax = 10;
          //this.localityPriceCreateCurrent = this.gridItemsLocalityPrice.length;
          this.localityPriceCreateCurrent = response.data.length;
          var localityPriceCreateMax = this.localityPriceCreateMax;
          var localityPriceCreateCurrent = this.localityPriceCreateCurrent;
          //Zablokovanie tlacidiel na vytvaranie novy lokalit, ak je ich viac ako 10
          if (localityPriceCreateCurrent >= localityPriceCreateMax) {
            this.localityPriceCreateButtonDisabled = true;
          } else {
            this.localityPriceCreateButtonDisabled = false;
          }
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },

    /*   reset() {
      this.enableEditMode(false);
      this.$refs.form.resetValidation();
      //this.$refs.form.reset();
      this.item = Object.assign({}, this.cachedItem);
    },*/

    /* submitForm() {
      console.log(this.item.validFrom);
      const isValid = this.$refs.form.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/pricelist`,
            this.item
          )
          .then((response) => {
            this.item = response.data;
            this.cachedItem = Object.assign({}, this.item);
            this.enableEditMode(false);

            this.snackbar.show = "true";
            this.snackbar.message = "Sucessfully updated...";
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t('msgResponseError');
            this.snackbar.color = "error";
          });
      }
    },*/
    viewLocalityOnDialogMap(
      localityItemId,
      operation = "CREATE",
      objectType = "NONE"
    ) {
      //console.log("Function Name", "viewLocalityOnDialogMap");
      var gridItems;
      var selectedItem;
      var editable = false;
      if (operation == "CREATE") {
        editable = true;
        this.editDialogFields = true;
        if (objectType == "STAND") {
          selectedItem = Object.assign({}, this.defaultStandItemData);
        } else {
          selectedItem = Object.assign({}, this.defaultLocalityItemData);
        }
        //console.log("selectedItem", selectedItem);
      } else {
        if (operation == "UPDATE") {
          editable = true;
          this.editDialogFields = true;
        } else if (operation == "VIEW") {
          editable = false;
          this.editDialogFields = false;
        }

        switch (objectType) {
          case "PICKUP":
            gridItems = this.gridItemsLocalityPickup;
            break;
          case "PRICE":
            gridItems = this.gridItemsLocalityPrice;
            break;
          case "CHECKIN":
            gridItems = this.gridItemsLocalityCheckin;
            break;
          case "STAND":
            gridItems = this.gridItemsLocalityStand;
            break;
          default:
            alert(this.$t("LocalityList.msgErrorToEditLocality"));
        }

        //PICKUP je vzdy iba 1, chodi bez ID, teda neda sa filtrovat
        if (objectType == "PICKUP") {
          selectedItem = Object.assign({}, gridItems[0]);
        } else {
          selectedItem = Object.assign(
            {},
            gridItems.filter(function (elem) {
              if (elem.id == localityItemId) return elem;
            })[0]
          );
        }
        //console.log("selectedItem", selectedItem);
        if (selectedItem) {
          // console.log(selectedItem);
          // selectedItem = selectedItem[0];
          selectedItem.object = objectType;
        } else {
          //console.log("viewLocalityOnDialogMap missing data problem");
          this.snackbar.show = true;
          this.snackbar.message = this.$t("LocalityList.msgDataMissing");
          this.snackbar.color = "error";
        }
      }
      this.updatedLocalityItemData = selectedItem;
      this.showDialog = true;
      this.initDialogMap(selectedItem, editable, gridItems);
    },

    viewLocalityOnDialogEditPoints(
      localityItemId,
      operation = "UPDATE",
      objectType = "NONE"
    ) {
      var gridItems;
      var selectedItem;
      var editable = false;
      if (operation == "UPDATE") {
        editable = true;
        this.editDialogFields = true;
      }
      switch (objectType) {
        case "PICKUP":
          gridItems = this.gridItemsLocalityPickup;
          break;
        case "PRICE":
          gridItems = this.gridItemsLocalityPrice;
          break;
        case "CHECKIN":
          gridItems = this.gridItemsLocalityCheckin;
          break;
        case "STAND":
          gridItems = this.gridItemsLocalityStand;
          break;
        default:
          alert(this.$t("LocalityList.msgErrorToEditLocality"));
      }

      //PICKUP je vzdy iba 1, chodi bez ID, teda neda sa filtrovat
      if (objectType == "PICKUP") {
        selectedItem = Object.assign({}, gridItems[0]);
      } else {
        selectedItem = Object.assign(
          {},
          gridItems.filter(function (elem) {
            if (elem.id == localityItemId) return elem;
          })[0]
        );
        //vytvorenie kopie gridItems do otherPolygons, ktore bude zahrnat vsetky polygony (type == 'POLYGON') okrem localityItemId
        var otherPolygons = [];
        for (var i = 0; i < gridItems.length; i++) {
          if (gridItems[i].type == "POLYGON") {
            if (gridItems[i].id != localityItemId) {
              otherPolygons.push(gridItems[i]);
            }
          }
        }
      }
      //console.log("selectedItem", selectedItem);
      if (selectedItem) {
        // console.log(selectedItem);
        // selectedItem = selectedItem[0];
        selectedItem.object = objectType;
      } else {
        //console.log("viewLocalityOnDialogMap missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = this.$t("LocalityList.msgDataMissing");
        this.snackbar.color = "error";
      }

      this.updatedLocalityItemData = selectedItem;

      //loop to set data from this.polygonPathToEdit to pathPoint1Lat...
      //prednastavit vsetky hodnoty na 0
      for (var i = 0; i < this.polygonPathToEditDefault.length; i++) {
        this["pathPoint" + (i + 1) + "Lat"] = 0; // this.polygonPathToEditDefault[i][0];
        this["pathPoint" + (i + 1) + "Lng"] = 0; // this.polygonPathToEditDefault[i][1];
        this["closePathPoint" + (i + 1) + "Lat"] = 0;
        this["closePathPoint" + (i + 1) + "Lng"] = 0;
        this["closePathPoint" + (i + 1) + "Label"] = "-";
      }
      var polygonPathToEdit = JSON.parse(selectedItem.path);
      //naplnenie poli pathPoint1Lat... hodnotami z polygonPathToEdit
      for (var i = 0; i < polygonPathToEdit.length; i++) {
        this["pathPoint" + (i + 1) + "Lat"] = polygonPathToEdit[i][0];
        this["pathPoint" + (i + 1) + "Lng"] = polygonPathToEdit[i][1];
        //VYHLADENIE BLIZKYCH BODOV na presnejsie spoje s inymi polygonmi
        //vypis zoznam bodov (id polygónu, longitude, latitude bodu) ostatnych polygonov okrem aktualneho (otherPolygons), ktore su maximalne vzdialene 20m od aktualneho bodu.
        //console.log("otherPolygons", otherPolygons);
        for (var j = 0; j < otherPolygons.length; j++) {
          //console.log("otherPolygons[j]", otherPolygons[j]);
          var otherPolygonPath = JSON.parse(otherPolygons[j].path);
          //console.log("otherPolygonPath", otherPolygonPath);
          for (var k = 0; k < otherPolygonPath.length; k++) {
            //console.log("otherPolygonPath[k]", otherPolygonPath[k]);
            //vypocitaj cez pytagorovu vetu priamo v tejto funkcii jednoduchu vzdialenost (distance) v metroch medzi GPS bodmi polygonPathToEdit[i] a otherPolygonPath[k]
            var distance = this.getSimpleDistanceInMeters(
              polygonPathToEdit[i][0],
              polygonPathToEdit[i][1],
              otherPolygonPath[k][0],
              otherPolygonPath[k][1]
            );
            distance = Math.round(distance);
            if (distance > 0 && distance < 30) {
              this["closePathPoint" + (i + 1) + "Lat"] = otherPolygonPath[k][0];
              this["closePathPoint" + (i + 1) + "Lng"] = otherPolygonPath[k][1];
              this["closePathPoint" + (i + 1) + "Label"] =
                otherPolygons[j].name;
              // console.log("selectedItem",selectedItem.id + "," + i + "," + selectedItem.name);
              // console.log("polygonPathToEdit[i]",polygonPathToEdit[i][0] + "," + polygonPathToEdit[i][1]);
              // console.log("otherPolygons[j]",otherPolygons[j].id + "," + j + "," + otherPolygons[j].name);
              // console.log("otherPolygonPath[k]",otherPolygonPath[k][0] + "," + otherPolygonPath[k][1]);
              // console.log("distance", distance);
            }
          }
        }
      }
      this.showDialogEditPolygon = true;
      //this.initDialogMap(selectedItem, editable, gridItems);
    },

    getSimpleDistanceInMeters(lat1, lon1, lat2, lon2) {
      //check if driverActualPosition of driver is within radius of stand
      var R = 6371; // Radius of the earth in km
      var dLat = (lat2 - lat1) * (Math.PI / 180);
      var dLon = (lon2 - lon1) * (Math.PI / 180);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var distanceInMeters = R * c * 1000; // Distance in m
      return distanceInMeters;
    },

    //function to create new polygon from points in dialog
    createPolygonFromDialogPoints() {
      //console.log("Function Name", "createPolygonFromDialogPoints");
      var polygonPath = [];
      for (var i = 0; i < this.polygonPathToEditDefault.length; i++) {
        //push only if lat and lng is not 0
        if (
          this["pathPoint" + (i + 1) + "Lat"] != 0 &&
          this["pathPoint" + (i + 1) + "Lng"] != 0
        ) {
          //set as number
          this["pathPoint" + (i + 1) + "Lat"] = parseFloat(
            this["pathPoint" + (i + 1) + "Lat"]
          );
          this["pathPoint" + (i + 1) + "Lng"] = parseFloat(
            this["pathPoint" + (i + 1) + "Lng"]
          );
          polygonPath.push([
            this["pathPoint" + (i + 1) + "Lat"],
            this["pathPoint" + (i + 1) + "Lng"],
          ]);
        }
      }
      //console.log("polygonPath", polygonPath);
      this.updatedLocalityItemData.path = JSON.stringify(polygonPath);
      //console.log("this.updatedLocalityItemData", this.updatedLocalityItemData);
    },

    saveDialogData() {
      //console.log("Function Name", "saveDialogData");
      //console.log(this.updatedLocalityItemData);
      if (this.updatedLocalityItemData) {
        var apiObjectUrl;
        switch (this.updatedLocalityItemData.object) {
          case "PICKUP":
            apiObjectUrl = `/dispatching/locality-pickup`;
            break;
          case "PRICE":
            apiObjectUrl = `/dispatching/locality-price`;
            break;
          case "CHECKIN":
            apiObjectUrl = `/dispatching/locality-check`;
            break;
          case "STAND":
            apiObjectUrl = `/dispatching/stand`;
            break;
          default:
            alert(this.$t("LocalityList.msgErrorToEditLocality"));
        }
        //Ak nie je ID, tak vytvoriť nový objekt, v prípade PICKUP je vzdy PUT
        if (
          this.updatedLocalityItemData.id == null &&
          this.updatedLocalityItemData.object != "PICKUP"
        ) {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + apiObjectUrl,
              this.updatedLocalityItemData
            )
            .then((response) => {
              //console.log("saveDialogData POST saved");
              this.processingUpdateDialogData = false;
              this.showDialog = false;
              //aj to druhe okno, lebo ukladanie je spolocne
              this.showDialogEditPolygon = false;
              switch (this.updatedLocalityItemData.object) {
                case "PICKUP":
                  this.getItemsLocalityPickup();
                  break;
                case "PRICE":
                  this.getItemsLocalityPrice();
                  break;
                case "CHECKIN":
                  this.getItemsLocalityCheckin();
                  break;
                case "STAND":
                  this.getItemsLocalityStand();
                  break;
                default:
                  alert(this.$t("LocalityList.msgErrorToEditLocality"));
              }
              this.snackbar.show = "true";
              this.snackbar.message = this.$t("msgCreateSuccess");
              this.snackbar.color = "success";
            })
            .catch((e) => {
              //console.log("saveDialogData POST error");
              this.snackbar.show = true;
              this.snackbar.message = this.$t("msgResponseError");
              this.snackbar.color = "error";
              this.processingUpdateDialogData = false;
            });
        } else {
          //upravit existujuci objekt
          axios
            .put(
              process.env.VUE_APP_API_BASE_URL + apiObjectUrl,
              this.updatedLocalityItemData
            )
            .then((response) => {
              //console.log("saveDialogData PUT saved");
              //console.log("this.updatedLocalityItemData.object",this.updatedLocalityItemData.object);
              this.processingUpdateDialogData = false;
              this.showDialog = false;
              //aj to druhe okno, lebo ukladanie je spolocne
              this.showDialogEditPolygon = false;
              switch (this.updatedLocalityItemData.object) {
                case "PICKUP":
                  this.getItemsLocalityPickup();
                  break;
                case "PRICE":
                  this.getItemsLocalityPrice();
                  break;
                case "CHECKIN":
                  this.getItemsLocalityCheckin();
                  break;
                case "STAND":
                  this.getItemsLocalityStand();
                  break;
                default:
                  alert(this.$t("LocalityList.msgErrorToEditLocality"));
              }
              this.snackbar.show = "true";
              this.snackbar.message = this.$t("msgUpdateSuccess");
              this.snackbar.color = "success";
            })
            .catch((e) => {
              //console.log("saveDialogData PUT error");
              this.snackbar.show = true;
              this.snackbar.message = this.$t("msgResponseError");
              this.snackbar.color = "error";
              this.processingUpdateDialogData = false;
            });
        }
      } else {
        //console.log("saveDialogData missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = "Data are missing";
        this.snackbar.color = "error";
      }
    },

    displayCircle(localityItem, editable = false) {
      //console.log("Function Name", "displayCircle");
      var bounds = new google.maps.LatLngBounds();
      //console.log("displayCircle bounds 0: ", bounds);
      var circleLat;
      var circleLng;
      var circleRadius;
      var editedLocalityLat;
      var editedLocalityLng;
      var editedLocalityRadius;
      var key = localityItem.id;
      var map = this.dialogMap;
      //this.editItemSupportOrderTypes = selectedItem[0];
      //this.updatedLocalityItemData = localityItem;
      circleLat = localityItem.latitude;
      circleLng = localityItem.longitude;
      circleRadius = localityItem.radius;
      //console.log("circle: ", circleLat, circleLng, circleRadius);
      var localityCircle = new google.maps.Circle({
        editable: editable,
        strokeColor: "#FFFF00",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: map,
        center: new google.maps.LatLng(circleLat, circleLng),
        radius: parseInt(circleRadius),
      });
      localityCircle.setMap(map);
      //console.log("displayCircle bounds 1: ", bounds);
      bounds.extend(new google.maps.LatLng(circleLat, circleLng));
      //console.log("displayCircle bounds 2: ", bounds);
      bounds.extend(
        new google.maps.LatLng(
          parseFloat(circleLat) - parseFloat(circleRadius / 100000),
          parseFloat(circleLng) - parseFloat(circleRadius / 100000)
        )
      );
      //console.log("displayCircle bounds 3: ", bounds);
      bounds.extend(
        new google.maps.LatLng(
          parseFloat(circleLat) + parseFloat(circleRadius / 100000),
          parseFloat(circleLng) + parseFloat(circleRadius / 100000)
        )
      );
      //console.log("displayCircle bounds 4: ", bounds);
      map.fitBounds(bounds);
      google.maps.event.addListener(
        localityCircle,
        "radius_changed",
        (function (key) {
          return function () {
            //if (key) {
            //console.log(localityCircle.getRadius());
            editedLocalityRadius = Math.round(localityCircle.getRadius());
            localityItem.radius = editedLocalityRadius;
            this.updatedLocalityItemData = localityItem;
            //console.log("item", localityItem);
            //console.log("this.updatedLocalityItemData",this.updatedLocalityItemData);
            // }
          };
        })(key)
      );
      google.maps.event.addListener(
        localityCircle,
        "center_changed",
        (function (key) {
          return function () {
            //if (key) {
            //console.log("editedLocalityLat",localityCircle.getCenter().lat());
            //console.log("editedLocalityLng", localityCircle.getCenter().lng());
            editedLocalityLat =
              Math.round(localityCircle.getCenter().lat() * 1000000) / 1000000;
            editedLocalityLng =
              Math.round(localityCircle.getCenter().lng() * 1000000) / 1000000;
            localityItem.latitude = editedLocalityLat;
            localityItem.longitude = editedLocalityLng;
            this.updatedLocalityItemData = localityItem;
            //console.log("this.editedLocalityLat", editedLocalityLat);
            //console.log("this.editedLocalityLng", editedLocalityLng);
            //}
          };
        })(key)
      );
    },

    displayPolygon(localityItem, editable = false) {
      //console.log("Function Name", "displayPolygon");
      var map = this.dialogMap;
      var bounds = new google.maps.LatLngBounds();
      var polygonPathArray = [];
      var polygonPath = [];
      polygonPathArray = JSON.parse(localityItem.path);
      //console.log(polygonPathArray);
      for (var i = 0; i < polygonPathArray.length; i++) {
        polygonPath.push({
          lat: polygonPathArray[i][0],
          lng: polygonPathArray[i][1],
        });
        //console.log("polygonPath: ", i, polygonPathArray[i][0]);
        bounds.extend(
          new google.maps.LatLng(polygonPathArray[i][0], polygonPathArray[i][1])
        );
      }
      var key = localityItem.id;

      var localityPolygon;
      localityPolygon = new google.maps.Polygon({
        editable: editable,
        paths: polygonPath,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        draggable: editable,
      });

      map.fitBounds(bounds);
      var self = this;
      var place_polygon_path = localityPolygon.getPath();
      var updateNode = function (key) {
        //console.log("Function Name", "displayPolygon 1", key);
        var localityData = self.convertGooglePathToJson(localityPolygon);
        localityItem.path = localityData.path;
        localityItem.latitude = localityData.lat;
        localityItem.longitude = localityData.lng;
        self.updatedLocalityItemData = localityItem;
      };
      var deleteNode = function (mev) {
        if (mev.vertex != null) {
          //console.log("mev.vertex", mev.vertex);
          //console.log("mev", mev);
          localityPolygon.getPath().removeAt(mev.vertex);
          updateNode(key);
          //workaround ako zneviditelnovat/odkryvat body ktore mozu pridavat body polygonu.
          if (localityPolygon.getPath().getLength() < 10) {
            setTimeout(function () {
              var map = document.getElementById("dialogMap");
              var divs = map.querySelectorAll("div[style*='opacity: 0.01']");
              divs.forEach(function (div) {
                div.style.opacity = "0.5";
                //div.style.display = "none";
              });
            }, 50);
          }
        }
      };
      var showVertexInfo = function (mev) {
        if (mev.vertex != null) {
          //console.log("mev.vertex", mev.vertex);
          //console.log("mev", mev);
          //alert position of vertex
          alert(
            "Vertex " +
              mev.vertex +
              " is at " +
              place_polygon_path.getAt(mev.vertex).lat() +
              "," +
              place_polygon_path.getAt(mev.vertex).lng() +
              " (validation info: " +
              mev.latLng.lat() +
              "," +
              mev.latLng.lng() +
              ")"
          );
        }
      };
      //rightclick deprecated, use contextmenu instead
      google.maps.event.addListener(localityPolygon, "click", showVertexInfo);
      google.maps.event.addListener(localityPolygon, "contextmenu", deleteNode);
      google.maps.event.addListener(
        localityPolygon,
        "dragend",
        (function (key) {
          //console.log("Function Name", "displayPolygon 2", key);
          return function () {
            //if (key) {
            updateNode(key);
            //}
          };
        })(key)
      );

      google.maps.event.addListener(
        place_polygon_path,
        "set_at",
        (function (key) {
          return function () {
            // if (key) {
            updateNode(key);
            // }
          };
        })(key)
      );
      var msgMax10Points = this.$t("LocalityList.msgMax10Points");
      var msgMax10PointsOver = this.$t("LocalityList.msgMax10PointsOver");
      google.maps.event.addListener(
        localityPolygon.getPath(),
        "insert_at",
        (function (key) {
          //Neda sa zabranit, aby vytvorili viac ako 10 bodov, nizsie je workaround ako im to aspon "zneprijenit"
          return function (index) {
            // if (key) {
            //console.log("key", key);
            //workaround ako zneviditelnovat/odkryvat body ktore mozu pridavat body polygonu
            if (localityPolygon.getPath().getLength() < 10) {
              setTimeout(function () {
                var map = document.getElementById("dialogMap");
                var divs = map.querySelectorAll("div[style*='opacity: 0.01']");
                divs.forEach(function (div) {
                  div.style.opacity = "0.5";
                  //div.style.display = "none";
                });
              }, 50);
            }
            if (localityPolygon.getPath().getLength() == 10) {
              window.alert(msgMax10Points);
              //workaround ako zneviditelnovat/odkryvat body ktore mozu pridavat body polygonu
              //Problem to priamo nevyriesi, ale aspon tie handling body nebudu vidiet
              setTimeout(function () {
                var map = document.getElementById("dialogMap");
                var divs = map.querySelectorAll("div[style*='opacity: 0.5']");
                divs.forEach(function (div) {
                  div.style.opacity = "0.01";
                  //div.style.display = "none";
                });
              }, 50);
            }
            if (localityPolygon.getPath().getLength() > 10) {
              window.alert(msgMax10PointsOver);
              //identifikuj index prave pridaneho bodu a odstran ho
              localityPolygon.getPath().removeAt(index);
              //workaround ako zneviditelnovat/odkryvat body ktore mozu pridavat body polygonu
              setTimeout(function () {
                var map = document.getElementById("dialogMap");
                var divs = map.querySelectorAll("div[style*='opacity: 0.5']");
                divs.forEach(function (div) {
                  div.style.opacity = "0.01";
                  //div.style.display = "none";
                });
              }, 50);
            }
            updateNode(key);
            // }
          };
        })(key)
      );

      /*    google.maps.event.addListener(
        localityPolygon.getPath(),
        "insert_at",
        function (index) {
          var numPoints = localityPolygon.getPath().length;

          if (numPoints > 5) {
            console.log(index);
            localityPolygon.getPath().removeAt(index);
          }
        }
      );*/

      /*google.maps.event.addListener(
        place_polygon_path,
        "insert_at",
        (function (key) {
          return function () {
            if (key) {
              //console.log("localityPolygon.getPath", localityPolygon.getPath());
              // localityItem.path = localityPolygon.getPath();
              var localityData = self.convertGooglePathToJson(localityPolygon);
              localityItem.path = localityData.path;
              localityItem.latitude = localityData.lat;
              localityItem.longitude = localityData.lng;
              this.updatedLocalityItemData = localityItem;
            }
          };
        })(key)
      );*/
      localityPolygon.setMap(map);
    },
    convertGooglePathToJson(itemObject) {
      //console.log("Function Name", "convertGooglePathToJson");
      // Since this polygon has only one path, we can call getPath() to return the
      // MVCArray of LatLngs.
      var vertices = itemObject.getPath();
      var returnObject = [];
      returnObject.path = "";
      returnObject.lat = "";
      returnObject.lng = "";
      var contentString = "";
      var sumLat = 0;
      var sumLng = 0;
      // var contentString = '<b>Polygon</b><br>' +
      //     'Clicked location: <br>' + event.latLng.lat() + ',' + event.latLng.lng() +
      //     '<br>';
      // Iterate over the vertices.
      for (var i = 0; i < vertices.getLength(); i++) {
        var xy = vertices.getAt(i);
        var pointLat = xy.lat().toString();
        var pointLng = xy.lng().toString();
        if (i > 0) {
          contentString += ",";
        }
        contentString +=
          "[" + pointLat.substr(0, 9) + "," + pointLng.substr(0, 9) + "]";
        // vypočítavanie približného stredu oblasti - pre účely výpočtu vzdialenosti medzi oblasťami v inom skripte
        sumLat = sumLat + Number(pointLat);
        sumLng = sumLng + Number(pointLng);
      }
      var priemerLat;
      var priemerLng;
      priemerLat = sumLat / vertices.getLength();
      priemerLng = sumLng / vertices.getLength();
      returnObject.path = "[" + contentString + "]";
      priemerLat = priemerLat.toString().substr(0, 9);
      priemerLng = priemerLng.toString().substr(0, 9);
      returnObject.lat = priemerLat;
      returnObject.lng = priemerLng;
      console.log(
        "patch",
        contentString,
        "priemerLat",
        priemerLat,
        "priemerLng",
        priemerLng,
        "returnObject",
        returnObject
      );
      return returnObject;
    },
    initDialogMap(itemObject, editable, gridItems) {
      //console.log("Function Name", "initDialogMap");
      //console.log("initDialogMap itemObject", itemObject);
      var type = itemObject.type; //CIRCLE, POLYGON
      this.dialogMap = new google.maps.Map(
        document.getElementById("dialogMap"),
        {
          center: this.mapCenter, //the center of the map
          zoom: 6,
          maxZoom: 18,
          minZoom: 4,
          streetViewControl: false,
          fullscreenControl: true,
          mapTypeId: google.maps.MapTypeId.ROADMAP, // typ mapy s cestami
          disableDefaultUI: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_CENTER, //umiestnenie prepínača typu mapy - dole v strede
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.HYBRID,
            ],
          },
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_CENTER, //BOTTOM_LEFT
          },
          scaleControl: false,
          //styles: this.mapTheme,
        }
      );
      //var mapa = this.map;
      if (type == "POLYGON") {
        this.displayPolygon(itemObject, editable);
      } else {
        this.displayCircle(itemObject, editable);
      }
      //zobrazit vsetky lokality needitovatelne
      this.displayShapesOnMap(gridItems);
    },

    displayShapesOnMap(itemList) {
      //Zobrazenie ostatnych shapes na mape, aby sme vedeli, kde su ostatne lokality.
      //Shapes su zablokovane, aby sa nedali editovať a su priesvitne.
      var map = this.dialogMap;
      var localityShapes = this.localityShapes;
      var localityMarkers = this.localityMarkers;
      //Premazanie starých shapes
      for (var i = 0; i < localityShapes.length; i++) {
        localityShapes[i].setMap(null);
      }
      for (var i = 0; i < localityMarkers.length; i++) {
        localityMarkers[i].setMap(null);
      }
      //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
      if (itemList.constructor.name != "Array") {
        var newItemList = [];
        newItemList.push(itemList);
        itemList = newItemList;
      }
      var labelShape = "A";
      var colorMarker = "#000000";
      var markerIndex = 0;
      //Vytvorenie nových shapes
      for (var city in itemList) {
        var latitude;
        var longitude;
        var radius;
        //increase labelShape for each polygon, start with A
        labelShape = String.fromCharCode(labelShape.charCodeAt(0) + 1);
        //console.log("labelShape", labelShape);
        //colorMarker set random light color
        do {
          colorMarker = "#" + Math.floor(Math.random() * 16777215).toString(16);
        } while (parseInt(colorMarker.substring(1), 16) < 0xcccccc);

        //console.log("colorMarker", colorMarker);

        if (itemList[city].type == "POLYGON") {
          var prePath = [];
          var postPath = [];
          var finalPath = [];
          prePath = JSON.parse(itemList[city].path);
          for (var i = 0; i < prePath.length; i++) {
            postPath.push({
              lat: prePath[i][0],
              lng: prePath[i][1],
            });
          }
          var _len = postPath.length;
          var i;
          for (i = 0; i < _len; i++) {
            finalPath.push(postPath[i]);
          }
          /*var addListenersOnPolygon = function (polygon) {
            google.maps.event.addListener(polygon, "click", function (event) {
              alert(polygon.title);
            });
          };*/
          var locationPolygon = new google.maps.Polygon({
            paths: finalPath,
            strokeColor: "#FFA500",
            strokeOpacity: 0.4,
            strokeWeight: 1,
            fillColor: "#FFFF00",
            fillOpacity: 0.05,
            editable: false,
            draggable: false,
            zIndex: itemList[city].priority,
            idx: itemList[city].id,
            label: labelShape,
          });

          //set text itemList[city].name on map where itemList[city].latitude and itemList[city].longitude
          if (this.displayOnlyForAdmin) {
            var tempLocation = new google.maps.LatLng(
              itemList[city].latitude,
              itemList[city].longitude
            );
            var svgMarker = {
              path: "M0 0 L1 0 L1 1 L0 1 Z",
              fillColor: "transparent",
              fillOpacity: 0,
              strokeColor: "transparent",
              strokeOpacity: 0,
              scale: 1,
              anchor: new google.maps.Point(0.5, 0.5),
            };
            var markerX = new google.maps.Marker({
              position: tempLocation,
              map: map,
              icon: svgMarker,
              label: {
                text: itemList[city].name,
                color: "black",
                fontSize: "16px",
                //fontWeight: "bold",
              },
            });
          }
          //addListenersOnPolygon(locationPolygon);

          //set markers on map where are vertices
          //only if administrator is logged in
          if (this.displayOnlyForAdmin == true && this.showMarkers == true) {
            var vertices = locationPolygon.getPath();

            for (var i = 0; i < vertices.getLength(); i++) {
              let idx = i;
              markerIndex++;
              var marker = new google.maps.Marker({
                position: vertices.getAt(i),
                map: map,
                draggable: false,
                zIndex: itemList[city].priority,
                idx: itemList[city].id,
                //idx: i,
                label: labelShape,
                icon: {
                  /*path: google.maps.SymbolPath.CIRCLE,
                scale: 8,
                fillColor: colorMarker,
                fillOpacity: 1,
                strokeWeight: 0,*/
                  path: "M 8,0 C 3.6,0 0,3.6 0,8 C 0,13.1 8,20 8,20 C 8,20 16,13.1 16,8 C 16,3.6 12.4,0 8,0 Z",
                  fillColor: colorMarker,
                  fillOpacity: 1,
                  strokeColor: "#000000",
                  strokeWeight: 1,
                  scale: 1,
                  labelOrigin: new google.maps.Point(8, 10),
                  anchor: new google.maps.Point(8, 20),
                },
                title:
                  "Vertex " +
                  i +
                  " of polygon id: " +
                  itemList[city].id +
                  " | " +
                  itemList[city].name +
                  " | GPS coordinates: " +
                  vertices.getAt(i).toUrlValue(6),
                //+ " | Marker Index: " + markerIndex,
              });
              //console.log("markerIndex", markerIndex);
              //right click on marker to copy GPS coordinates
              /*google.maps.event.addListener(
                marker,
                "rightclick",
                (function (marker, markerIndex) {
                  return function () {
                    var infowindow = new google.maps.InfoWindow({
                      content:
                        "Vertex " +
                        i +
                        " of polygon id: " +
                        itemList[city].id +
                        " (idx: " +
                        marker.idx +
                        ") " +
                        " | Marker Index: " +
                        markerIndex +
                        " | " +
                        itemList[city].name +
                        " | GPS coordinates: " +
                        vertices.getAt(markerIndex).toUrlValue(6),
                    });
                    infowindow.open(map, marker);
                  };
                })(marker, markerIndex)
              );*/
              localityMarkers.push(marker);
              //console.log("labelShape Poly", labelShape);
              //marker.setMap(map);
            }
          }
          localityShapes.push(locationPolygon);
          locationPolygon.setMap(map);
        } else {
          /*var addListenersOnCircle = (circle) => {
            google.maps.event.addListener(circle, "click", function (event) {
            });
          };*/
          latitude = itemList[city].latitude;
          longitude = itemList[city].longitude;
          radius = itemList[city].radius;

          var cityCircle = new google.maps.Circle({
            editable: false,
            strokeColor: "#FFA500",
            strokeOpacity: 0.4,
            strokeWeight: 1,
            fillColor: "#FFFF00",
            fillOpacity: 0.05,
            zIndex: itemList[city].priority,
            center: new google.maps.LatLng(latitude, longitude),
            radius: parseInt(radius),
            idx: itemList[city].id,
            label: labelShape,
          });

          //set markers on map where is center of circle
          //only if administrator is logged in
          if (this.displayOnlyForAdmin == true && this.showMarkers == true) {
            markerIndex++;
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(latitude, longitude),
              map: map,
              draggable: false,
              zIndex: itemList[city].priority,
              idx: itemList[city].id,
              //idx: i,
              label: labelShape,
              icon: {
                /*path: google.maps.SymbolPath.CIRCLE,
                scale: 8,
                fillColor: colorMarker,
                fillOpacity: 1,
                strokeWeight: 0,*/
                path: "M 8,0 C 3.6,0 0,3.6 0,8 C 0,13.1 8,20 8,20 C 8,20 16,13.1 16,8 C 16,3.6 12.4,0 8,0 Z",
                fillColor: colorMarker,
                fillOpacity: 1,
                strokeColor: "#000000",
                strokeWeight: 1,
                scale: 1,
                labelOrigin: new google.maps.Point(8, 10),
                anchor: new google.maps.Point(8, 20),
              },
              title:
                "Center of circle id: " +
                itemList[city].id +
                " | " +
                itemList[city].name +
                " | GPS coordinates: " +
                latitude +
                ", " +
                longitude,
              //+ " | Marker Index: " + markerIndex,
            });
            //console.log("markerIndex", markerIndex);
            localityMarkers.push(marker);
            //console.log("labelShape Circle", labelShape);
            //marker.setMap(map);
          }
          localityShapes.push(cityCircle);
          cityCircle.setMap(map);
          //addListenersOnCircle(cityCircle);
        }
      }

      this.localityShapes = localityShapes;
      this.localityMarkers = localityMarkers;
      console.log("localityShapes", this.localityShapes);
      console.log("localityMarkers", this.localityMarkers);
    },

    displayMarkersOnMap() {
      if (this.showMarkers == false) {
        for (var i = 0; i < this.localityMarkers.length; i++) {
          this.localityMarkers[i].setMap(null);
        }
      } else {
        alert("Reopen window to display markers on map");
      }
    },
  },
};
</script>
