<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>
        {{ $t("ReportOrderEstimatedWaitingTime.page_title") }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-account-details-outline</v-icon>
    </v-toolbar>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="10">
        <datetime-range-picker filled v-model="dateRange" hideStartTime eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      :search="filter.search"
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, 250, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`body.prepend`]>
        <tr>
          <td></td>
          <td>
            <v-text-field
              v-model="filter.id"
              type="text"
              label="ID"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.type"
              :label="$t('ReportOrderEstimatedWaitingTime.type')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.requestedTime"
              :label="$t('ReportOrderEstimatedWaitingTime.requestedTime')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.acceptedAt"
              :label="$t('ReportOrderEstimatedWaitingTime.acceptedAt')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.waitingAt"
              :label="$t('ReportOrderEstimatedWaitingTime.waitingAt')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          :title="$t('orderType_tooltip.' + item.type)"
          :color="getTypeColor(item.type)"
          dark
          label
          small
        >
          {{ $t("orderType." + item.type) }}
        </v-chip>
      </template>

      <template v-slot:[`item.requestedTime`]="{ item }">
        {{
          item.requestedTime
            ? $options.filters.formatDateLong(item.requestedTime)
            : "-"
        }}
      </template>
      <template v-slot:[`item.acceptedAt`]="{ item }">
        {{
          item.acceptedAt
            ? $options.filters.formatDateLong(item.acceptedAt)
            : "-"
        }}
      </template>
      <template v-slot:[`item.waitingAt`]="{ item }">
        {{
          item.waitingAt ? $options.filters.formatDateLong(item.waitingAt) : "-"
        }}
      </template>
      <template v-slot:[`item.waitingTimeDifference`]="{ item }">
        <span
          :style="{
            color:
              Math.abs(item.waitingTimeDifference) <= 2
                ? 'green'
                : Math.abs(item.waitingTimeDifference) > 10
                ? 'red'
                : 'orange',
            fontWeight: item.waitingTimeDifference > 2 ? 'bold' : 'normal',
          }"
        >
          {{ item.waitingTimeDifference }}
        </span>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import { Type, TypeColor } from "../enums/OrderEnum";

export default {
  data() {
    return {
      moment: moment,
      Type,
      TypeColor,
      //Nastavenia modulov
      module: moduleDefaultSettings,
      gridItems: [],
      loadingData: false,
      dateRange: [], //["2021-07-22","2021-07-24"],
      displayOnlyForAdmin: false,
      sortBy: "createdAt",
      sortDesc: true,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        id: "",
        type: null,
        requestedTime: "",
        acceptedAt: "",
        waitingAt: "",
      },

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },
  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },

  mounted() {
    this.getUserRole();
    //Niekedy pri prvom nacitani stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },
  computed: {
    gridHeaders() {
      return [
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.orderId"),
          value: "id",
          sortable: true,
          filter: (value) => {
            if (!this.filter.id) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.id.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.type"),
          value: "type",
          filter: (value) => {
            if (!this.filter.type) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.type.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.requestedTime"),
          value: "requestedTime",
          filter: (value) => {
            if (!this.filter.requestedTime) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.requestedTime.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.acceptedAt"),
          value: "acceptedAt",
          filter: (value) => {
            if (!this.filter.acceptedAt) return true;
            return (value + "")
              .toLowerCase()
              .includes(this.filter.acceptedAt.toLowerCase());
          },
        },
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.waitingAt"),
          value: "waitingAt",
          filter: (value) => {
            if (!this.filter.waitingAt) return true;
            return (value + "")
              .toLowerCase()
              .includes(this.filter.waitingAt.toLowerCase());
          },
        },
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.estimatedWaitingTime"),
          value: "estimatedWaitingTime",
          sortable: true,
        },
        {
          text: this.$t("ReportOrderEstimatedWaitingTime.realWaitingTime"),
          value: "realWaitingTime",
          sortable: true,
        },
        {
          text: this.$t(
            "ReportOrderEstimatedWaitingTime.waitingTimeDifference"
          ),
          value: "waitingTimeDifference",
          sortable: true,
        },
      ];
    },
  },
  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/order-estimated-waiting-time?from=${this.dateRange[0]}&to=${this.dateRange[1]}`
        )
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
  },
};
</script>
