import cs from "vuetify/lib/locale/cs";
import ReportSettledHour from "../views/ReportSettledHour.vue";

export default {

  $vuetify: {
    ...cs,
  },

  menu: {
    dashboard: "Informační panel",
    console: "Konzole",
    consoleOsm: "Konzole OSM",
    subheaderOperational: "Provozní údaje",
    order: "Objednávky",
    account: "Firmy",
    driverVehicle: "Přidělená vozidla",
    vehicleDriver: "Seznam vozidel",
    vehicleMaintenance: "Údržba vozidel",
    place: "Místa",
    reports: "Reporty",
    reportOverview: "Přehled",
    reportSubheaderStatus: "Stav a počet objednávek",
    reportStatusMonth: "Stavy podle měsíců",
    reportStatusDay: "Stavy podle dní",
    reportStatusHour: "Stavy podle hodin",
    ReportSettledHour: "Zúčtované podle hodin",
    reportStatusMonthPerSource: "Stavy podle zdroje",
    reportDailyVehicles: "Počty vozidel podle dní",
    reportSubheaderSales: "Finance a firemní jízdy",
    reportSalesDriver: "Tržby řidičů",
    reportSalesCompany: "Firemní jízdy",
    reportSubheaderWorkshift: "Pracovní směny",
    reportWorkshift: "Pracovní směny",
    reportWorkshiftSummary: "Přehled pracovních směn",
    reportSubheaderOperations: "Provozní údaje",
    reportCallLog: "Záznamy hovorů",
    reportOrderSentLog: "Odeslané objednávky",
    reportOrderSentManually: "Manuálně odeslané objednávky",
    reportOrderEstimatedWaitingTime: "Odhadovaná čekací doba",
    reportOrderIgnoredAccepted: "Ignorované objednávky",
    reportOrderNotificationAcceptance: "Notifikace objednávek",
    reportOrderStatusSpanHourly: "Trvání stavů objednávek",
    reportSubheaderUsers: "Uživatelé",
    reportDispatcherAccessLog: "Přihlášení dispečerů",
    subheaderUsers: "Uživatelé a vozidla",
    driver: "Řidiči",
    vehicle: "Vozidla",
    dispatcher: "Dispečeři",
    subheaderSettings: "Nastavení",
    locality: "Lokality",
    pricelist: "Ceníky",
    settings: "Nastavení",
    taxiserviceStatus: "Stav taxislužby",
    taxiservice: "Taxislužby",
    subheaderOther: "Ostatní",
    roadmap: "Roadmap",
    servicePlan: "Plán služeb",
    system: "Systém",
    messageDispatch: "Oznámení dispečinku",
    feedback: "Zpětná vazba",
    about: "O aplikaci",
    signout: "Odhlásit se",
    versionChange: "Přepnutí verze",
    versionBeta: "Vyzkoušejte Beta",
    versionProd: "Zpět na Prod",
  },

  page_title: {
    dashboard: "Informační panel",
    console: "Dispečerská konzole",
    consoleOsm: "Dispečerská konzole OSM",
    orderList: "Seznam objednávek",
    orderDetail: "Detail objednávky",
    accountList: "Firemní klienti",
    accountDetail: "Detail firmy",
    driverVehicleList: "Přidělená vozidla",
    vehicleDriverList: "Seznam vozidel",
    vehicleMaintenanceList: "Údržba vozidel",
    placeList: "Seznam míst",
    reportOverview: "Přehled",
    reportStatusMonth: "Stavy objednávek podle měsíců",
    reportStatusDay: "Stavy vytvořených objednávek podle dní",
    ReportSettledHour: "Zúčtované objednávky podle hodin",
    reportStatusMonthPerSource: "Stavy objednávek podle zdroje",
    reportDailyVehicles: "Počty vozidel podle dní",
    reportSalesDriver: "Tržby řidičů",
    reportSalesCompany: "Firemní jízdy",
    reportWorkshift: "Pracovní směny",
    reportWorkshiftSummary: "Souhrn pracovních směn",
    reportCallLog: "Záznamy hovorů",
    reportOrderSentLog: "Odeslané objednávky na řidiče",
    reportOrderSentManually: "Manuálně odeslané objednávky",
    reportOrderEstimatedWaitingTime: "Odhadovaná čekací doba",
    reportOrderIgnoredAccepted: "Ignorované akceptované objednávky",
    reportOrderNotificationAcceptance: "Notifikace akceptovaných objednávek",
    reportOrderStatusSpanHourly: "Trvání stavů objednávek",
    reportDispatcherAccessLog: "Záznamy o přihlášení dispečerů",
    driverList: "Řidiči",
    vehicleList: "Správa vozidel",
    vehicleDetail: "Detail vozidla",
    dispatcherList: "Dispečeři",
    localityList: "Lokality",
    pricelistList: "Správa ceníků",
    priceList: "Ceník",
    settings: "Nastavení",
    taxiserviceList: "Správa taxislužeb",
    taxiserviceDetail: "Detail taxislužby",
    taxiserviceStatus: "Stav taxislužby",
    roadmap: "Plán",
    servicePlan: "Plán služeb",
    messageDispatchList: "Oznámení",
    feedbackList: "Zpětná vazba",
    about: "O aplikaci",
    personalSetting: "Osobní nastavení",
  },

  // Common
  btnCreate: "Vytvořit",
  btnSave: "Uložit",
  btnSubmit: "Odeslat",
  btnCancel: "Zrušit",
  btnClose: "Zavřít",
  btnEdit: "Upravit",
  btnAdd: "Přidat",
  btnAssign: "Přidělit",
  btnReload: "Obnovit",
  btnUpdate: "Aktualizovat",
  btnRemove: "Odstranit",
  btnDelete: "Smazat",
  btnChange: "Změnit",
  btnConfirm: "Potvrdit",
  btnDisable: "Zablokovat",
  btnEnable: "Povolit",
  btnSetDefault: "Nastavit jako výchozí",
  btnExportData: "Exportovat",
  btnImportData: "Importovat",
  btnRedirectHttps: "Přesměrovat na HTTPS",
  DateRangeSelector: "Vyberte rozsah dat",
  //POST request
  msgCreateSuccess: "Záznam byl úspěšně vytvořen",
  msgCreateError: "Záznam se nepodařilo vytvořit",
  //PUT request
  msgUpdateSuccess: "Záznam byl úspěšně aktualizován",
  msgUpdateError: "Záznam se nepodařilo aktualizovat",
  //GET request
  msgGetDataError: "Och, chyba.... Něco se pokazilo při načítání dat",
  //DELETE request
  msgDeleteSuccess: "Záznam byl úspěšně smazán",
  msgDeleteError: "Záznam se nepodařilo smazat",
  //General error response
  msgResponseError: "Och, vyskytla se chyba...",
  msgDataMissing: "Chybějící údaje",
  msgDataValidation: "Chyba validace údajů",
  msgConfirmSlider: "Potvrďte posunutím slideru doprava", //Confirm with slider (move right)
  //Validation rules
  msgRequiredField: "Toto pole je povinné",
  phone_hint: "Mezinárodní formát: +420123456789",
  phone_placeholder: "+420123456789",
  msgPasswordMinLength: "Heslo musí mít alespoň 6 znaků",
  msgPasswordLowerCase: "Heslo musí obsahovat alespoň jedno malé písmeno",
  msgPasswordNumber: "Heslo musí obsahovat alespoň jedno číslo",
  //modules
  msgGetModulesError: "Nepodařilo se načíst nastavení modulů...",
  msgModuleWorklogNotEnabled: "Modul Pracovní směny (Worklog) není povolen",
  msgModuleReportNotEnabled: "Modul Reporty není povolen",
  //HTTPS
  msgHttps: "Pro používání této aplikace je nutné změnit protokol URL adresy na zabezpečený HTTPS",

  Main: {
    showBetaDialog: {
      title: "Beta aplikace",
      text1: "Přejete si přepnout na beta verzi aplikace?",
      text2: "Aplikace je otestovaná, ale může přesto obsahovat chyby. Má však nejnovější funkčnost. Také beta verze může obsahovat opravu chyby, která se mohla vyskytnout v produkční verzi.",
      text3: "Vyzkoušením beta verze pomůžete dřívějšímu odladění funkčnosti a jejímu produkčnímu nasazení.",
      text4: "Také nás zajímá vaše zpětná vazba na nové funkčnosti, aby budoucí verze vyhovovaly vám i ostatním uživatelům.",
      text5: "Kdykoliv se můžete rozhodnout přepnout zpět na produkční verzi.",
      switchButton: "Přepnout na Beta",
    },

    showProdDialog: {
      title: "Produkční verze aplikace",
      text1: "Přejete si přepnout zpět na produkční verzi aplikace?",
      text2: "Pokud byla v beta verzi aplikace chyba, nebo máte připomínky k nové funkčnosti, dejte nám prosím vědět na adresu info@taxximo.com",
      switchButton: "Přepnout na Produkční verzi",
    },
    language: {
      SK: "Slovensky",
      CS: "Česky",
      EN: "English (Anglicky)",
    },
    showLogoutDialog: {
      title: "Ukončení práce a odhlášení se",
      text1: "Přejete si odhlásit se z aplikace?",
      //text1: "Přejete si ukončit pracovní směnu a odhlásit se z aplikace?",
      logoutButton: "Odhlásit se",
      signoutButton: "Ukončit práci a odhlásit se",
    },
    msgGetTaxiservicListForPickerError: "Nepodařilo se načíst seznam taxislužeb pro picker",
    msgChangeTaxiserviceError: "Nepodařilo se změnit taxislužbu",
    msgChangeTaxiserviceSuccess: "Taxislužba byla úspěšně změněna. Aktualizujte si načtená data",
    msgSignOutError: "Nepodařilo se odhlásit z pracovní směny!",
  },
  AutocompleteDriverRemote: {
    prependIconTitle: "Aktualizovat seznam řidičů",
    iconAppendOuterTitle: "Přepínání uspořádání seznamu podle: Označení vozidla | Označení řidiče | Příjmení řidiče",
  },
  AutocompleteAccountRemote: {
    placeholder: "Začněte psát název firmy...",
  },
  AutocompleteAddressOsm: {
    placeholder: "Začněte psát adresu...",
    //title: "Pro přidání nové adresy do databáze klikněte na ikonu před políčkem.",
    title: "Vyhledejte adresu ve službě Open Street Map",
  },
  AutocompleteAddressGeocode: {
    placeholder: "Začněte psát adresu...",
    //title: "Pro přidání nové adresy do databáze klikněte na ikonu před políčkem.",
    title: "Vyhledejte adresu ve službě Geocode Earth",
    iconAppendOuterTitle: "Přepínání filtru vyhledávání podle: Blízkých adres | Pouze na viditelné mapě",
    iconAppendOuterTitleBoundary: "Přepínání filtru vyhledávání podle: Blízkých adres | >Pouze na viditelné mapě<",
    iconAppendOuterTitleFocus: "Přepínání filtru vyhledávání podle: >Blízkých adres< | Pouze na viditelné mapě",
  },
  AutocompleteAddressRemote: {
    placeholder: "Začněte psát adresu...",
    title: "Pro přidání nové adresy do databáze klikněte na ikonu před políčkem.",
    prependIconTitle: "Vyhledat a přidat novou adresu do databáze",
    dialogTitle: "Přidání nové adresy do databáze",
    dialogInputPlaceholder: "Zadejte adresu...",
    btnClose: "Zavřít",
    btnAdd: "Přidat",
    msgAddSuccess: "Adresa byla úspěšně přidána do databáze",
    msgAddErrorEmpty: "Adresu je třeba nejprve vyhledat a vybrat z nabízených možností",
    msgAddErrorDuplicated: "Adresu se nepodařilo přidat do databáze",
    msgAddErrorDuplicatedAlert: "Duplicitní záznam. Prosím vyhledejte adresu normálním způsobem, nevyhledávejte ji přes Přidání adresy.",
    msgAddErrorDuplicatedBan: "Často vyhledáváte adresy, které se již nacházejí v databázi. Překročili jste maximální počet takových adres. Funkce na přidávání nových adres je pro vás dočasně zablokována.",
    msgNoAddress: "Není dostupný detail adresy", //No details available for input
    msgAddAddressNotSearched: "Adresu je třeba pokusit se nejprve vyhledat",
  },
  AutocompleteAddressRemoteGeocode: {
    placeholder: "Začněte psát adresu...",
    title: "Pro přidání nové adresy do databáze klikněte na ikonu před políčkem.",
    prependIconTitle: "Vyhledat a přidat novou adresu do databáze přes službu Geocode Earth",
    dialogTitle: "Přidání nové adresy do databáze",
    autocompleteAddLabel: "Nová adresa",
    btnClose: "Zavřít",
    btnAdd: "Přidat",
    msgAddSuccess: "Adresa byla úspěšně přidána do databáze",
    msgAddErrorDuplicated: "Adresu se nepodařilo přidat do databáze",
    msgAddErrorDuplicatedAlert: "Duplicitní záznam. Prosím vyhledejte adresu normálním způsobem, nevyhledávejte ji přes Přidání adresy.",
    msgAddErrorDuplicatedBan: "Často vyhledáváte adresy, které se již nacházejí v databázi. Překročili jste maximální počet takových adres. Funkce na přidávání nových adres je pro vás dočasně zablokována.",
    msgNoAddress: "Není dostupný detail adresy", //No details available for input
    msgAddAddressNotSearched: "Adresu je třeba pokusit se nejprve vyhledat",
  },
  AutocompleteCityRemote: {
    placeholder: "Začněte psát město...",
    msgSelectCountry: "Pro vyhledání města je třeba nejprve vybrat zemi.",
  },
  WeatherForecast: {
    title: "Počasí",
    msgGetWeatherError: "Nepodařilo se načíst počasí",
    temperature: "Teplota",
    wind: "Vítr",
    humidity: "Vlhkost",
    pressure: "Tlak",
    feelsLike: "Pocitová teplota",
    probabilityOfPrecipitation: "Pravděpodobnost srážek",
  },
  //https://openweathermap.org/weather-conditions
  WeatherEvent: {
    200: "Bouřka", //Thunderstorm
    300: "Mrholení", //Drizzle
    500: "Déšť",//Rain
    600: "Sníh",//Snow
    700: "Atmosférické podmínky",//  Atmosphere
    800: "Jasno",//Clear
    801: "Mírně zataženo",// Mírně zataženo / Few clouds
    802: "Polojasno",// Rozptýlené oblaky / Scattered clouds
    803: "Zataženo",// Rozbité oblaky / Broken clouds
    804: "Zataženo",// Zataženo / Overcast clouds
  },

  ExportData: {
    title: "Exportovat údaje",
    formatDatetime_label: "Formát data a času",
    formatDecimal_label: "Oddělovač desetinných čísel",
    btnExcel: "Excel",
    btnCsv: "CSV",
    btnRawData: "Surová data",
  },

  Login: {
    page_title: "Přihlášení do systému Taxximo",
    email: "Email",
    password: "Heslo",
    btnLogin: "Přihlásit se",
    btnForgottenPassword: "Zapomněli jste heslo?",
    btnDriverApp: "Stáhnout aplikaci řidiče",
    btnDriverAppBeta: "Zapojit se do beta testování aplikace řidiče",
    btnSimpleLogin: "Zjednodušené přihlášení",
    dialogTitle: "Zapomenuté heslo",
    msgWrongFormatEmail: "Zadali jste nesprávný formát emailu",
    msgWrongCredentials: "Zadali jste nesprávný email nebo heslo",
    msgWrongCredentials2: "Zadané údaje jsou nesprávné",
    msgModuleError: "Nepodařilo se načíst nastavení modulů... Kontaktujte administrátora systému nebo obchodního zástupce Taxximo.",
    msgResetSuccess: "Resetovací odkaz byl zaslán na váš email",
    msgRecommendedChrome: "Pro nejlepší výkon aplikace doporučujeme používat prohlížeč Google Chrome",
    msgRecommendedChromeUnsupported: "Pro nejlepší výkon aplikace doporučujeme používat prohlížeč Google Chrome. Váš prohlížeč není podporován. Některé funkce mohou být nedostupné.",
    //zobrazení objednávek z paměti zařízení při výpadku spojení
    dialogOrderListTitle: "Neaktuální seznam objednávek z paměti zařízení - technický výpis",
    btnCheckConnectivity: "Zkontrolovat připojení",
    btnOrderList: "Zobrazit seznam objednávek",
    msgOrderList: "Zobrazují se poslední údaje z paměti zařízení, ne ze serveru. Jsou pravděpodobně neaktuální. Objednávky nelze aktualizovat ani zrušit. Seznam je pouze informativní.",
    msgConnectivity404: "Připojení na server selhalo.",
    msgConnectivity200: "Připojení na server je OK.",
    msgConnectivity302: "Připojení na server je pravděpodobně OK.",
    msgConnectivityOffline: "Zařízení není připojeno k internetu. Zobrazují se poslední zjištěné údaje. Objednávky nelze aktualizovat ani zrušit. Zkuste se připojit nebo počkejte, až bude dostupné internetové připojení.",
    msgConnectivityServerDown: "Údaje ze serveru nejsou dostupné. Možná máte výpadek internetu nebo je výpadek v datacentru. Pokud si zobrazíte seznam objednávek, bude obsahovat pouze poslední zjištěné údaje. Objednávky nelze aktualizovat ani zrušit. Počkejte, až bude server opět dostupný nebo bude dostupné internetové připojení.",
    msgConnectivityNoInternet: "Nemáte dostupný internet. Možná máte výpadek poskytovatele internetových služeb nebo je problém v routeru. Pokud si zobrazíte seznam objednávek, bude obsahovat pouze poslední zjištěné údaje. Objednávky nelze aktualizovat ani zrušit.",
    tableHeaders: {
      id: "ID",
      status: "Stav",
      type: "Typ",
      createdAt: "Čas vytvoření",
      requestedTime: "Požadovaný čas",
      address1Type: "Typ 1",
      address1: "Adresa 1",
      address2Type: "Typ 2",
      address2: "Adresa 2",
      driverFirstname: "Jméno řidiče",
      driverLastname: "Příjmení řidiče",
      vehicleInternalName: "Název vozidla",
      preferredDriverLastname: "Preferovaný řidič",
      note: "Poznámka",
    },
  },

  PersonalSetting: {
    page_title: "Osobní nastavení",
    passwordTitle: "Heslo",
    passwordSubtitle: "Nastavení hesla",
    btnLogout: "Odhlásit se",
    dashboardSelectedItems: "Položky v informačním panelu",
    dialogLogoutTitle: "Odhlášení z aplikace",
    logoutText: "Přejete si odhlásit se z aplikace?",
    DialogPasswordChangeTitle: "Změna hesla",
    passwordOld: "Aktuální heslo",
    passwordNew: "Nové heslo",
    passwordNew2: "Potvrzení nového hesla",
    msgPasswordsNotMatch: "Hesla se neshodují",
    msgDashboardSelectedItemsSuccesfullySaved: "Položky v informačním panelu byly úspěšně uloženy",
  },

  Dashboard: {
    page_title: "Informační panel",
    cardMessageDispatch: "Oznámení",
    cardDdriverOnBreak: "Řidiči na přestávce",
    cardDdriverOnBreakSubtitle: "Seznam řidičů na přestávce",
    cardWorklogRequest: "Žádosti řidičů",
    cardWorklogRequestSubtitle: "Seznam žádostí řidičů o přestávku a ukončení pracovní směny",
    cardDriverOnline: "Aktivní řidiči",
    cardDriverOnlineSubtitle: "Seznam řidičů, kteří jsou volní nebo vykonávají jízdu",
    cardPriceLocality: "Ceníkové lokality",
    cardPriceLocalitySubtitle: "Mapa oblastí, které se využívají na výpočet cen",
    cardWeatherForecast: "Počasí",
    cardWeatherForecastSubtitle: "Předpověď počasí na nejbližší hodiny",
    cardPaymentDue: "Evidujeme platby po datu splatnosti",
    service: "Služba",
    tableHeadersWorklog: {
      type: "Typ",
      status: "Stav",
      driverFirstName: "Jméno",
      driverLastName: "Příjmení",
      createdAt: "Vytvořeno",
    },
    tableHeadersDriverOnBreak: {
      id: "ID",
      firstName: "Jméno",
      lastName: "Příjmení",
    },
    tableHeadersDriverOnline: {
      id: "ID",
      firstName: "Jméno",
      lastName: "Příjmení",
      silence: "Tichý režim",
    },
    silenceEnabledTitle: "Tichý režim zapnutý",
    silenceDisabledTitle: "Tichý režim vypnutý",
    dashcardUtilizationRatio: {
      title: "Vytíženost řidičů",
      actionText: "Procentuální vytíženost řidičů: Procento zaneprázdněných vůči aktivním řidičům. " +
        "\nIdeální hodnota pro pružné vybavování zákazníků je do 80%." +
        "\nOptimální pro efektivitu taxislužby je 100%",
    },
    dashcardLoadRatio: {
      title: "Zatížení taxislužby",
      actionText: "Index zatížení taxislužby: Poměr Aktivních objednávek (realizované + čekající) / Aktivních řidičů." +
        "\nIdeální hodnota pro vybavování zákazníků je 1 a méně." +
        "\nHodnota nad 1 způsobuje, že zákazníci déle čekají na přidělení vozidla." +
        "\nOptimální pro efektivitu (rychlé vytěžování řidičů) taxislužby je od 1 do 1,4. " +
        "\nNad 2 je přetížená taxislužba. V takovém případě je vhodné uvažovat nad odmítáním nových objednávek.",
    },
    dashcardImmediateAvailabilityRatio: {
      title: "Okamžitá dostupnost",
      actionText: "Index okamžité dostupnosti: Poměr objednávek na přidělení a volných řidičů." +
        "\nIdeální hodnota pro vybavování zákazníků je 1 a méně.",
    },
    dashcardCounterPENDING: {
      title: "Nepřidělené objednávky",
      actionText: "Objednávky čekající na přidělení řidičům.",
    },
    dashcardSumPerformedOrders: {
      title: "Realizované objednávky",
      actionText: "Objednávky přidělené řidičům a právě realizované.",
    },
    dashcardSumActiveDrivers: {
      title: "Aktivní řidiči",
      actionText: "Volní a zaneprázdnění řidiči s objednávkami.",
    },
    dashcardSumBusyDrivers: {
      title: "Zaneprázdnění řidiči",
      actionText: "Řidiči s přidělenými objednávkami.",
    },
    dashcardCounterOnlineFREE: {
      title: "Volní řidiči",
      actionText: "Volní řidiči bez objednávek.",
    },
    dashcardCounterONBREAK: {
      title: "Řidiči na přestávce",
      actionText: "Řidiči aktuálně na přestávce.",
    },
  },

  orderStatus: {
    PREREGISTERED: "Předregistrovaná",
    TRANSFERED: "Přesměrovaná",
    NEW: "Nová",
    PLANNED: "Plánovaná",
    PENDING: "Čekající",
    ACCEPTED: "Akceptovaná",
    WAITING: "Na místě",
    IN_PROGRESS: "Ve vozidle",
    FINISHED: "Ukončená",
    CHARGED: "Naúčtovaná",
    SETTLED: "Zúčtovaná",
    ARCHIVED: "Archivovaná",
    CANCELLED_PREREGISTERED: "Odregistrovaná",
    CANCELLED_NEW: "Zneplatněná",
    CANCELLED_PLANNED: "Nerealizovaná",
    CANCELLED_PENDING: "Odvolaná",
    CANCELLED_ACCEPTED: "Odmítnutá",
    CANCELLED_WAITING: "Zrušená",
    CANCELLED_IN_PROGRESS: "Zamítnutá",
    CANCELLED_FINISHED: "Nedokončená",
    CANCELLED_CHARGED: "Nezaúčtovaná",
    CANCELLED_SETTLED: "Nezaplacená",
    CANCELLED_ALL: "Všechny ZRUŠENÉ",
    UNDEFINED: "",
  },

  orderStatusSimple: {
    PREREGISTERED: "Předregistrovaná",
    TRANSFERED: "Přesměrovaná",
    NEW: "Nová",
    PLANNED: "Plánovaná",
    PENDING: "Čekající",
    ACCEPTED: "Akceptovaná",
    WAITING: "Na místě",
    IN_PROGRESS: "Ve vozidle",
    FINISHED: "Ukončená",
    CHARGED: "Naúčtovaná",
    SETTLED: "Zúčtovaná",
    ARCHIVED: "Archivovaná",
    CANCELLED_PREREGISTERED: "Zrušená",
    CANCELLED_NEW: "Zrušená",
    CANCELLED_PLANNED: "Zrušená",
    CANCELLED_PENDING: "Zrušená",
    CANCELLED_ACCEPTED: "Zrušená",
    CANCELLED_WAITING: "Zrušená",
    CANCELLED_IN_PROGRESS: "Zrušená",
    CANCELLED_FINISHED: "Zrušená",
    CANCELLED_CHARGED: "Zrušená",
    CANCELLED_SETTLED: "Zrušená",
    UNDEFINED: "",
  },

  orderStatusExt: {
    UNDEFINED: "",
    CANCELLED_SUM: "Zrušené",
  },

  orderStatus_tooltip: {
    PREREGISTERED: "Předregistrovaná objednávka",
    TRANSFERED: "Přesměrovaná objednávka",
    NEW: "Nová objednávka",
    PLANNED: "Plánovaná objednávka",
    PENDING: "Čekající objednávka",
    ACCEPTED: "Akceptovaná objednávka",
    WAITING: "Vodič čeká na místě vyzvednutí",
    IN_PROGRESS: "Vodič přepravuje zákazníka",
    FINISHED: "Ukončená objednávka",
    CHARGED: "Naúčtovaná objednávka",
    SETTLED: "Zúčtovaná objednávka",
    ARCHIVED: "Archivovaná objednávka",
    CANCELLED_PREREGISTERED: "Zrušená Předregistrovaná objednávka",
    CANCELLED_NEW: "Zrušená Nová objednávka",
    CANCELLED_PLANNED: "Zrušená Plánovaná objednávka",
    CANCELLED_PENDING: "Zrušená Čekající objednávka",
    CANCELLED_ACCEPTED: "Zrušená Akceptovaná objednávka",
    CANCELLED_WAITING: "Zrušená objednávka Na místě",
    CANCELLED_IN_PROGRESS: "Zrušená objednávka Ve vozidle",
    CANCELLED_FINISHED: "Zrušená Ukončená objednávka",
    CANCELLED_CHARGED: "Zrušená Naúčtovaná objednávka",
    CANCELLED_SETTLED: "Zrušená Zúčtovaná objednávka",
    UNDEFINED: "",
  },

  orderStatus_help: {
    PREREGISTERED: "Předregistrovaná objednávka",
    TRANSFERED: "Přesměrovaná objednávka",
    NEW: "Objednávka byla vytvořena zákazníkem a čeká na akceptaci taxislužbou.",
    PLANNED: "Objednávka zatím není přidělena řidiči, byla vytvořena předem na požadovaný čas.",
    PENDING: "Objednávka čeká na okamžité přidělení řidiči. Při zapnutém automatu bude přidělena automaticky.",
    ACCEPTED: "Objednávka byla přidělena řidiči, který ji akceptoval. Je pravděpodobně na cestě k zákazníkovi.",
    WAITING: "Vodič čeká na zákazníka na místě vyzvednutí",
    IN_PROGRESS: "Vodič přepravuje zákazníka po požadované trase",
    FINISHED: "Objednávka je ukončená, zákazník byl přepraven. Vodič připravuje účet.",
    CHARGED: "Objednávka byla naúčtovaná, probíhá platba kartou, kreditem nebo fakturou.",
    SETTLED: "Objednávka byla zaplacena a účet uzavřen.",
    ARCHIVED: "Archivovaná objednávka",
    CANCELLED_PREREGISTERED: "Zrušená Předregistrovaná objednávka",
    CANCELLED_NEW: "Zrušená Nová objednávka",
    CANCELLED_PLANNED: "Zrušená Plánovaná objednávka",
    CANCELLED_PENDING: "Zrušená Čekající objednávka",
    CANCELLED_ACCEPTED: "Zrušená Akceptovaná objednávka",
    CANCELLED_WAITING: "Zrušená objednávka Na místě",
    CANCELLED_IN_PROGRESS: "Zrušená objednávka Ve vozidle",
    CANCELLED_FINISHED: "Zrušená Ukončená objednávka",
    CANCELLED_CHARGED: "Zrušená Naúčtovaná objednávka",
    CANCELLED_SETTLED: "Zrušená Zúčtovaná objednávka",
    UNDEFINED: "",
  },

  orderStatusArray: [
    {
      "text": "Předregistrovaná",
      "value": "PREREGISTERED",
    },
    {
      "text": "Přesměrovaná",
      "value": "TRANSFERED",
    },
    {
      "text": "Nová",
      "value": "NEW",
    },
    {
      "text": "Plánovaná",
      "value": "PLANNED",
    },
    {
      "text": "Čekající",
      "value": "PENDING",
    },
    {
      "text": "Akceptovaná",
      "value": "ACCEPTED",
    },
    {
      "text": "Na místě",
      "value": "WAITING",
    },
    {
      "text": "Ve vozidle",
      "value": "IN_PROGRESS",
    },
    {
      "text": "Ukončená",
      "value": "FINISHED",
    },
    {
      "text": "Naúčtovaná",
      "value": "CHARGED",
    },
    {
      "text": "Zúčtovaná",
      "value": "SETTLED",
    },
    {
      "text": "Archivovaná",
      "value": "ARCHIVED",
    },
    {
      "text": "Odregistrovaná",
      "value": "CANCELLED_PREREGISTERED",
    },
    {
      "text": "Zneplatněná",
      "value": "CANCELLED_NEW",
    },
    {
      "text": "Nerealizovaná",
      "value": "CANCELLED_PLANNED",
    },
    {
      "text": "Odvolaná",
      "value": "CANCELLED_PENDING",
    },
    {
      "text": "Odmítnutá",
      "value": "CANCELLED_ACCEPTED",
    },
    {
      "text": "Zrušená",
      "value": "CANCELLED_WAITING",
    },
    {
      "text": "Zamítnutá",
      "value": "CANCELLED_IN_PROGRESS",
    },
    {
      "text": "Nedokončená",
      "value": "CANCELLED_FINISHED",
    },
    {
      "text": "Nezaúčtovaná",
      "value": "CANCELLED_CHARGED",
    },
    {
      "text": "Nezaplacená",
      "value": "CANCELLED_SETTLED",
    },
    {
      "text": "",
      "value": "UNDEFINED",
    }
  ],


  orderType: {
    INSTANT: "Okamžitá",
    TIME: "Časovka",
    STREET: "Z ulice",
    DIRECT: "Přímá",
    REPEATED: "Opakovaná",
    AIRPORT: "Letištní",
    UNDEFINED: "",
  },

  orderType_tooltip: {
    INSTANT: "Objednávka k okamžité realizaci",
    TIME: "Objednávka na přesný čas",
    STREET: "Objednávka z ulice",
    DIRECT: "Přímá objednávka",
    REPEATED: "Opakovaná objednávka",
    AIRPORT: "Objednávka s vyzvednutím na letišti",
    UNDEFINED: "",
  },

  orderTypeArray: [
    {
      "text": "Okamžitá",
      "value": "INSTANT",
    },
    {
      "text": "Časovka",
      "value": "TIME",
    },
    {
      "text": "Z ulice",
      "value": "STREET",
    },
    {
      "text": "Přímá",
      "value": "DIRECT",
    },
    {
      "text": "Opakovaná",
      "value": "REPEATED",
    },
    {
      "text": "Letištní",
      "value": "AIRPORT",
    },
    {
      "text": "",
      "value": "UNDEFINED",
    }
  ],


  source: {
    DISPATCH_TAXXIMO: "Dispečink Txx.",
    DISPATCH_TAXISERVICE: "Dispečink",
    DISPATCH_BROKER: "Dispečink B.",
    CUSTOMER_ANDROID: "Zákazník AD",
    CUSTOMER_IOS: "Zákazník iOS",
    BRAND_ANDROID: "Zákazník AD B.",
    BRAND_IOS: "Zákazník iOS B.",
    DRIVER_ANDROID: "Řidič",
    PBX: "Ústředna",
    UNDEFINED: "",
    undefined: "",
  },

  source_short: {
    DISPATCH_TAXXIMO: "DispečX",
    DISPATCH_TAXISERVICE: "Dispeč",
    DISPATCH_BROKER: "DispečB",
    CUSTOMER_ANDROID: "Zákazník",
    CUSTOMER_IOS: "Zákazník",
    BRAND_ANDROID: "Zákazník",
    BRAND_IOS: "Zákazník",
    DRIVER_ANDROID: "Řidič",
    PBX: "Ústředna",
    undefined: "",
  },

  source_tooltip: {
    DISPATCH_TAXXIMO: "Dispečink Taxximo",
    DISPATCH_TAXISERVICE: "Dispečink taxislužby",
    DISPATCH_BROKER: "Společný dispečink brokera",
    CUSTOMER_ANDROID: "Zákaznická aplikace Taxximo, systém Android",
    CUSTOMER_IOS: "Zákaznická aplikace Taxximo, systém iOS",
    BRAND_ANDROID: "Zákaznická brandovaná aplikace, systém Android",
    BRAND_IOS: "Zákaznická brandovaná aplikace, systém iOS",
    DRIVER_ANDROID: "Aplikace řidiče, systém Android",
    PBX: "Telefonní ústředna",
    undefined: "",
    UNDEFINED: "",
  },

  sourceArray: [
    { "text": "Dispečink Txx.", "value": "DISPATCH_TAXXIMO" },
    { "text": "Dispečink", "value": "DISPATCH_TAXISERVICE" },
    { "text": "Dispečink B.", "value": "DISPATCH_BROKER" },
    { "text": "Zákazník AD", "value": "CUSTOMER_ANDROID" },
    { "text": "Zákazník iOS", "value": "CUSTOMER_IOS" },
    { "text": "Zákazník AD B.", "value": "BRAND_ANDROID" },
    { "text": "Zákazník iOS B.", "value": "BRAND_IOS" },
    { "text": "Řidič", "value": "DRIVER_ANDROID" },
    { "text": "Ústředna", "value": "PBX" },
    { "text": "", "value": "UNDEFINED" }
  ],

  paymentType: {
    CASH: "Hotovost",
    CARD: "Kartou terminál",
    VIAMO: "VIAMO",
    COUPON: "Poukázka",
    CARD_ONLINE: "Kartou",
    PREPAID: "Kredit",
    POSTPAID: "Faktura",
    INVOICE: "Faktura Broker",
    UNDEFINED: "",
    undefined: "",
  },
  paymentType_tooltip: {
    CASH: "Platba v hotovosti",
    CARD: "Platba kartou přes EFTPOS terminál ve vozidle",
    VIAMO: "Platba přes VIAMO",
    COUPON: "Platba poukázkou",
    CARD_ONLINE: "Platba přes platební bránu",
    PREPAID: "Platba z předplaceného kreditu",
    POSTPAID: "Platba na fakturu účtovanou od taxislužby",
    INVOICE: "Platba na fakturu přeúčtovaná přes brokera",
    undefined: "",
  },
  paymentTypeArray: [
    { "text": "Hotovost", "value": "CASH" },
    { "text": "Kartou terminál", "value": "CARD" },
    { "text": "VIAMO", "value": "VIAMO" },
    { "text": "Poukázka", "value": "COUPON" },
    { "text": "Kartou", "value": "CARD_ONLINE" },
    { "text": "Kredit", "value": "PREPAID" },
    { "text": "Faktura", "value": "POSTPAID" },
    { "text": "Faktura Broker", "value": "INVOICE" },
    { "text": "", "value": "undefined" }
  ],
  workStatus: {
    OFFLINE: "Volno",
    ONLINE: "V práci",
    ON_BREAK: "Přestávka",
    NONE: "",
    UNDEFINED: "",
  },
  activeTaxiserviceSelection: {
    ACTIVE: "Vybraná",
    DISABLED: "Nevybraná",
    UNDEFINED: "",
  },
  worklogRequestType: {
    OFFLINE: "Ukončení směny",
    ONLINE: "Zahájení směny",
    ON_BREAK: "Přestávka",
    NONE: "",
    UNDEFINED: "",
  },
  worklogRequestStatus: {
    NEW: "Nové",
    CONFIRMED: "Schválené",
    DECLINED: "Odmítnuté",
    DONE: "Zrealizované",
    CANCELLED: "Zrušené",
    UNDEFINED: "",
  },
  weekdayName: {
    0: "Neděle",
    1: "Pondělí",
    2: "Úterý",
    3: "Středa",
    4: "Čtvrtek",
    5: "Pátek",
    6: "Sobota",
    null: "",
  },

  dashboardItems: [
    { "text": "Vytížení řidičů", "value": "utilizationRatio" },
    { "text": "Zatížení taxislužby", "value": "loadRatio" },
    { "text": "Okamžitá dostupnost", "value": "immediateAvailabilityRatio" },
    { "text": "Nepřiřazené objednávky", "value": "counterPENDING" },
    { "text": "Realizované objednávky", "value": "sumPerformedOrders" },
    { "text": "Aktivní řidiči", "value": "sumActiveDrivers" },
    { "text": "Zaneprázdnění řidiči", "value": "sumBusyDrivers" },
    { "text": "Volní řidiči", "value": "counterOnlineFREE" },
    { "text": "Řidiči na přestávce", "value": "counterON_BREAK" },
    { "text": "Ceníkové lokality - mapa", "value": "priceLocalityList" },
    { "text": "Aktivní řidiči - seznam", "value": "driverOnlineItems" },
    { "text": "Řidiči na přestávce - seznam", "value": "driverOnBreakItems" },
    { "text": "Schvalování pracovních směn - seznam", "value": "worklogRequestItems" },
  ],
  OrderListView: {
    page_title: "Seznam objednávek",
    mobileBreakpointSwitch_label: "Tabulkové zobrazení",
    selectedHeaders_label: "Vyberte sloupce",
    onlyRealized_label: "Pouze realizované",
    actions: "Akce",
    Id: "Č. objednávky",
    Id_tooltip: "Technické číslo objednávky {msg}",
    Status: "Stav",
    Type: "Typ",
    Source: "Zdroj",
    PaymentType: "Platba",
    Note: "Poznámka",
    CreatedAt: "Vytvořeno",
    AcceptedAt: "Akceptováno",
    StartedAt: "Započato",
    FinishedAt: "Ukončeno",
    DriverId: "Č. řidiče",
    DriverName: "Jméno řidiče",
    DriverFirstName: "Jméno řidiče",
    DriverLastName: "Příjmení řidiče",
    ClientId: "Č. zákazníka",
    ClientNickname: "Jméno zákazníka",
    ClientPhone: "Telefon zákazníka",
    VehicleLpn: "SPZ vozidla",
    VehicleBrand: "Značka vozidla",
    VehicleModel: "Model vozidla",
    VehicleInternalName: "Interní název vozidla",
    boardAddress: "Vyzvednutí",
    destinationAddress: "Cíl",
    EstimatedDistance: "Předpokládaná vzdálenost",
    EstimatedTime: "Předpokládaný čas",
    FinalDistance: "Finální vzdálenost",
    FinalTime: "Finální čas",
    InfoDistance: "Informační vzdálenost",
    InfoTime: "Informační čas",
    InfoPriceTotal: "Informační celková cena",
    EstimatedPriceTotal: "Předpokládaná celková cena",
    FinalPriceStart: "Finální cena za vyzvednutí",
    FinalPriceDrive: "Finální cena za jízdu",
    FinalPriceWaiting: "Finální cena za čekání",
    FinalPriceJourney: "Finální cena za jízdu",
    FinalPriceExtra: "Finální příplatek",
    FinalPriceDiscount: "Finální sleva",
    FinalPriceTotal: "Finální celková cena",
    OrdersCount: "Počet objednávek",
    OrderCounter: "Počet objednávek",
    BreakCounter: "Počet přestávek",
    BreakDuration: "Délka přestávek",
    MileagesStart: "Počáteční stav kilometrů",
    MileagesFinish: "Koncový stav kilometrů",
    Mileages: "Ujeté kilometry",
    Duration: "Trvání",
    Distance: "Ujetá vzdálenost",
    NetDuration: "Čas bez přestávek",
    AccountId: "Č. firmy",
    AccountName: "Název firmy",
    filterLabel: {
      actions: "Akce",
      Id: "č. objednávky",
      Status: "Stav",
      Type: "Typ",
      Source: "Zdroj",
      PaymentType: "Platba",
      Note: "Poznámka",
      CreatedAt: "Vytvořeno",
      AcceptedAt: "Akceptováno",
      DriverId: "Č. řidiče",
      DriverName: "Jméno řidiče",
      DriverFirstName: "Jméno řidiče",
      DriverLastName: "Příjmení řidiče",
      VehicleLpn: "SPZ",
      boardAddress: "Vyzvednutí",
      destinationAddress: "Cíl",
      AccountName: "Název firmy",
      AccountId: "ID firmy",
    },
    RefreshData: "Aktualizovat",
    vizualizeAddressOnMapDialog_title: "Vizualizace adres na mapě",
    btnVizualizeAddresses: "Vizualizace adres",
    btnVizualizeAddresses_title: "Zobrazit místa vyzvednutí a cílů objednávek na mapě pomocí zobrazení heatmap",
    btnVizualizeBoardAddresses: "Vizual. vyzvednutí",
    btnVizualizeBoardAddresses_title: "Zobrazit místa vyzvednutí objednávek na mapě pomocí zobrazení heatmap",
    btnVizualizeDestinationAddresses: "Vizual. cíle",
    btnVizualizeDestinationAddresses_title: "Zobrazit místa cílů objednávek na mapě pomocí zobrazení heatmap",
    detailDialog_title: "Detail objednávky",
  },


  OrderView: {
    page_title: "Detail objednávky",
    reducePositions_title: "Redukovat počet markerů, pokud jich je na mapě příliš mnoho",
    tabInfo: "Info",
    tabRoute: "Trasa",
    tabTimeline: "Záznamy",
    tabPrice: "Cena",
    // Tab Info
    headerBasicData: "Základní údaje o objednávce",
    id: "Číslo objednávky",
    status: "Stav",
    source: "Zdroj",
    type: "Typ",
    paymentType: "Typ platby",
    preferredDriverFirstName: "Jméno preferovaného řidiče",
    preferredDriverLastName: "Příjmení preferovaného řidiče",
    requestedTime: "Požadovaný čas",
    presendTime: "Čas zaslání předem (minuty)",
    remainingTime: "Odhadovaný čas do vyzvednutí",
    preferredDriverId: "ID preferovaného řidiče",
    note: "Poznámka",
    headerClient: "Klient",
    nickname: "Jméno",
    clientPhone: "Telefon",
    mark: "Typ",
    clientNote: "Poznámka",
    clientRating: "Hodnocení",
    clientDriveCounter: "Počet jízd",
    clientRateCount: "Počet hodnocení",
    clientRateSum: "Součet bodů hodnocení",
    headerAccount: "Firemní jízda",
    accountName: "Název firmy",
    accountStatus: "Stav firmy",
    accountId: "ID firmy",
    accountNone: "Žádná",
    headerDriver: "Řidič",
    driverFirstName: "Jméno",
    driverLastName: "Příjmení",
    driverPhone: "Telefon",
    driverNickname: "Označení",
    driverRating: "Hodnocení",
    driverDriveCounter: "Počet jízd",
    driverRateCount: "Počet hodnocení",
    driverRateSum: "Součet bodů hodnocení",
    driverNone: "Žádný",
    driverId: "ID řidiče",
    orderDriverRating: "Hodnocení od zákazníka",
    orderClientRating: "Hodnocení od řidiče",
    headerOrderFilter: "Požadavky na jízdu",
    headerDisplayAndSend: "Zobrazení a odeslání objednávky",
    showPhone_short: "Telefon zákazníka",
    showDriverPhoneButton_short: "Tlačítko volání",
    showAllAddresses_short: "Všechny adresy",
    onlyManualSending_short: "Manuální odesílání",
    forcedPickupRadius_short: "Rádius vyzvednutí",
    showPrice: "Zobrazení ceny řidiči a zákazníkovi",
    showPhone: "Řidiči zobrazit telefonní číslo zákazníka",
    showDriverPhoneButton: "Řidiči zobrazit tlačítko volání",
    showAllAddresses: "Řidiči zobrazit všechny adresy",
    onlyManualSending: "Pouze manuální odeslání objednávky",
    forcedPickupRadius: "Vynucení vyzvednutí v okruhu (0 = vypnuto)",
    headerWaypointList: "Trasa",
    headerChat: "Chat",
    headerOrderSentLogs: "Záznamy o odeslání objednávky řidičům",
    cardFinalPriceWaiting: "Čekání",
    cardFinalPriceJourney: "Jízda",
    cardFinalPriceExtra: "Extra",
    cardFinalPriceDiscount: "Sleva",
    cardFinalPriceTotal: "Celkem",
    subheaderPrice: "Analýza ceny",
    displayCalculationSwitchHide: "Skrýt výpočet",
    displayCalculationSwitchShow: "Zobrazit výpočet",
    tblHeaderPriceName: "Název",
    tblHeaderPriceEstimated: "Očekávaná",
    tblHeaderPriceFinal: "Finální",
    tblHeaderPriceInfo: "Informační",
    tblPrice: {
      locFrom: "Lokalita vyzvednutí",
      locTo: "Lokalita cíle cesty",
      distance: "Vzdálenost přepravy",
      time: "Čas přepravy",
      priceWaiting: "Cena za čekání před začátkem přepravy zákazníka",
      priceStart: "Cena za vyzvednutí",
      priceDriveDistance: "Cena za vzdálenost přepravy",
      priceDriveTime: "Cena za čas přepravy",
      priceDrive: "Cena za přepravu",
      priceJourney: "Cena za jízdu",
      priceExtra: "Příplatky",
      priceDiscount: "Slevy",
      priceTotal: "Celková cena objednávky",
    },
    subheaderPriceItems: "Cenové položky",
    tblPriceItems: {
      type: "Typ",
      name: "Název",
      active: "Aktivní",
      cancelPriceFixation: "Zrušit fixaci",
      resetToDefualtPrice: "Obnovit výchozí cenu",
      unitPrice: "Cena",
      quantity: "Množství",
      unit: "Jednotka",
      price: "Celková cena",
    },
    addressMarker_BOARD: "Místo vyzvednutí",
    addressMarker_DESTINATION: "Cíl cesty",
    addressMarker_TRANSIT1: "1. mezizastávka",
    addressMarker_TRANSIT2: "2. mezizastávka",
    addressMarker_TRANSIT3: "3. mezizastávka",
    addressMarker_TRANSIT4: "4. mezizastávka",
    addressMarker_TRANSIT: "Mezizastávka",
    // position marker title
    markerTitleId: "ID bodu",
    markerTitleOrderStatus: "Stav objednávky",
    markerTitleDriverId: "Řidič",
    markerTitleSpeed: "Rychlost",
    markerTitleCreatedAt: "Vytvořeno (UTC)",
    markerTitleRecordedAt: "Zaznamenáno (UTC)",
    timeline: {
      // VYTVOŘENÍ OBJEDNÁVKY
      createdAt_title: "Vytvořena objednávka",
      createdAt_text: "Objednávka vytvořena v systému",
      // ZMĚNY STAVŮ OBJEDNÁVKY
      plannedAt_title: "Naplánovaná objednávka",
      plannedAt_text: "Objednávka naplánována",
      pendingAt_title: "Objednávka připravena k přidělení řidiči",
      pendingAt_text: "Objednávka ",
      acceptedAt_title: "Objednávka akceptována řidičem",
      acceptedAt_text: "Řidič akceptoval objednávku. Pokud nemá jinou probíhající jízdu, měl by být na cestě k zákazníkovi.",
      waitingAt_title: "Řidič čeká na zákazníka",
      waitingAt_text: "Řidič dorazil na místo vyzvednutí zákazníka",
      progressAt_title: "Řidič veze zákazníka",
      progressAt_text: "Řidič zahájil přepravu zákazníka",
      finishedAt_title: "Řidič ukončil jízdu",
      finishedAt_text: "Řidič dopravil zákazníka do požadovaného cíle cesty.",
      chargedAt_title: "Řidič připravil účet",
      chargedAt_text: "Řidič zpracoval finální účet. Pokud má tuto možnost povolenu, mohl upravit cenu za jízdu nebo příplatky.",
      settledAt_title: "Ukončené vyúčtování jízdy",
      settledAt_text: "Objednávka byla zaplacena.",
      interruptedAt_title: "Zrušená objednávka",
      interruptedAt_text: "Objednávka byla zrušena.",
      // POŽADOVANÝ ČAS VYZVEDNUTÍ
      requestedTimeInstant_title: "Požadovaný čas vyzvednutí - okamžitě",
      requestedTimeInstant_text: "Čas vyzvednutí je u časové objednávky v budoucnu, u okamžité jízdy se přibližně shoduje s časem vytvoření.",
      orderSentLogsTime_title: "Požadovaný čas vyzvednutí",
      orderSentLogsTime_text: "Čas vyzvednutí u časové objednávky",
      // CHAT
      chatList_title: "Zprávy / Chat",
      // WAYPOINT
      waypointIssue_title: "Pozdní příjezd na místo vyzvednutí zákazníka",
      waypointIssue1_text: "Řidič přijel na místo o ",
      waypointIssue2_text: " minut později, než byl požadovaný čas vyzvednutí.",
      waypointIssue_detail: "Požadovaný čas: ",
      waypointListBoard_title: "Místo vyzvednutí",
      waypointListDestination_title: "Cíl cesty",
      waypointListTransit_title: "Mezizastávka",
      // WAITING
      waitingListStartedAt_title: "Zapnutí čekání",
      waitingListStartedAt_text: "Řidič zapnul čekání",
      waitingListFinishedAt_title: "Vypnutí čekání",
      waitingListFinishedAt1_text: "Řidič vypnul čekání, které trvalo ",
      waitingListFinishedAt2_text: "min. (přesná hodnota ",
      // ORDER LOGS
      orderLogList_title: "Záznamy logů",
      // ORDER SEND LOGS
      orderSentLogs_title: "Odeslání objednávky",
      orderSentLogs1_text: "Objednávka byla odeslána (",
      orderSentLogs2_text: ") řidiči ",
      orderSentLogs3_text: " který byl od místa vyzvednutí vzdálen ",
      orderSentLogs4_text: "",
    },
  },



  dashboardItems: [
    { "text": "Vytížení řidičů", "value": "utilizationRatio" },
    { "text": "Zatížení taxislužby", "value": "loadRatio" },
    { "text": "Okamžitá dostupnost", "value": "immediateAvailabilityRatio" },
    { "text": "Nepřiřazené objednávky", "value": "counterPENDING" },
    { "text": "Realizované objednávky", "value": "sumPerformedOrders" },
    { "text": "Aktivní řidiči", "value": "sumActiveDrivers" },
    { "text": "Zaneprázdnění řidiči", "value": "sumBusyDrivers" },
    { "text": "Volní řidiči", "value": "counterOnlineFREE" },
    { "text": "Řidiči na přestávce", "value": "counterON_BREAK" },
    { "text": "Ceníkové lokality - mapa", "value": "priceLocalityList" },
    { "text": "Aktivní řidiči - seznam", "value": "driverOnlineItems" },
    { "text": "Řidiči na přestávce - seznam", "value": "driverOnBreakItems" },
    { "text": "Schvalování pracovních směn - seznam", "value": "worklogRequestItems" },
  ],
  OrderListView: {
    page_title: "Seznam objednávek",
    mobileBreakpointSwitch_label: "Tabulkové zobrazení",
    selectedHeaders_label: "Vyberte sloupce",
    onlyRealized_label: "Pouze realizované",
    actions: "Akce",
    Id: "Č. objednávky",
    Id_tooltip: "Technické číslo objednávky {msg}",
    Status: "Stav",
    Type: "Typ",
    Source: "Zdroj",
    PaymentType: "Platba",
    Note: "Poznámka",
    CreatedAt: "Vytvořeno",
    AcceptedAt: "Akceptováno",
    StartedAt: "Započato",
    FinishedAt: "Ukončeno",
    DriverId: "Č. řidiče",
    DriverName: "Jméno řidiče",
    DriverFirstName: "Jméno řidiče",
    DriverLastName: "Příjmení řidiče",
    ClientId: "Č. zákazníka",
    ClientNickname: "Jméno zákazníka",
    ClientPhone: "Telefon zákazníka",
    VehicleLpn: "SPZ vozidla",
    VehicleBrand: "Značka vozidla",
    VehicleModel: "Model vozidla",
    VehicleInternalName: "Interní název vozidla",
    boardAddress: "Vyzvednutí",
    destinationAddress: "Cíl",
    EstimatedDistance: "Předpokládaná vzdálenost",
    EstimatedTime: "Předpokládaný čas",
    FinalDistance: "Finální vzdálenost",
    FinalTime: "Finální čas",
    InfoDistance: "Informační vzdálenost",
    InfoTime: "Informační čas",
    InfoPriceTotal: "Informační celková cena",
    EstimatedPriceTotal: "Předpokládaná celková cena",
    FinalPriceStart: "Finální cena za vyzvednutí",
    FinalPriceDrive: "Finální cena za jízdu",
    FinalPriceWaiting: "Finální cena za čekání",
    FinalPriceJourney: "Finální cena za jízdu",
    FinalPriceExtra: "Finální příplatek",
    FinalPriceDiscount: "Finální sleva",
    FinalPriceTotal: "Finální celková cena",
    OrdersCount: "Počet objednávek",
    OrderCounter: "Počet objednávek",
    BreakCounter: "Počet přestávek",
    BreakDuration: "Délka přestávek",
    MileagesStart: "Počáteční stav kilometrů",
    MileagesFinish: "Koncový stav kilometrů",
    Mileages: "Ujeté kilometry",
    Duration: "Trvání",
    Distance: "Ujetá vzdálenost",
    NetDuration: "Čas bez přestávek",
    AccountId: "Č. firmy",
    AccountName: "Název firmy",
    filterLabel: {
      actions: "Akce",
      Id: "č. objednávky",
      Status: "Stav",
      Type: "Typ",
      Source: "Zdroj",
      PaymentType: "Platba",
      Note: "Poznámka",
      CreatedAt: "Vytvořeno",
      AcceptedAt: "Akceptováno",
      DriverId: "Č. řidiče",
      DriverName: "Jméno řidiče",
      DriverFirstName: "Jméno řidiče",
      DriverLastName: "Příjmení řidiče",
      VehicleLpn: "SPZ",
      boardAddress: "Vyzvednutí",
      destinationAddress: "Cíl",
      AccountName: "Název firmy",
      AccountId: "ID firmy",
    },
    RefreshData: "Aktualizovat",
    vizualizeAddressOnMapDialog_title: "Vizualizace adres na mapě",
    btnVizualizeAddresses: "Vizualizace adres",
    btnVizualizeAddresses_title: "Zobrazit místa vyzvednutí a cílů objednávek na mapě pomocí zobrazení heatmap",
    btnVizualizeBoardAddresses: "Vizual. vyzvednutí",
    btnVizualizeBoardAddresses_title: "Zobrazit místa vyzvednutí objednávek na mapě pomocí zobrazení heatmap",
    btnVizualizeDestinationAddresses: "Vizual. cíle",
    btnVizualizeDestinationAddresses_title: "Zobrazit místa cílů objednávek na mapě pomocí zobrazení heatmap",
    detailDialog_title: "Detail objednávky",
  },

  ConsoleMap: {
    markerTitle_2Active: "2 aktivní objednávky",
    markerTitle_3Active: "3 aktivní objednávky",
    markerTitle_4Active: "4 aktivní objednávky",
    markerTitle_timeTypeWarning: "Řidič má další objednávku, která je časová",
    markerTitle_silenceEnabled: "Tichý režim zapnutý",
    markerTitle_preferredDriverInconsistencyActual: "Aktuální jízda je přidělena jinému řidiči než preferovanému",
    markerTitle_preferredDriverInconsistencyOther: "Jiná jízda je přidělena jinému řidiči než preferovanému",
    markerTitle_worklogRequestOnbreakNew: "Nová žádost o přestávku",
    markerTitle_worklogRequestOnbreakConfirmed: "Schválená žádost o přestávku",
    markerTitle_worklogRequestOfflineNew: "Nová žádost o ukončení práce",
    markerTitle_worklogRequestOfflineConfirmed: "Schválená žádost o ukončení práce",
    markerTitle_outdatedPosition: "Delší dobu nemáme aktuální polohu",
    markerTitle_timeToDestination: "Vyložení zákazníka za",
    markerTitle_driverSpeed: "Rychlost vozidla",
    markerTitle_timeFromLastOrder: "Čas od ukončení poslední jízdy",
    markerTitle_streetOrder: "Jízda z ulice",
    markerTitlePlace_board: "Místo vyzvednutí zákazníka",
    markerTitlePlace_destination: "Cíl cesty",
    markerTitlePlace_transit1: "1. mezizastávka",
    markerTitlePlace_transit2: "2. mezizastávka",
    markerTitlePlace_transit3: "3. mezizastávka",
    markerTitlePlace_transit4: "4. mezizastávka",
    infoWindow_btnSetDriver: "Nastavit tohoto řidiče v poli na záložce DETAIL objednávky",
    infoWindow_btnMessageDriver: "Poslat řidiči zprávu",
    infoWindow_btnDetail: "Zobrazit detail objednávky",
    infoWindow_btnMessageDriverClient: "Poslat zprávu Zákazníkovi a Řidiči k objednávce",
    msgFindDriverOnMap1: "Řidič s ID",
    msgFindDriverOnMap2: "se nenachází na mapě.",
    msgSetDriverToDetailFromMapSuccess: "Řidič přidán do detailu objednávky...",
    msgSetDriverToDetailFromMapError: "Nebyl nalezen žádný vhodný volný blízký řidič...",
    markerOrderTitle_durationSinceTryingAssign: "Doba přidělování",
    markerOrderTitle_addresses: "Adresy",
    markerOrderTitle_customer: "Zákazník",
    markerOrderTitle_driver: "Řidič",
    markerOrderTitle_pbx: "Ústředna",
    markerOrderTitle_preferredDriver: "Preferovaný řidič",
    markerOrderTitle_dispatch: "Dispečink",
    markerOrderTitle_time: "Časová",
    markerOrderTitle_instant: "Okamžitá",
    defaultStand: "Předvolené stanoviště",
    stand: "Stanoviště",
    capacity: "Kapacita",
    radius: "Rádius",
  },

  ConsoleOrderNew: {
    btnCreate: "Vytvořit",
    btnClear: "Vyčistit",
    phone: "Telefonní číslo",
    phoneRules: "Telefonní číslo musí být ve formátu +421XXXXXXXXX",
    btnGetClientByPhone_title: "Vyhledat podle telefonního čísla",
    btnGetClientByExtension_title: "Vyhledat podle probíhajícího hovoru na ústředně",
    extension: "Linka/klapka",
    extensionExpand: "Rozbalit klapky na tlačítka",
    extensionCollapse: "Skrýt tlačítka s klapkami",
    presetRandomOn: "Přednastavit náhodné telefonní číslo",
    presetRandomOff: "Vypnout přednastavení náhodného telefonního čísla",
    nickname: "Jméno zákazníka",
    btnOpenEditClientDialog_title: "Upravit údaje o zákazníkovi",
    btnOpenInfoClientDialog_title: "Informace o zákazníkovi",
    btnOpenHistoryClientDialog: "Historie objednávek zákazníka",
    clientInfo: "Informace o zákazníkovi",
    clientMoreAccounts: "další",
    clientNote: "Poznámka o zákazníkovi",
    countryCode: "Země",
    countryCode_title: "Země",
    countryCode_prepend_title: "Vyčistěte pole země a města",
    city: "Město",
    city_title: "Město",
    boardAddress: "Adresa vyzvednutí",
    transitAddresses: "Mezizastávky",
    transit1Address: "1. Adresa mezizastávky",
    transit2Address: "2. Adresa mezizastávky",
    transit3Address: "3. Adresa mezizastávky",
    transit4Address: "4. Adresa mezizastávky",
    destinationAddress: "Adresa cíle",
    radioBtnInstant: "Okamžitě",
    radioBtnTime: "Později",
    preferredDriverId: "Preferovaný řidič",
    preferredDriverId_title: "Řidič, který bude mít přednost při přidělení objednávky, není to však zaručeno",
    radioBtnClient: "Osobně",
    radioBtnAccount: "Firma",
    accountId: "Firma",
    paymentType: "Způsob platby",
    requestedTime: "Požadovaný čas",
    presendTime: "Zasílat předem",
    btnAddRequestedTimeToNote: "Přidat požadovaný čas do poznámky",
    btnAddClientPhoneToNote: "Přidat telefonní číslo do poznámky v objednávce",
    note: "Poznámka",
    noteInsertTime: "Čas",
    noteInsertAccount: "Firma",
    noteInsertClientPhone: "Tel",
    orderFilterHeader: "Požadavky na jízdu",
    orderFilter: {
      animal: "Zvíře",
      airconditioning: "Klimatizace",
      luggage: "Zavazadlo",
      autonomous: "Autonomní jízda",
      paymentTerminal: "Platební terminál",
      childrenChair: "Přeprava dítěte",
      driverRating: "Hodnocení řidiče",
      economy: "Eko jízda",
      maxPerson: "Počet cestujících",
      specialTransport: "Speciální přeprava",
    },
    onlyManualSending: "Zasílání objednávky",
    createdClientDialog_title: "Nový zákazník",
    defaultNewClientDialog_title: "Výchozí nastavení nového zákazníka",
    editClientDialog_title: "Upravit zákazníka",
    infoClientDialog_title: "Informace o zákazníkovi",
    historyClientDialog_title: "Historie objednávek zákazníka",
    createOrderDialog_title: "Vytvořit novou objednávku",
    successCreateOrderDialog_title: "Úspěšně vytvořená nová objednávka",
    setAccountIdFromClientDialog_title: "Nastavit firmu zákazníka",
    showOrderDetailDialog_title: "Detail objednávky",
    showPhoneHistoryDialog_title: "Historie posledních telefonních čísel",
    mark: "Označení",
    notification: "Notifikace",
    virtualAssistant: "Virtuální asistent",
    rating: "Hodnocení",
    driveCounter: "Počet jízd",
    accounts: "Firmy",
    accountName: "Název firmy",
    accountClientStatus: "Stav uživatele ve firmě",
    msgPhoneCrountryAlert1: " Telefonní číslo je pravděpodobně z JINÉ ZEMĚ (",
    msgPhoneCrountryAlert2: "). Nastavte prosím notifikaci, zda chcete zasílat SMS.",
    msgPhoneLandlineAlert: "Telefonní číslo je pravděpodobně PEVNÁ LINKA. Nastavte prosím notifikaci, zda chcete zasílat SMS.",
    msgOrderCreateSuccess: "Objednávka byla úspěšně vytvořena.",
    msgOrderCreateText: "Chcete ponechat data formuláře (Zavřít), vytvořit novou prázdnou objednávku, nebo načíst nový hovor pro novou prázdnou objednávku?",
    autoHideSuccessCreateOrderDialogSwitch: "Automaticky skrýt a vyčistit formulář pro novou objednávku po 2 sekundách",
    btnNewOrder: "Nová objednávka",
    btnGetClientByExtension: "Načíst hovor",
    btnShowOrderDetail_title: "Detail právě vytvořené nové objednávky",
    msgSetAccountIdText: "Klient má povolené firemní jízdy! Chcete vytvořit klientovi objednávku s výchozí firmou?",
    alwaysShowAccountAlertDialog_label: "Zobrazovat upozornění",
    alwaysShowAccountAlertDialog_title: "Zobrazovat okno s upozorněním, že klient má povolené firemní jízdy",
    btnPaymentPrivate: "Soukromá objednávka",
    btnPaymentAccount: "Firemní objednávka",
    msgShowOrderDetailText: "Klient má probíhající objednávku! Chcete zobrazit detail objednávky?",
    alwaysShowOrderDetailAlertDialog_label: "Zobrazovat upozornění",
    alwaysShowOrderDetailAlertDialog_title: "Zobrazovat okno s upozorněním, že klient má probíhající objednávku",
    btnShowOrderDetail: "Detail objednávky",
    rulesPresendTime1: "Min. 1min",
    rulesPresendTime2: "Max. 240min",
    presendTimeChip_title: "Klikněte pro nastavení zasílání předem",
    rulesNote: "Poznámka může mít maximálně 80 znaků",
    btnActionHideText: "Skrýt",
    btnActionShowText: "Zobrazit",
    msgActionShowFullForm: "Chcete vyzkoušet ROZŠÍŘENÉ MOŽNOSTI okna Nové objednávky?",
    msgReverseGeoAlertNoResults: "Nebyly nalezeny žádné výsledky",
    msgNoExtensionSelected: "Nebyla vybrána žádná linka",
    msgNoCallOnExtension: "Na lince není žádný hovor",
    msgCreateNewCustomer: "Zaevidujte nového klienta!",
    msgPhoneGateError: "Chyba při komunikaci s telefonní ústřednou!",
    msgPhoneGateErrorOther: "Chyba při komunikaci s ústřednou!",
    msgPhoneNumberMissing: "Chybí telefonní číslo!",
    msgPhoneNumberMissingPlus: "Telefonní číslo musí začínat znakem +",
    msgPhoneNumberShort: "Telefonní číslo je příliš krátké, použijte mezinárodní formát a alespoň 8 číslic!",
    msgPhoneNumberNotNumbers: "Telefonní číslo může obsahovat po znaku + pouze čísla!",
    msgPhoneNumberSecondNotNumber: "Telefonní číslo je neplatné!",
    msgDisabledModuelDispatchingCreateOrder: "Modul vytváření objednávek je vypnutý!",
    msgMissingClientOrNumber: "Nebyl vyhledán klient nebo telefonní číslo není platné.",
    msgClientPhoneNotMatch: "Telefonní číslo klienta se neshoduje s vyhledaným číslem. Možná jste ho přepsali a nevyhledali jste klienta podle nového čísla.",
    msgMissingClient: "Nebyl vyhledán klient.",
    msgClientNoteNotChanged: "Poznámka klienta se nezměnila.",
    msgMissingBoardDestinAddress: "Chybí adresa vyzvednutí nebo adresa cíle.",
    msgGetPaymentListError: "Došlo k chybě při načítání možností platby. Zkontrolujte, zda jsou konfigurovány platební metody.",
    msgFilterPaymentTerminalYes: "Přidán požadavek na platební terminál ve vozidle",
    msgFilterPaymentTerminalAny: "Zrušen požadavek na platební terminál ve vozidle",
    msgClientPendingOnAccount: "Firma byla přidána, ale zákazník ještě nebyl v dané firmě schválen.",
    msgDefaultAccountIsNotValid: "Přednastavená firma není platná. Vyberte prosím firmu z nabídky a informujte manažera taxislužby, aby zkontroloval firemního zákazníka.",
    msgAccountNotSelected: "Zvolili jste platbu na firmu, ale nevybrali jste žádnou firmu.",
    msgShortDistanceAddresses: "Vzdálenost mezi body je příliš malá! Vzdálenost musí být alespoň 10 m.",
    msgTimeInPastError: "Čas vyzvednutí nemůže být v minulosti!",
    msgTimeTooSoonError: "Čas vyzvednutí je příliš brzy! Zadejte pozdější čas (alespoň o 5 minut)!",
    msgTimeTooLateError: "Zadaný čas je více než 2 týdny v budoucnosti!",
    msgTimeTooLateMultiError: "Zadaný čas je více než 1 týden v budoucnosti!",
    msgCreateOrderError: "Došlo k chybě při vytváření objednávky!",
    msgDuplicateOrder: "V systému již existuje objednávka pro daného zákazníka!",
    msgAddReverseGeoLimitBan: "Často vyhledáváte adresy přímo z mapy. Překročili jste maximální počet takových adres. Funkce je dočasně zablokována.",
    msgClientBlocked: "Klient je blokován!",
    msgClientDebtor: "Klient je dlužník!",
    msgClientVip: "Klient je VIP!",
    msgUpdateClientSuccess: "Úspěšně aktualizováno!",
    msgUpdateClientError: "Došlo k chybě při aktualizaci!",
    msgPresendTimeNotSufficient: "Doporučený čas zasílání předem je delší než výchozí čas. Zvažte jeho úpravu!",
    msgBoardAddressFarFromStand: "Adresa vyzvednutí je dál, než je obvyklé!",
    msgRequestedTimeNotValid: "Zadaný čas není platný!",
    requstedDateMulti: "Objednávka č. ",
    btnCheckAll: "Označit vše",
    btnCheckNone: "Odznačit vše",
    btnCheckReverse: "Obrátit",
    btnCheckWeekdays: "Pracovní dny",
    btnCheckWeekend: "Víkend",
  },

  onlyManualSendingArray: [
    {
      "text": "Výchozí nastavení (default)",
      "value": null,
    }, {
      "text": "Pouze manuální zasílání",
      "value": true,
    }, {
      "text": "Zasílání automatem (pokud je zapnutý)",
      "value": false,
    },

  ],


  ConsoleOrderList: {
    panelOrderList: "Objednávky",
    panelOrderList_title: "Minimalizovať/maximalizovať Panel s objednávkami",
    searchField: "Hľadať",
    searchField_title: "Vyhľadávanie v zozname objednávok potvrďte stlačením klávesy Enter.",
    showOnlyActiveOrder_title: "Zobraziť iba Aktívne objednávky",
    showPlannedOrder_title: "Zobraziť všetky Plánované objednávky",
    hidePlannedOrder_title: "Skryť Plánované objednávky (zobrazené budú iba časovky prideľované do 30 minút)",
    onlyNotApprovedSwitch_title: "Zobraziť iba Adresy určené na schválenie",
  },

  ConsoleOrderListItem: {
    preferredDriver: "Preferovaný vodič",
    requestedTime: "Požadovaný čas vyzdvihnutia",
    onlyManualSending_true: "Zobraziť detail objednávky a manuálne prideliť vodičovi",
    onlyManualSending_false: "Zobraziť detail objednávky",
    changeStatusDialog_title: "Zmena stavu objednávky",
    changeStatusDialog_text: "Zmena stavu objednávky môžete urobiť iba pre Nové objednávky.",
    btnAccept: "Prijať objednávku",
    btnAbort: "Zrušiť objednávku",
  },

  ConsoleOrderWaypointEdit: {
    btnEditWaypoint: "Adresy",
    btnEditWaypoint_title: "Úprava adries a prejazdových bodov objednávky",
    dialogTitle: "Upraviť adresy",
    id_short: "ID",
    status: "Stav",
    createdAt: "Vytvorená",
    boardAddress: "Adresa vyzdvihnutia",
    transit1Address: "Adresa prejazdového bodu 1",
    transit2Address: "Adresa prejazdového bodu 2",
    transit3Address: "Adresa prejazdového bodu 3",
    transit4Address: "Adresa prejazdového bodu 4",
    destAddress: "Adresa cieľa cesty",
    msgPriceUpdated: "Cena bola úspešne prepočítaná.",
    msgPriceNotUpdated: "Cenu sa nepodarilo prepočítať.",
  },

  ConsoleOrderEdit: {
    btnEditOrder: "Upraviť",
    btnEditOrder_title: "Upraviť objednávku",
    dialogTitle: "Úprava objednávky",
    id_short: "ID",
    status: "Stav",
    createdAt: "Vytvorená",
    msgSuccess: "Objednávka bola úspešne aktualizovaná",
    msgError: "Objednávku sa nepodarilo aktualizovať!",
    msgUnavailable: "Pre tento typ alebo stav objednávky nie je možné upravovať objednávku!",
    msgInvalidDataInForm: "Údaje v formulári sú neplatné!",
    list: {
      subheaderTime: "Čas objednávky",
      updateToInstantDialog: "Zmeniť na okamžitú jazdu",
      updateRequestedTimeDialog: "Upraviť čas vyzdvihnutia",
      updateRequestedTimeDialog_toTime: "Zmeniť na časovku",
      updatePresendTimeDialog: "Upraviť čas zaslanie vopred",
      subheaderStatus: "Stav objednávky",
      abortOrderDialog: "Zrušiť jazdu",
      updateSendingActivateDialog: "Aktivovať na odosielanie",
      updateSendingDeactivateDialog: "Deaktivovať odosielanie",
      clearDriverDialog: "Odobrať jazdu",
      subheaderSettings: "Nastavenia objednávky",
      updateNoteDialog: "Upraviť poznámku",
      updatePaymentDialog: "Upraviť spôsob platby alebo firemný účet",
      updateFilterDialog: "Upraviť filtre/požiadavky na jazdu",
      updateSettingsDialog: "Upraviť nastavenia zobrazenia a zasielania objednávky",
      updatePriceDialog: "Prepočítať cenu",
      confirmNewDialog: "Potvrdiť novú objednávku",
    },
    //zrusenie
    abortOrderDialog_title: "Prajete si Zrušiť objednávku?",
    abortOrderDialog_text: "Pri zrušením objednávky nebude objednávky doručovaná na vodičov. Ak o jazdu nepožiadal zákazník, oznámte mu zrušenie jeho objednávky.",
    abortOrderDialog_btnAbort: "Zrušiť objednávku",
    abortedReason: "Dôvod zrušenia",
    abortNote: "Poznámka",
    //aktivovanie
    updateSendingActivateDialog_title: "Prajete si Aktivovať objednávku na odosielanie?",
    updateSendingActivateDialog_text: "Pri aktivovaní objednávky bude objednávka doručovaná na vodičov. (automatom alebo ručne podľa nastavení)",
    updateSendingActivateDialog_btnActivate: "Aktivovať na odosielanie",
    //deaktivovanie
    updateSendingDeactivateDialog_title: "Prajete si Deaktivovať objednávku na odosielanie?",
    updateSendingDeactivateDialog_text: "Pri deaktivovaní objednávky nebude objednávka doručovaná na vodičov. Zmení sa jej stav na neskoršie odosielanie. Podľa nastavenia automatu sa môže stať, že ju opäť aktivuje na odosielanie.",
    updateSendingDeactivateDialog_btnDeactivate: "Deaktivovať odosielanie",
    //na okamzitu
    updateToInstantDialog_title: "Prajete si zmeniť objednávku na okamžitú jazdu?",
    updateToInstantDialog_text: "Pri zmene objednávky na okamžitú jazdu bude objednávka doručovaná na vodičov IHNEĎ.",
    updateToInstantDialog_btnToInstant: "Zmeniť na okamžitú jazdu",
    //na casovku
    updateRequestedTimeDialog_title: " Prajete si zmeniť objednávku na časovku alebo zmeniť čas vyzdvihnutia?",
    updateRequestedTimeDialog_text: "Pri zmene objednávky na časovku bude objednávka doručovaná na vodičov v čase, ktorý ste zadali. Pre odosielanie vopred nastavte aj čas zasielania vopred v minutách.",
    updateRequestedTimeDialog_btnToTime: "Zmeniť",
    requestedTime: "Požadovaný čas vyzdvihnutia",
    msgRequestedTimePastError: "Čas vyzdvihnutia musí byť v budúcnosti!",
    msgRequestedTimeFarFutureError: "Čas vyzdvihnutia musí byť do 14 dní!",
    //cas zaslanie vopred
    updatePresendTimeDialog_title: "Prajete si zmeniť čas, kedy sa má objednávka začat vopred odosielať?",
    updatePresendTimeDialog_text: "Objednávka bude doručovaná zvolený počet minút vopred.",
    updatePresendTimeDialog_btnUpdate: "Zmeniť",
    presendTime: "Čas zaslanie vopred",
    rulesPresendTime1: "Čas musí byť viac ako 1 minúta", //Time should be above 1 minute
    rulesPresendTime2: "Čas musí byť menej ako 240 minút (4 hodiny)", //Max should not be above 240 minutes (4 hours)
    msgPresendTimeNullError: "Čas zaslanie vopred musí byť vyplnený!", //Presend time must be filled
    //Odobratie objednavky od vodica
    clearDriverDialog_title: "Prajete si odobrať objednávku od vodiča?",
    clearDriverDialog_text: "Pri odobratí objednávky od vodiča sa objednávka vráti do stavu odosielanie. ",
    clearDriverDialog_btnClear: "Odobrať objednávku",
    //poznamka  
    updateNoteDialog_title: "Praje si upraviť poznámku?",
    updateNoteDialog_text: "Pri zmene poznámky sa zmení poznámka v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updateNoteDialog_btnUpdate: "Upraviť poznámku",
    note: "Poznámka",
    rulesNote: "Poznámka môže mať maximálne 80 znakov", //Max 80 characters
    //platba
    updatePaymentDialog_title: "Prajete si upraviť platobné informácie?",
    updatePaymentDialog_text: "Pri zmene platobných informácií sa zmení platobná metóda a firemný účet v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updatePaymentDialog_btnUpdate: "Upraviť platbu",
    paymentType: "Spôsob platby",
    accountId: "Firemný účet",
    //poziadavky na jazdu
    updateFilterDialog_title: "Prajete si upraviť Požiadavky na jazdu?",
    updateFilterDialog_text: "Pri zmene požiadaviek na jazdu sa zmení požiadavky na jazdu v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updateFilterDialog_btnUpdate: "Upraviť požiadavky",
    animal: "Zviera",
    airconditioning: "Klimatizácia",
    luggage: "Batožina",
    autonomous: "Autonomná jazda",
    paymentTerminal: "Platobný terminál",
    childrenChair: "Preprava dieťaťa",
    driverRating: "Hodnotenie vodiča",
    economy: "Eko jazda",
    maxPerson: "Počet cestujúcich",
    specialTransport: "Špeciálna preprava",
    //nastavenia
    updateSettingsDialog_title: "Prajete si upraviť nastavenia na zasielanie objednávky a zobrazenie informácií v aplikácii vodiča?",
    updateSettingsDialog_text: "Pri zmene nastavení sa zmení nastavenie v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updateSettingsDialog_btnUpdate: "Upraviť nastavenia",
    showPhone: "Vodičovi zobraziť telefónne číslo zákazníka",
    showDriverPhoneButton: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses: "Vodičovi zobraziť všetky adresy",
    onlyManualSending: "Iba manuálne zaslanie objednávky",
    forcedPickupRadius: "Vynútenie vyzdvihnutia v okruhu (0 = vypnuté)",
    showPhone_title: "Na zobrazenie telefónneho čísla je potrebné, aby si vodič reštartoval aplikáciu alebo zmenil stav objednávky.",
    showDriverPhoneButton_title: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses_title: "Na zobrazenie telefónneho čísla je potrebné, aby si vodič reštartoval aplikáciu alebo zmeni stav objednávky",
    onlyManualSending_title: "Používa sa pre automat, aby neposielal objednávku automaticky",
    forcedPickupRadius_title: "Ak je 0, vodič môže kdekoľvek zmeniť stav objednávky, že je na mieste vyzdvihnutia. Ak je napr. 200, vodič pri príchode musí byť maximálne 200 metrov od polohy adresy vyzdvihnutia.",
    rulesForcedPickupRadius1: "Musí byť 0 alebo viac", //Should be 0 or more
    rulesForcedPickupRadius2: "Maximálne 1000m", //Max 1000m
    //cena
    updatePriceDialog_title: "Prajete si prepočítať cenu objednávky?",
    updatePriceDialog_text: "Pri prepočítaní ceny môžu vzniknúť negatívne dopady na zákazníka. Preto dobre zvážte, či chcete prepočítať cenu objednávky. Po zmene ceny je potrebné novú očakávanú cenu oznámiť primo zákazníkovi, či s tým súhlasí.",
    updatePriceDialog_btnUpdate: "Prepočítať cenu",
    msgPriceUpdated: "Cena bola úspešne prepočítaná.",
    msgPriceNotUpdated: "Cenu sa nepodarilo prepočítať.",
    //potvrdenie Novej objednavky
    confirmNewDialog_title: "Prajete si potvrdiť novú objednávku?",
    confirmNewDialog_text: "Pri vytvorení objednávky spavidla z aplikácie Zákazníka, ak nie je nastavené automatické schválenie, tak je potrebné objednávku Schváliť. Ak objednávku neschválite alebo odmietnete, nebude posielaná na vodičov.",
    confirmNewDialog_btnConfirmNew: "Schváliť",
    confirmNewDialog_btnAbortNew: "Odmietnuť a zrušiť",
  },

  ConsoleOrderDetail: {
    id: "Č. objednávky",
    id_short: "ID",
    status: "Stav",
    presendTime: "Zaslanie vopred:",
    requestedTime: "",
    clientNone: " - žiadny zákazník - ",
    clientId: "Č. zákazníka:",
    nicknameClient: "",
    phoneClient: "",
    mark: "",
    ratingClient: "",
    noteClient: "",
    driverNone: " - žiadny vodič - ",
    driverId: "Č. vodiča:",
    firstName: "",
    lastName: "",
    nicknameDriver: "",
    phoneDriver: "",
    ratingDriver: "",
    driveCounter: "",
    vehicleId: "Č. vozidla:",
    brand: "",
    model: "",
    lpn: "",
    color: "",
    vehicleType: "",
    internalName: "",
    preferredDriverFirstName: "Pref: ",
    preferredDriverLastName: "",
    paymentType: "Platba:",
    account: "Firemný zákazník:",
    note: "Poznámka:",
    orderFilter: "Požiadavky na jazdu",
    displayAndSend: "Zobrazenie a zaslanie objednávky",
    timeChanged: "Čas zmeny stavu objednávky",
    createdAt: "Vytvorená",
    plannedAt: "Naplánovaná",
    pendingAt: "Čaká na priradenie",
    acceptedAt: "Akceptovaná",
    waitingAt: "Čaká na zákazníka",
    progressAt: "Začatá preprava",
    finishedAt: "Ukončená preprava",
    chargedAt: "Pripravený účet",
    settledAt: "Zaplatená objednávka",
    interruptedAt: "Zrušená",
    archivedAt: "Archivovaná",
    showPrice: "Zobrazenie ceny vodičovi a zákazníkovi",
    showPhone: "Vodičovi zobraziť telefónne číslo zákazníka",
    showDriverPhoneButton: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses: "Vodičovi zobraziť všetky adresy",
    onlyManualSending: "Iba manuálne zaslanie objednávky",
    forcedPickupRadius: "Vynútenie vyzdvihnutia v okruhu (0 = vypnuté)",
    btnSendOrderToDriver: "Poslať",
    btnUpdatePreferreDriver: "Preferovať",
    btnFindDriverOnMap: "Nájsť",
    btnShowEditDriverWorkShiftDialog: "Práca",
    btnShowMessage: "Chat",
    btnDetailRefresh: "Aktual.",
    btnAutocompleteDriverRemote: "Vodič",
    btnAutocompleteDriverRemote_placeholder: "Začnite písať...",
    //btnEditOrder: "Upraviť",
    //btnEditOrderWaypoint: "Adresy",
    price: "Cena",
    priceName: "Názov",
    priceEstimated: "Predpoklad",
    priceFinal: "Final",
    priceInfo: "Info",
    distance: "Vzdialenosť",
    time: "Čas",
    priceStart: "Za vyzdvihnutie",
    priceWaiting: "Za čakanie pred jazdou",
    priceDriveDistance: "Za vzdialenosť prepravy",
    priceDriveTime: "Za čas prepravy",
    priceDrive: "Za prepravu",
    priceJourney: "Za jazdu",
    priceExtra: "Príplatky",
    priceDiscount: "Zľava",
    priceTotal: "Celková cena",
    sentOrderLogList: "Zasielanie objednávok na vodičov",
    tbl_driver: "Vodič",
    tbl_sent: "Odoslané",
    tbl_sent_short: "Odosl.",
    tbl_distance: "Vzdialenosť",
    tbl_distance_short: "Vzd.",
    tbl_time: "Čas",
    waitingList: "Čakanie na zákazníka",
    tbl_startedAt: "Začiatok",
    tbl_finishedAt: "Koniec",
    tbl_timeMin: "Trvanie (v min.)",
    tbl_timeSeconds: "Trvanie (v sek.)",
    tbl_distanceMeters: "Vzdialenosť (v m)",
    editDriverWorkShiftDialog_title: "Upraviť stavy pracovnej zmeny",
    workBreak: "Prestávka v práci",
    workBreakApprove: "Schváliť prestávku",
    workBreakReject: "Zamietnuť prestávku",
    workEndShift: "Ukončenie pracovnej zmeny",
    workEndShiftApprove: "Schváliť ukončenie pracovnej zmeny",
    workEndShiftReject: "Zamietnuť ukončenie pracovnej zmeny",
    workShiftRequestListDialog_title: "Zoznam otvorených požiadaviek na úpravu pracovnej zmeny",
    msgUpdateWorkShiftMissingData: "Chýbajú údaje pre úpravu pracovnej zmeny", //Missing data for changing workshift.
    msgUpdateWorkShiftMissingRequest: "Nebola nájdená požiadavka vodiča na prestávku alebo ukončenie pracovnej zmeny.",
    msgUpdateStatusError: "Chyba pri aktualizácii stavu objednávky",
    msgUpdateStatusSuccess: "Stav objednávky bol úspešne aktualizovaný",
    msgDriverNotSelected: "Vodič nie je vybraný",
    msgWorkShiftDriverNoRequest: "Vodič nemá žiadnu požiadavku k pracovovnej zmene!",
    msgGetOrderDetailError: "Chyba pri získavaní detailu objednávky",
    msgCopyPhoneNumberMissing: "Telefónne číslo nie je k dispozícii",
    msgCopyText: "Skopírované do schránky",
    msgPbxCallNumberMissing: "Telefónne číslo nie je k dispozícii. Skontrolujte či je načítaný detail objednávky alebo či je v objednávke zadané číslo zákazníka",
    msgPbxCallLineMissing: "Nebola vybraná žiadna klapka na záložke s novou objednávkou!",
    msgPbxCallSuccess: "Volanie bolo úspešne zahájené",
    msgPbxCallError: "Volanie nebolo zahájené!",
    msgSendOrderDriverNotSelected: "Vodič nie je vybraný",
    msgSendOrderOrderNotSelected: "Objednávka nie je vybraná",
    msgSendOrderSuccess: "Objednávka bola úspešne zaslaná vodičovi.",
    msgSendOrderError: "Objednávka nebola zaslaná vodičovi!",
    msgSendOrderDriverNotPreferred: "Chystáte sa poslať objednávku nepreferovanému vodičovi. Stlate OK, aby ste mu napriek tomu poslali Objednávku.",
    msgPreferredDriverSetSuccess: "Preferovaný vodič je nastavený",
    msgPreferredDriverSetError: "Preferovaný vodič nie je nastavený",
    msgFindDriverNotSet: "Pre zobrazenie konkrétneho vodiča na mape, najprv vyberte vodiča zo zoznamu...",
    msgFindPositionError: "Lokalita je bez koordinátov...",
    msgMessageStatusCannotSend: "Správu nie je možné odoslať, lebo jej stav je uzavretý",
    confirmPbxCallDialog_title: "Volania zákazníkovi a vodičovi k objednávke",
    confirmPbxCallDialog_text: "Niektoré funkcie závisia od vašej ústredne alebo používaného zariadenia, nemusia byť dostupné.",
    subheaderPbxCall: "Volania cez ústredňu",
    btnPbxCallClient: "Vytvoriť hovor zákazníkovi z klapky",
    btnPbxCallDriver: "Vytvoriť hovor vodičovi z klapky",
    btnPbxCallBetweenDriverClient: "Vytvoriť hovor od Vodiča Zákazníkovi",
    subheaderPhoneCopy: "Skopírovať telefónne číslo",
    btnPhoneClientCopy: "Skopírovať telefónne číslo 'Zákazníka'",
    btnPhoneDriverCopy: "Skopírovať telefónne číslo 'Vodiča'",
    subheaderPhoneHref: "Otvoriť odkaz na telefón v telefónnej aplikácii",
    btnPhoneClientHref: "Otvoriť odkaz na telefón Zákazníka",
    btnPhoneDriverHref: "Otvoriť odkaz na telefón Vodiča",
    msgPbxCallOrderMissing: "Nie je zvolená žiadna objednávka!",
    chatMsgSuccessPbxDriverClientCall: "Dispečer vytvoril hovor medzi vodičom a zákazníkom",
    editDriverSilenceDialog_title: "Upraviť tichý režim vodiča",
    editDriverSilenceDialog_text: "Prajete si zapnúť alebo vypnúť Tichý režim vodiča?",
    btnSilenceOn: "Zapnúť Tichý režim",
    btnSilenceOff: "Vypnúť Tichý režim",
    msgSilenceOnSuccess: "Tichý režim vodiča je zapnutý",
    msgSilenceOffSuccess: "Tichý režim vodiča je vypnutý",
    msgSilenceError: "Tichý režim vodiča sa nepodarilo nastaviť",
    historyClientDialog_title: "História objednávok zákazníka",
    btnOpenHistoryClientDialog: "História objednávok",
    btnOpenHistoryClientDialog_title: "Zobraziť zoznam s históriou objednávok zákazníka",
    msgMissingClient: "Nie je dostupný klient.",
    msgClientHistoryEmpty: "História objednávok tohto klienta je prázdna."
  },

  ConsoleOrderDetail_tooltip: {
    copyToClipboard: "Skopírovať do schránky",
    createdAtChip: "Dátum a čas vytvorenia objednávky (UTC): ",
    changeStatusAtChip: "Kliknite pre zmenu stavu objednávky",
    presendTime: "Pri zasielaní automatom zaslať objednávku v nastavený čas vopred",
    requestedTime: "Pri časovej objednávke požadovaný čas vyzdvihnutia",
    paymentType: "Spôsob platby | Kliknite pre zmenu spôsobu platby",
    account: "Firemný zákazník",
    note: "Poznámka k objednávke | Kliknite pre zmenu poznámky",
    client: "Zákazník",
    clientId: "Identifikačné číslo zákazníka",
    nicknameClient: "Meno zákazníka",
    phoneClient: "Telefónne číslo",
    phoneClientHref: "Otvoriť odkaz na telefón v aplikácii",
    phoneClientCopy: "Skopírovať telefónne číslo zákazníka",
    phonePbxCall: "Vytvoriť hovor",
    mark: "Označenie alebo typ zákazníka",
    ratingClient: "Hodnotenie zákazníka",
    noteClient: "Poznámka k zákazníkovi",
    driver: "Vodič",
    driverId: "Identifikačné číslo vodiča:",
    firstName: "Meno",
    lastName: "Priezvisko",
    nicknameDriver: "Označenie/prezývka",
    phoneDriver: "Telefón na vodiča",
    phoneDriverHref: "Otvoriť odkaz na telefón v aplikácii",
    phoneDriverCopy: "Skopírovať telefónne číslo vodiča",
    ratingDriver: "Hodnotenie vodiča",
    driveCounter: "Celkový počet jázd vodiča",
    vehicleId: "Identifikačné číslo vozidla:",
    brand: "Značka vozidla",
    model: "Model vozidla",
    lpn: "ŠPZ",
    color: "Farba",
    vehicleType: "Typ vozidla",
    internalName: "Interné označenie",
    preferredDriverFirstName: "Meno preferovaného vodiča",
    preferredDriverLastName: "Priezvisko preferovaného vodiča",
    orderFilter: "Požiadavky na jazdu",
    displayAndSend: "Zobrazenie a zaslanie objednávky",
    btnSendOrderToDriver: "Zaslanie objednavky vodičovi",
    btnUpdatePreferreDriver: "Nastavenie preferovaného vodiča",
    btnFindDriverOnMap: "Vyhľadanie vodiča na mape",
    btnChangeDriverSilence: "Zmena tichého režimu vodiča",
    btnShowEditDriverWorkShiftDialog: "Schvaľovanie stavov pracovnej zmeny",
    btnShowMessage: "Zobrazenie chat správ k objednávke",
    btnDetailRefresh: "Znovunačítať detailné informácie o objednávke",
    btnAutocompleteDriverRemote: "Vyberte vodiča. Kliknutím na ľavú ikonu aktualizujete zoznam vodičov.",
    price: "Cena objednávky",
    //btnEditOrder: "Úprava objednávky",
    //btnEditOrderWaypoint: "Úprava adries a prejazdových bodov objednávky",
    timeChanged: "Čas zmeny stavu objednávky",
    createdAt: "Objednávka bola vytvorená",
    plannedAt: "Bola naplánovaná jazda, pri časovke v požadovanom čase",
    pendingAt: "Objednávka čaká na priradenie vodičovi",
    acceptedAt: "Vodič prijal objednávku a presúva sa na miesto vyzdvihnutia zákazníka",
    waitingAt: "Vodič prišiel na miesto vyzdvihnutia zákazníka a čaká na zákazníka",
    progressAt: "Vodič začal prepravovať zákazníka",
    finishedAt: "Vodič doviezol zákazníka do cieľa a pripravuje účet",
    settledAt: "Objednávka bola zaplatená a uzavretá",
    chargedAt: "Objednávka bola vyúčtovaná, ak bola zvolená platoba kartou, platbu vykoná platobná brána",
    interruptedAt: "Objednávka bola zrušená alebo jej zasielanie vodičovi bolo pozastavené",
    archivedAt: "Objednávka je archivovaná",
    sentOrderLogList: "Zasielanie objednávok na vodičov",
    waitingList: "Čakanie na zákazníka",
  },

  ConsoleOrderMessage: {
    tabOrder: "Objednávka",
    tabDriver: "Vodič",
    tabDispatch: "Dispečing",
    tabPublic_title: "Správy na všetkých vodičov",
    orderMessageField: "Správa vodičovi aj zákazníkovi",
    dispDriverMessageField: "Správa vodičovi",
    btnWorkShift: "Schvaľovanie stavov pracovnej zmeny (prestávka, ukončenie zmeny)",
    dispatchingMessageField: "Správa všetkým dispečerom",
    publicMessageField: "Správa na všetkých vodičov",
    btnAutocompleteDriverRemote: "Vodič",
    btnAutocompleteDriverRemote_placeholder: "Začnite písať...",
    btnAutocompleteDriverRemote_title: "Vyberte vodiča. Prípadne kliknutím na ľavú ikonu aktualizujete zoznam vodičov",
    copyToTextField: "Klikni na nakopírovanie textu do poľa so správou",
    editDriverWorkShiftDialog_title: "Upraviť stavy pracovnej zmeny",
    workBreak: "Prestávka v práci",
    workBreakApprove: "Schváliť prestávku",
    workBreakReject: "Zamietnuť prestávku",
    workEndShift: "Ukončenie pracovnej zmeny",
    workEndShiftApprove: "Schváliť ukončenie pracovnej zmeny",
    workEndShiftReject: "Zamietnuť ukončenie pracovnej zmeny",
    msgWorkShiftDriverNoRequest: "Vodič nemá žiadnu požiadavku k pracovnej zmene!",
    msgWorkShiftDriverNotSelected: "Nie je vybratý vodič!",
    msgWorkShiftMissingData: "Chýbajú údaje pre úpravu pracovnej zmeny", //Missing data for changing workshift.
    msgWorkShiftMissingRequest: "Nebola nájdená požiadavka vodiča na prestávku alebo ukončenie pracovnej zmeny.",
    msgWorkShiftUpdateError: "Chyba pri aktualizácii stavu",
    msgWorkShiftUpdateSuccess: "Stav bol úspešne aktualizovaný",
    msgGetOrderDetailError: "Chyba pri získavaní detailu objednávky",
    msgOrderMessageCountSystem: "V objednávke sa vyskytlo viacero správ s upozorneniami. Je možné, že priveľa vodičov ignorovalo túto objednávku. Prideľte ju ručne alebo prijmite primerané opatrenia.",
    sendMessageSuccess: "Správa bola úspešne odoslaná",
    getPublicMessagesError: "Chyba pri načítaní verených správ na všetkých vodičov",
    sendOrderMessageError: "Nie je zvolená objednávka alebo chýba text správy!",
    sendDispDriverMessageError: "Nie je vybratý vodič alebo nie je zadaný text správy!",
    sendDispatchingMessageError: "Nie je zadaný text správy!",
    sendPublicMessageError: "Nie je zadaný text správy!", //Text is missing!
    dispDriverMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Dobre",
      good_text: "Dobre",
      thankyou: "Ďakujem",
      thankyou_text: "Ďakujem",
      calling: "Volám",
      calling_text: "Volám",
      going: "Ide",
      going_text: "Zákazník už ide",
      whereareyou: "Kde si?",
      whereareyou_text: "Kde sa prosím nachádzaš?",
      cancelled: "Zrušené",
      cancelled_text: "Objednávka je zrušená",
      "1min": "1",
      "1min_text": "Zákazník príde o 1 minútu",
      "2min": "2",
      "2min_text": "Zákazník príde o 2 minúty",
      "5min": "5",
      "5min_text": "Zákazník príde o 5 minút",
    },
    orderMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Dobre",
      good_text: "Dobre",
      thankyou: "Ďakujem",
      thankyou_text: "Ďakujem",
      calling: "Volám",
      calling_text: "Volám",
      going: "Ide",
      going_text: "Zákazník už ide",
      cancelled: "Zrušené",
      cancelled_text: "Objednávka je zrušená",
      "1min": "1",
      "1min_text": "Zákazník príde o 1 minútu",
      "2min": "2",
      "2min_text": "Zákazník príde o 2 minúty",
      "5min": "5",
      "5min_text": "Zákazník príde o 5 minút",
      clientPhone: "Telefón",
      clientPhone_text: "Telefón na zákazníka: ",
    },
    dispatchingMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Dobre",
      good_text: "Dobre",
      thankyou: "Ďakujem",
      thankyou_text: "Ďakujem",
    },
  },

  showDriverPhoneButton: {
    NONE: "Žiadne",
    CUSTOMER: "Telefón na zákazníka",
    DISPATCH: "Telefón na dispečing",
    CUSTOMER_DISPATCH: "Telefón na zákazníka a dispečing",
    PBX: "Telefón na zákazníka cez ústredňu",
    PBX_DISPATCH: "Telefón na zákazníka cez ústredňu a dispečing",
  },
  showDriverPhoneButtonShort: {
    NONE: "-",
    CUSTOMER: "Z",
    DISPATCH: "D",
    CUSTOMER_DISPATCH: "Z&D",
    PBX: "U",
    PBX_DISPATCH: "U&D",
  },
  showDriverPhoneButtonArray: [
    {
      "text": "Žiadne",
      "value": "NONE",
    }, {
      "text": "Telefón na zákazníka",
      "value": "CUSTOMER",
    }, {
      "text": "Telefón na dispečing",
      "value": "DISPATCH",
    }, {
      "text": "Telefón na zákazníka a dispečing",
      "value": "CUSTOMER_DISPATCH",
    }, {
      "text": "Telefón na zákazníka cez ústredňu",
      "value": "PBX",
    }, {
      "text": "Telefón na zákazníka cez ústredňu a dispečing",
      "value": "PBX_DISPATCH",
    },
  ],
  showPrice: {
    ALL: "Zobraziť všetkým",
    DRIVER_BRAND: "Vodič a Brand Appka",
    ONLY_DRIVER: "Iba vodič",
    HIDDEN: "Nezobraziť nikomu",
  },
  showPriceShort: {
    ALL: "Všetci",
    DRIVER_BRAND: "Vodič a Brand",
    ONLY_DRIVER: "Iba vodič",
    HIDDEN: "Nezobraziť",
  },
  showPriceArray: [
    {
      "text": "Zobraziť všetkým",
      "value": "ALL",
    }, {
      "text": "Vodič a Brand Appka",
      "value": "DRIVER_BRAND",
    }, {
      "text": "Zobraziť iba vodičovi",
      "value": "ONLY_DRIVER",
    }, {
      "text": "Nezobraziť nikomu",
      "value": "HIDDEN",
    },
  ],

  orderFilter: {
    airconditioning: "Klimatizácia",
    animal: "Zviera",
    autonomous: "Autonómne vozidlo",
    childrenChair: "Preprava dieťaťa",
    driverRating: "Hodnotenie vodiča",
    eco: "Ekologický",
    luggage: "Batožina",
    maxPerson: "Počet cestujúcich",
    paymentTerminal: "Platobný terminál",
    smoking: "Fajčenie vo vozidle",
    specialTransport: "Špeciálna preprava",
  },

  orderFilterSelect: {
    airconditioning: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    animal: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Malé do 10kg",
        "value": "SMALL",
      }, {
        "text": "Stredné do 20kg",
        "value": "MEDIUM",
      }, {
        "text": "Veľké do 50kg",
        "value": "BIG",
      }, {
        "text": "Iba bez zvierat",
        "value": "NONE",
      },
    ],
    autonomous: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Autonomné vozidlo",
        "value": "AUTONOMOUS",
      }, {
        "text": "Neautonomné vozidlo",
        "value": "NONAUTONOMOUS",
      },
    ],
    paymentTerminal: [
      {
        "text": "Nepožadovaný",
        "value": "ANY",
      }, {
        "text": "Požadovaný",
        "value": "YES",
      },
    ],
    childrenChair: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Podsedák",
        "value": "BOOSTER",
      }, {
        "text": "Detská sedačka",
        "value": "SEAT",
      },
    ],
    driverRating: [
      {
        "text": "Nepožadované",
        "value": 0,
      }, {
        "text": "1",
        "value": 1,
      }, {
        "text": "2",
        "value": 2,
      }, {
        "text": "3",
        "value": 3,
      }, {
        "text": "4",
        "value": 4,
      }, {
        "text": "5",
        "value": 5,
      },
    ],
    eco: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Ekologické vozidlo",
        "value": "ECO",
      }, {
        "text": "Elektrické vozidlo",
        "value": "ELECTRIC",
      },
    ],
    luggage: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Malá batožina",
        "value": "SMALL",
      }, {
        "text": "Stredná batožina",
        "value": "MEDIUM",
      }, {
        "text": "Veľká batožina",
        "value": "BIG",
      },
    ],
    maxPerson: [
      {
        "text": "Nepožadované",
        "value": 0,
      }, {
        "text": "1",
        "value": 1,
      }, {
        "text": "2",
        "value": 2,
      }, {
        "text": "3",
        "value": 3,
      }, {
        "text": "4",
        "value": 4,
      }, {
        "text": "5",
        "value": 5,
      }, {
        "text": "6",
        "value": 6,
      }, {
        "text": "7",
        "value": 7,
      }, {
        "text": "8",
        "value": 8,
      }, {
        "text": "9",
        "value": 9,
      },
    ],
    smoking: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Fajčiarské vozidlo",
        "value": "SMOKING",
      },
    ],
    specialTransport: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Preprava ľudí na vozíku",
        "value": "CHAIR",
      }, {
        "text": "Starší a vyžadujúci asistenciu",
        "value": "OLD",
      },
    ],
  },

  vehicleSelect: {
    airconditioning: [
      {
        "text": "Nie",
        "value": "NO",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    animal: [
      {
        "text": "Malé",
        "value": "SMALL",
      }, {
        "text": "Stredné",
        "value": "MEDIUM",
      }, {
        "text": "Veľké",
        "value": "BIG",
      }, {
        "text": "Žiadne",
        "value": "NONE",
      },
    ],
    autonomous: [
      {
        "text": "Bez autonómnych funkcií",
        "value": "L0",
      }, {
        "text": "Úroveň 1 - Adaptívny tempomat",
        "value": "L1",
      }, {
        "text": "Úroveň 2 - Udržiavanie jazdného pruhu",
        "value": "L2",
      }, {
        "text": "Úroveň 3 - Čiastočné autonómne riadenie",
        "value": "L3",
      }, {
        "text": "Úroveň 4 - Takmer autonómne riadenie",
        "value": "L4",
      }, {
        "text": "Úroveň 5 - Riadenie plne bez zásahu vodiča",
        "value": "L5",
      },
    ],
    paymentTerminal: [
      {
        "text": "Nie",
        "value": "NO",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    childrenChair: [
      {
        "text": "Bez detskej sedačky",
        "value": "NONE",
      }, {
        "text": "Podsedák",
        "value": "BOOSTER",
      }, {
        "text": "Sedačka",
        "value": "SEAT",
      }, {
        "text": "Sedačka aj podsedák",
        "value": "ALL",
      },
    ],
    eco: [
      {
        "text": "Nafta",
        "value": "DIESEL",
      }, {
        "text": "Benzín",
        "value": "GASOLINE",
      }, {
        "text": "Hybrid Electric",
        "value": "HYBRID",
      }, {
        "text": "Plug-in Hybrid Electric",
        "value": "PLUGIN",
      }, {
        "text": "CNG",
        "value": "CNG",
      }, {
        "text": "LPG",
        "value": "LPG",
      }, {
        "text": "Elektrické",
        "value": "ELECTRIC",
      }, {
        "text": "Vodík",
        "value": "HYDROGEN",
      },
    ],
    luggage: [
      {
        "text": "Malá",
        "value": "SMALL",
      }, {
        "text": "Stredná",
        "value": "MEDIUM",
      }, {
        "text": "Veľká",
        "value": "BIG",
      },
    ],
    smoking: [
      {
        "text": "Nie",
        "value": "NO",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    specialTransport: [
      {
        "text": "Nie",
        "value": "NONE",
      }, {
        "text": "Preprava ľudí na vozíku",
        "value": "CHAIR",
      }, {
        "text": "Preprava starších ľudí a vyžadujúcich pomoc",
        "value": "OLD",
      }, {
        "text": "Starší aj imobilní ľudia na vozíku",
        "value": "ALL",
      },
    ],
  },

  DateRangeSelect:
    [
      {
        "text": "Dnes",
        "value": "today",
      }, {
        "text": "Včera",
        "value": "yesterday",
      }, {
        "text": "Posledné 2 dni",
        "value": "last_2_days",
      }, {
        "text": "Tento týždeň",
        "value": "this_week",
      }, {
        "text": "Tento mesiac",
        "value": "this_month",
      }, {
        "text": "Minulý mesiac",
        "value": "last_month",
      },
      /*{
        "text": "Posledné 3 mesiace",
        "value": "last_3_months",
      }, {
        "text": "Posledných 6 mesiacov",
        "value": "last_6_months",
      },*/
      {
        "text": "Vlastný rozsah",
        "value": "custom",
      },
    ],

  clientMark: {
    NONE: "Nový zákazník",
    NEW: "Nový zákazník",
    REGULAR: "Stály zákazník",
    PRIORITY: "Prioritný zákazník",
    FAMOUS: "Známa osobnosť",
    VIP: "VIP",
    COLLEAGUE: "Kolega z taxislužby",
    STUDENT: "Študent",
    CHILD: "Dieťa",
    JUNIOR: "Junior",
    SENIOR: "Senior",
    DEBTOR: "Dlžník",
    BLOCKED: "Blokovaný",
  },

  clientMarkSelect:
    [
      {
        "text": "Nový zákazník",
        "value": "NEW",
      }, {
        "text": "Stály zákazník",
        "value": "REGULAR",
      }, {
        "text": "Prioritný zákazník",
        "value": "PRIORITY",
      }, {
        "text": "Známa osobnosť",
        "value": "FAMOUS",
      }, {
        "text": "VIP",
        "value": "VIP",
      }, {
        "text": "Kolega z taxislužby",
        "value": "COLLEAGUE",
      }, {
        "text": "Študent",
        "value": "STUDENT",
      }, {
        "text": "Dieťa",
        "value": "CHILD",
      }, {
        "text": "Junior",
        "value": "JUNIOR",
      }, {
        "text": "Senior",
        "value": "SENIOR",
      }, {
        "text": "Dlžník",
        "value": "DEBTOR",
      }, {
        "text": "Blokovaný",
        "value": "BLOCKED",
      },
    ],

  clientNotificationSelect:
    [
      {
        "text": " - Žiadne - ",
        "value": "NONE",
      },
      /*{
        "text": "Push správy",
        "value": "PUSH",
      },*/
      {
        "text": "SMS",
        "value": "SMS",
      },
      /* {
        "text": "Email",
        "value": "EMAIL",
      }, {
        "text": "Telefonická komunikácia",
        "value": "CALL",
      }, {
        "text": "Chat",
        "value": "CHAT",
      }, {
        "text": "Viber",
        "value": "VIBER",
      }, {
        "text": "WhatsApp",
        "value": "WHATSAPP",
      },*/
    ],
  CountryCodeConsoleShortList:
    [
      { "text": "Všetky krajiny", "value": null, },
      { "text": "Slovensko", "value": "SK", },
      { "text": "Česko", "value": "CZ", },
      { "text": "Rakúsko", "value": "AT", },
      { "text": "Maďarsko", "value": "HU", },
      { "text": "Poľsko", "value": "PL", },
      { "text": "Ukrajina", "value": "UA" },
    ],
  CountryCodeShortList:
    [
      { "text": "Slovensko", "value": "SK", },
      { "text": "Česko", "value": "CZ", },
      { "text": "Rakúsko", "value": "AT", },
      { "text": "Maďarsko", "value": "HU", },
      { "text": "Poľsko", "value": "PL", },
      { "text": "Ukrajina", "value": "UA" },
    ],
  CountryCodeList:
    [
      { "text": "Slovensko", "value": "SK", },
      { "text": "Česko", "value": "CZ", },
      { "text": "Rakúsko", "value": "AT", },
      { "text": "Maďarsko", "value": "HU", },
      { "text": "Poľsko", "value": "PL", },
      { "text": "Ukrajina", "value": "UA" },
      { "value": "AD", "text": "Andorra" },
      { "value": "AE", "text": "Spojené arabské emiráty" },
      { "value": "AF", "text": "Afganistan" },
      { "value": "AG", "text": "Antigua a Barbuda" },
      { "value": "AI", "text": "Anguilla" },
      { "value": "AL", "text": "Albánsko" },
      { "value": "AM", "text": "Arménsko" },
      { "value": "AO", "text": "Angola" },
      { "value": "AQ", "text": "Antarktída" },
      { "value": "AR", "text": "Argentína" },
      { "value": "AS", "text": "Americká Samoa" },
      { "value": "AU", "text": "Austrália" },
      { "value": "AW", "text": "Aruba" },
      { "value": "AX", "text": "Alandské ostrovy" },
      { "value": "AZ", "text": "Azerbajdžan" },
      { "value": "BA", "text": "Bosna a Hercegovina" },
      { "value": "BB", "text": "Barbados" },
      { "value": "BD", "text": "Bangladéš" },
      { "value": "BE", "text": "Belgicko" },
      { "value": "BF", "text": "Burkina Faso" },
      { "value": "BG", "text": "Bulharsko" },
      { "value": "BH", "text": "Bahrajn" },
      { "value": "BI", "text": "Burundi" },
      { "value": "BJ", "text": "Benin" },
      { "value": "BL", "text": "Svätý Bartolomej" },
      { "value": "BM", "text": "Bermudy" },
      { "value": "BN", "text": "Brunej" },
      { "value": "BO", "text": "Bolívia, mnohonárodnostný štát" },
      { "value": "BQ", "text": "Bonaire, Sint Eustatius a Saba" },
      { "value": "BR", "text": "Brazília" },
      { "value": "BS", "text": "Bahamy" },
      { "value": "BT", "text": "Bhután" },
      { "value": "BV", "text": "Bouvetov ostrov" },
      { "value": "BW", "text": "Botswana" },
      { "value": "BY", "text": "Bielorusko" },
      { "value": "BZ", "text": "Belize" },
      { "value": "CA", "text": "Kanada" },
      { "value": "CC", "text": "Kokosové ostrovy" },
      { "value": "CD", "text": "Konžská demokratická republika" },
      { "value": "CF", "text": "Stredoafrická republika" },
      { "value": "CG", "text": "Kongo" },
      { "value": "CH", "text": "Švajčiarsko" },
      { "value": "CI", "text": "Pobrežie Slonoviny" },
      { "value": "CK", "text": "Cookove ostrovy" },
      { "value": "CL", "text": "Čile" },
      { "value": "CM", "text": "Kamerun" },
      { "value": "CN", "text": "Čína" },
      { "value": "CO", "text": "Kolumbia" },
      { "value": "CR", "text": "Kostarika" },
      { "value": "CU", "text": "Kuba" },
      { "value": "CV", "text": "Kapverdy" },
      { "value": "CW", "text": "Curaçao" },
      { "value": "CX", "text": "Vianočný ostrov" },
      { "value": "CY", "text": "Cyprus" },
      { "value": "DE", "text": "Nemecko" },
      { "value": "DJ", "text": "Džibutsko" },
      { "value": "DK", "text": "Dánsko" },
      { "value": "DM", "text": "Dominika" },
      { "value": "DO", "text": "Dominikánska republika" },
      { "value": "DZ", "text": "Alžírsko" },
      { "value": "EC", "text": "Ekvádor" },
      { "value": "EE", "text": "Estónsko" },
      { "value": "EG", "text": "Egypt" },
      { "value": "EH", "text": "Západná Sahara" },
      { "value": "ER", "text": "Eritrea" },
      { "value": "ES", "text": "Španielsko" },
      { "value": "ET", "text": "Etiópia" },
      { "value": "FI", "text": "Fínsko" },
      { "value": "FJ", "text": "Fidži" },
      { "value": "FK", "text": "Falklandy (Malvíny)" },
      { "value": "FM", "text": "Mikronézia, federatívne štáty" },
      { "value": "FO", "text": "Faerské ostrovy" },
      { "value": "FR", "text": "Francúzsko" },
      { "value": "GA", "text": "Gabon" },
      { "value": "GB", "text": "Spojené kráľovstvo Veľkej Británie a Severného Írska" },
      { "value": "GD", "text": "Grenada" },
      { "value": "GE", "text": "Gruzínsko" },
      { "value": "GF", "text": "Francúzska Guayana" },
      { "value": "GG", "text": "Guernsey" },
      { "value": "GH", "text": "Ghana" },
      { "value": "GI", "text": "Gibraltár" },
      { "value": "GL", "text": "Grónsko" },
      { "value": "GM", "text": "Gambia" },
      { "value": "GN", "text": "Guinea" },
      { "value": "GP", "text": "Guadeloupe" },
      { "value": "GQ", "text": "Rovníková Guinea" },
      { "value": "GR", "text": "Grécko" },
      { "value": "GS", "text": "Južná Georgia a Južné Sandwichove ostrovy" },
      { "value": "GT", "text": "Guatemala" },
      { "value": "GU", "text": "Guam" },
      { "value": "GW", "text": "Guinea-Bissau" },
      { "value": "GY", "text": "Guyana" },
      { "value": "HK", "text": "Hongkong" },
      { "value": "HM", "text": "Heardov ostrov a McDonaldove ostrovy" },
      { "value": "HN", "text": "Honduras" },
      { "value": "HR", "text": "Chorvátsko" },
      { "value": "HT", "text": "Haiti" },
      { "value": "ID", "text": "Indonézia" },
      { "value": "IE", "text": "Írsko" },
      { "value": "IL", "text": "Izrael" },
      { "value": "IM", "text": "Ostrov Man" },
      { "value": "IN", "text": "India" },
      { "value": "IO", "text": "Britské indickooceánske územie" },
      { "value": "IQ", "text": "Irak" },
      { "value": "IR", "text": "Irán, Islamská republika" },
      { "value": "IS", "text": "Island" },
      { "value": "IT", "text": "Taliansko" },
      { "value": "JE", "text": "Jersey" },
      { "value": "JM", "text": "Jamajka" },
      { "value": "JO", "text": "Jordánsko" },
      { "value": "JP", "text": "Japonsko" },
      { "value": "KE", "text": "Keňa" },
      { "value": "KG", "text": "Kirgizsko" },
      { "value": "KH", "text": "Kambodža" },
      { "value": "KI", "text": "Kiribati" },
      { "value": "KM", "text": "Komory" },
      { "value": "KN", "text": "Svätý Krištof a Nevis" },
      { "value": "KP", "text": "Kórejská ľudovodemokratická republika" },
      { "value": "KR", "text": "Kórejská republika" },
      { "value": "KW", "text": "Kuvajt" },
      { "value": "KY", "text": "Kajmanie ostrovy" },
      { "value": "KZ", "text": "Kazachstan" },
      { "value": "LA", "text": "Laoská ľudovodemokratická republika" },
      { "value": "LB", "text": "Libanon" },
      { "value": "LC", "text": "Svätá Lucia" },
      { "value": "LI", "text": "Lichtenštajnsko" },
      { "value": "LK", "text": "Srí Lanka" },
      { "value": "LR", "text": "Libéria" },
      { "value": "LS", "text": "Lesotho" },
      { "value": "LT", "text": "Litva" },
      { "value": "LU", "text": "Luxembursko" },
      { "value": "LV", "text": "Lotyšsko" },
      { "value": "LY", "text": "Líbya" },
      { "value": "MA", "text": "Maroko" },
      { "value": "MC", "text": "Monako" },
      { "value": "MD", "text": "Moldavská republika" },
      { "value": "ME", "text": "Čierna Hora" },
      { "value": "MF", "text": "Svätý Martin (francúzska časť)" },
      { "value": "MG", "text": "Madagaskar" },
      { "value": "MH", "text": "Marshallove ostrovy" },
      { "value": "MK", "text": "Severné Macedónsko" },
      { "value": "ML", "text": "Mali" },
      { "value": "MM", "text": "Mjanmarsko" },
      { "value": "MN", "text": "Mongolsko" },
      { "value": "MO", "text": "Macao" },
      { "value": "MP", "text": "Severné Mariány" },
      { "value": "MQ", "text": "Martinik" },
      { "value": "MR", "text": "Mauritánia" },
      { "value": "MS", "text": "Montserrat" },
      { "value": "MT", "text": "Malta" },
      { "value": "MU", "text": "Maurícius" },
      { "value": "MV", "text": "Maldivy" },
      { "value": "MW", "text": "Malawi" },
      { "value": "MX", "text": "Mexiko" },
      { "value": "MY", "text": "Malajzia" },
      { "value": "MZ", "text": "Mozambik" },
      { "value": "NA", "text": "Namíbia" },
      { "value": "NC", "text": "Nová Kaledónia" },
      { "value": "NE", "text": "Niger" },
      { "value": "NF", "text": "Norfolkov ostrov" },
      { "value": "NG", "text": "Nigéria" },
      { "value": "NI", "text": "Nikaragua" },
      { "value": "NL", "text": "Holandsko" },
      { "value": "NO", "text": "Nórsko" },
      { "value": "NP", "text": "Nepál" },
      { "value": "NR", "text": "Nauru" },
      { "value": "NU", "text": "Niue" },
      { "value": "NZ", "text": "Nový Zéland" },
      { "value": "OM", "text": "Omán" },
      { "value": "PA", "text": "Panama" },
      { "value": "PE", "text": "Peru" },
      { "value": "PF", "text": "Francúzska Polynézia" },
      { "value": "PG", "text": "Papua-Nová Guinea" },
      { "value": "PH", "text": "Filipíny" },
      { "value": "PK", "text": "Pakistan" },
      { "value": "PM", "text": "Saint Pierre a Miquelon" },
      { "value": "PN", "text": "Pitcairnove ostrovy" },
      { "value": "PR", "text": "Portoriko" },
      { "value": "PS", "text": "Palestína, štát" },
      { "value": "PT", "text": "Portugalsko" },
      { "value": "PW", "text": "Palau" },
      { "value": "PY", "text": "Paraguaj" },
      { "value": "QA", "text": "Katar" },
      { "value": "RE", "text": "Réunion" },
      { "value": "RO", "text": "Rumunsko" },
      { "value": "RS", "text": "Srbsko" },
      { "value": "RU", "text": "Rusko" },
      { "value": "RW", "text": "Rwanda" },
      { "value": "SA", "text": "Saudská Arábia" },
      { "value": "SB", "text": "Šalamúnove ostrovy" },
      { "value": "SC", "text": "Seychely" },
      { "value": "SD", "text": "Sudán" },
      { "value": "SE", "text": "Švédsko" },
      { "value": "SG", "text": "Singapur" },
      { "value": "SH", "text": "Svätá Helena" },
      { "value": "SI", "text": "Slovinsko" },
      { "value": "SJ", "text": "Svalbard a Jan Mayen" },
      { "value": "SL", "text": "Sierra Leone" },
      { "value": "SM", "text": "San Maríno" },
      { "value": "SN", "text": "Senegal" },
      { "value": "SO", "text": "Somálsko" },
      { "value": "SR", "text": "Surinam" },
      { "value": "SS", "text": "Južný Sudán" },
      { "value": "ST", "text": "Svätý Tomáš a Princov ostrov" },
      { "value": "SV", "text": "Salvádor" },
      { "value": "SX", "text": "Sint Maarten" },
      { "value": "SY", "text": "Sýria" },
      { "value": "SZ", "text": "Svazijsko" },
      { "value": "TC", "text": "Turks a Caicos" },
      { "value": "TD", "text": "Čad" },
      { "value": "TF", "text": "Francúzske južné a antarktické územia" },
      { "value": "TG", "text": "Togo" },
      { "value": "TH", "text": "Thajsko" },
      { "value": "TJ", "text": "Tadžikistan" },
      { "value": "TK", "text": "Tokelau" },
      { "value": "TL", "text": "Východný Timor" },
      { "value": "TM", "text": "Turkménsko" },
      { "value": "TN", "text": "Tunisko" },
      { "value": "TO", "text": "Tonga" },
      { "value": "TR", "text": "Turecko" },
      { "value": "TT", "text": "Trinidad a Tobago" },
      { "value": "TV", "text": "Tuvalu" },
      { "value": "TW", "text": "Taiwan, provincie Čínskej republiky" },
      { "value": "TZ", "text": "Tanzánia, zjednotená republika" },
      { "value": "UG", "text": "Uganda" },
      { "value": "UM", "text": "Menšie odľahlé ostrovy Spojených štátov" },
      { "value": "US", "text": "Spojené štáty americké" },
      { "value": "UY", "text": "Uruguaj" },
      { "value": "UZ", "text": "Uzbekistan" },
      { "value": "VA", "text": "Svätá stolica" },
      { "value": "VC", "text": "Svätý Vincent a Grenadiny" },
      { "value": "VE", "text": "Venezuela, Bolívarovská republika" },
      { "value": "VG", "text": "Britské panenské ostrovy" },
      { "value": "VI", "text": "Americké panenské ostrovy" },
      { "value": "VN", "text": "Vietnam" },
      { "value": "VU", "text": "Vanuatu" },
      { "value": "WF", "text": "Wallis a Futuna" },
      { "value": "WS", "text": "Samoa" },
      { "value": "YE", "text": "Jemen" },
      { "value": "YT", "text": "Mayotte" },
      { "value": "ZA", "text": "Južná Afrika" },
      { "value": "ZM", "text": "Zambia" },
      { "value": "ZW", "text": "Zimbabwe" }
    ],

  orderAbortedReasonArray:
    [
      { "text": "Zákazník zrušil objednávku", "value": "CLIENT_CANCELLED_ORDER" },
      { "text": "Zákazník neprišiel", "value": "CLIENT_NOT_COME" },
      { "text": "Duplicitná objednávka", "value": "DUPLICATED" },
      { "text": "Nesprávna objednávka", "value": "INCORRECT_ORDER" },
      { "text": "Iné", "value": "OTHER" },
    ],
  messageType: {
    SYSTEM: "Systémová",
    PUBLIC: "Verejná",
    DISP_DRIVER: "S vodičom",
    ORDER: "Objednávka",
    DISPATCHING: "Medzi Dispečermi",
    DIRECT: "Priama",
  },
  messageType_short: {
    SYSTEM: "SYS",
    PUBLIC: "ALL",
    DISP_DRIVER: "Vodič",
    ORDER: "OBJ",
    DISPATCHING: "DISP",
    DIRECT: "Priama",
  },
  messageType_tooltip: {
    SYSTEM: "Správa vygenerovaná systémom",
    PUBLIC: "Správa distribuovaná na všetkých vodičov bez možnosti odpovedať na správu",
    DISP_DRIVER: "Správa medzi vodičom a dispečermi",
    ORDER: "Správa v rámci objednávky",
    DISPATCHING: "Správa medzi dispečermi",
    DIRECT: "Priama správa medzi dvoma používateľmi",
  },
  messageFromToType: {
    SYSTEM: "Systém",
    PUBLIC: "Všetci",
    DRIVER: "Vodič",
    CLIENT: "Zákazník",
    DISPATCHER: "Dispečer",
  },
  messageFromToType_short: {
    SYSTEM: "Systém",
    PUBLIC: "Všetci",
    DRIVER: "Vodič",
    CLIENT: "Zákazník",
    DISPATCHER: "Dispečer",
  },
  messageFromToType_tooltip: {
    SYSTEM: "Systém",
    PUBLIC: "Všetci",
    DRIVER: "Vodič",
    CLIENT: "Zákazník",
    DISPATCHER: "Dispečer",
  },
  gender:
    [
      {
        "text": "Muž",
        "value": "MALE",
      }, {
        "text": "Žena",
        "value": "FEMALE",
      }, {
        "text": "Iné",
        "value": "OTHER",
      },
    ],
  driverTag:
    [
      {
        "text": "-",
        "value": null,
      }, {
        "text": "Nepočujúci alebo nedoslýchavý",
        "value": "DEAF",
      }, {
        "text": "Pohybovo zdravotne postihnutý",
        "value": "IMMOBILE",
      },
    ],
  language: [
    { "text": "Slovensky", "value": "sk" },
    { "text": "Česky", "value": "cs" },
    { "text": "Anglicky", "value": "en" },
    { "text": "Ukrajinsky", "value": "uk" },
    { "text": "Maďarsky", "value": "hu" },
    { "text": "Nemecky", "value": "de" },
    { "text": "Francúzsky", "value": "fr" },
    { "text": "Španielsky", "value": "es" },
    { "text": "Rusky", "value": "ru" },
    { "text": "Turecky", "value": "tr" },
    { "text": "Poľsky", "value": "pl" },
    { "text": "Čínsky", "value": "zh" },
    { "text": "Chorvátsky", "value": "hr" },
    { "text": "Slovinsky", "value": "sl" },
    { "text": "Taliansky", "value": "tl" },
    { "text": "Srbsky", "value": "sr" },
  ],

  vehicleColor_short: {
    WHITE: "Biela", // biela
    BLACK: "Čierna", // Čierna
    SILVER: "Strieborná", // Strieborná
    GRAY: "Šedá", // Šedá
    BROWN: "Hnedá", // Hnedá
    PURPLE: "Purpurová", // Purpurová
    VIOLET: "Fialová", // Fialová
    BLUE: "Modrá", // Modrá
    DARKBLUE: "Tmavomodrá", // Tmavomodrá
    LIGHTBLUE: "Svetlomodrá", // Svetlomodrá
    SKYBLUE: "Strednemodrá", // Strednemodrá
    RED: "Červená", // Červená
    ORANGE: "Oranžová", //  Oranžová
    DARKORANGE: "Tmavooranžová", // Tmavooranžová
    YELLOW: "Žltá", // Žltá
    GREEN: "Zelená", // Zelená
    DARKGREEN: "Tmavozelená", // Tmavozelená
    LIGHTGREEN: "Svetlozelená", // Svetlozelená
    PINK: "Ružová", // Ružová
    BEIGE: "Béžová", // Béžová
    GOLD: "Zlatá", // Zlatá
  },
  vehicleColorArray:
    [
      {
        "text": "Biela",
        "value": "WHITE",
      }, {
        "text": "Čierna",
        "value": "BLACK",
      }, {
        "text": "Strieborná",
        "value": "SILVER",
      }, {
        "text": "Šedá",
        "value": "GRAY",
      }, {
        "text": "Hnedá",
        "value": "BROWN",
      }, {
        "text": "Purpurová",
        "value": "PURPLE",
      }, {
        "text": "Fialová",
        "value": "VIOLET",
      }, {
        "text": "Modrá",
        "value": "BLUE",
      }, {
        "text": "Tmavomodrá",
        "value": "DARKBLUE",
      }, {
        "text": "Svetlomodrá",
        "value": "LIGHTBLUE",
      }, {
        "text": "Strednemodrá",
        "value": "SKYBLUE",
      }, {
        "text": "Červená",
        "value": "RED",
      }, {
        "text": "Oranžová",
        "value": "ORANGE",
      }, {
        "text": "Tmavooranžová",
        "value": "DARKORANGE",
      }, {
        "text": "Žltá",
        "value": "YELLOW",
      }, {
        "text": "Zelená",
        "value": "GREEN",
      }, {
        "text": "Tmavozelená",
        "value": "DARKGREEN",
      }, {
        "text": "Svetlozelená",
        "value": "LIGHTGREEN",
      }, {
        "text": "Ružová",
        "value": "PINK",
      }, {
        "text": "Béžová",
        "value": "BEIGE",
      }, {
        "text": "Zlatá",
        "value": "GOLD",
      },
    ],

  mapShapeArray:
    [
      {
        "text": "Kruh",
        "value": "CIRCLE",
      }, {
        "text": "Polygón",
        "value": "POLYGON",
      },
    ],
  mapShape: {
    CIRCLE: "Kruh",
    POLYGON: "Polygón",
  },
  /* ReportStatusMonthView: {
     page_title: "Zoznam objednávok",
     Id: "Č. objednávky",
     Id_tooltip: 'Technické číslo objednávky {msg}',
     Status: "Stav",
     Type: "Typ",
     Source: "Zdroj",
     PaymentType: "Platba",
     Note: "Poznámka",
     CreatedAt: "Vytvorené",
     AcceptedAt: "Akceptované",
     DriverId: "Č. vodiča",
     boardAddress: "Vyzdvihnutie",
     destinationAddress: "Cieľ",
     EstimatedPrice: "Predpokladaná cena",
     FinalPrice: "Finálna cena",
     filterLabel: {
       Id: "č. objednávky",
       Status: "Stav",
       Type: "Typ",
       Source: "Zdroj",
       PaymentType: "Platba",
       Note: "Poznámka",
       CreatedAt: "Vytvorené",
       AcceptedAt: "Akceptované",
       DriverId: "Č. vodiča",
       boardAddress: "Vyzdvihnutie",
       destinationAddress: "Cieľ",
       EstimatedPrice: "Predpokladaná cena",
       FinalPrice: "Finálna cena",
     },
     RefreshData: "Aktualizovať",
   },*/
  messageDispatchList: {
    page_title: "Zoznam oznámení v dispečingu",
    toolbarActions: "Aktivity",
    Id: "ID",
    Id_tooltip: 'Technické číslo {msg}',
    Status: "Stav",
    Type: "Typ",
    Taxiservice: "Taxislužba",
    TaxiserviceId: "Č. taxislužby",
    CreatedAt: "Vytvorené",
    UpdatedAt: "Upravené",
    ValidFrom: "Platné od",
    ValidTo: "Platné do",
    Title: "Názov",
    Subtitle: "Podnázov",
    Message: "Text",
    filterLabel: {
      Id: "č. oznámenia",
      Status: "Stav",
      Type: "Typ",
      Taxiservice: "Taxislužba",
      CreatedAt: "Vytvorené",
      UpdatedAt: "Upravené",
      ValidFrom: "Platné od",
      ValidTo: "Platné do",
      Title: "Názov",
    },
    RefreshData: "Aktualizovať",
    showCreateDialog: "Nové Oznámenie",
    showEditDialog: "Úprava Oznámenia",
    btnSetActualDate: "Nastaviť platnosť do na aktuálny dátum",
    msgValidMaxDate: "Platnosť do musí byť do 60 dní od dnešného dátumu",
    msgGetTaxiservicListForPickerError: "Nepodarilo sa načítať zoznam taxislužieb pre picker",
  },
  messageDispatchTypeArray:
    [
      {
        "text": "Zmuvné vzťahy",
        "value": "CONTRACT",
      }, {
        "text": "Údržba",
        "value": "MAINTENANCE",
      }, {
        "text": "Platby",
        "value": "PAYMENT",
      }, {
        "text": "Release",
        "value": "RELEASE",
      }, {
        "text": "Stav systému",
        "value": "SYSTEM_STATUS",
      }, {
        "text": "Iné",
        "value": "OTHER",
      },
    ],
  messageDispatchType: {
    CONTRACT: "Zmluvné vzťahy",
    MAINTENANCE: "Údržba",
    PAYMENT: "Platby",
    RELEASE: "Release",
    SYSTEM_STATUS: "Stav systému",
    OTHER: "Iné",
  },
  messageDispatchStatusArray:
    [
      {
        "text": "Expirované",
        "value": "EXPIRED",
      }, {
        "text": "Plánované",
        "value": "PLANNED",
      }, {
        "text": "Aktívne",
        "value": "ACTIVE",
      },
    ],
  messageDispatchStatus: {
    EXPIRED: "Expirované",
    PLANNED: "Plánované",
    ACTIVE: "Aktívne",
    OTHER: "Iné",
  },

  smsTemplateTypeArray:
    [
      {
        "text": "Akceptovanie objednávky vodičom",
        "value": "ACCEPTED",
      },
      /*{
        "text": "Priblíženie vozidla",
        "value": "APPROACHING",
      },*/
      {
        "text": "Pristavenie vozidla",
        "value": "WAITING",
      },
      /* {
        "text": "Priblíženie alebo pristavenie vozidla",
        "value": "WAITING_APPROACHING",
      },*/
      {
        "text": "Ukončenie jazdy",
        "value": "FINISHED",
      }, {
        "text": "Vyúčtovanie",
        "value": "SETTLED",
      },
      {
        "text": "Zrušenie objednávky",
        "value": "CANCELLED",
      },
    ],
  smsTemplateType: {
    ACCEPTED: "Akceptovanie objednávky",
    APPROACHING: "Priblíženie vozidlo",
    WAITING: "Pristavenie vozidla",
    WAITING_APPROACHING: "Priblíženie alebo pristavenie vozidla",
    FINISHED: "Ukončenie jazdy",
    SETTLED: "Vyúčtovanie",
    CANCELLED: "Zrušenie",
  },

  smsTemplateLanguageArray:
    [
      { "text": "Slovensky", "value": "SK" },
      { "text": "Česky", "value": "CS" },
      { "text": "Anglicky", "value": "EN" },
    ],
  smsTemplateLanguage: {
    SK: "Slovensky",
    CS: "Česky",
    EN: "Anglicky",
  },

  AccountEnums: {
    Status: {
      ACTIVE: "Aktívny",
      DISABLED: "Neaktívny",
      BLOCKED: "Zablokovaný",
      ARCHIVED: "Archivovaný",
    },
    StatusArray:
      [
        { "text": "Aktívny", "value": "ACTIVE", },
        { "text": "Neaktívny", "value": "DISABLED", },
        { "text": "Zablokovaný", "value": "BLOCKED", },
        { "text": "Archivovaný", "value": "ARCHIVED", },
      ],
    CompanyVatPayer:
      [
        { "text": "Nie je platiteľ DPH", "value": "NO_VAT", },
        { "text": "Platiteľ DPH", "value": "VAT", },
        { "text": "Oslobodený od DPH", "value": "VAT_FREE", },
        { "text": "Zahraničná osoba bez DPH", "value": "FOREIGN", },
      ],
    ClientStatus: {
      ACTIVE: "Aktívny",
      DISABLED: "Neaktívny",
      PENDING: "Na schválenie",
      BLOCKED: "Zablokovaný",
      ARCHIVED: "Archivovaný",
    },
    ClientStatusArray:
      [
        { "text": "Aktívny", "value": "ACTIVE", },
        { "text": "Neaktívny", "value": "DISABLED", },
        { "text": "Na schválenie", "value": "PENDING", },// Čakajúci na schválenie (2)
        { "text": "Zablokovaný", "value": "BLOCKED", },
        { "text": "Archivovaný", "value": "ARCHIVED", },
      ],
  },

  ModulePricelistType: {
    SIMPLE: "Defaultné ceny",
    LOCALITY: "Ceny pre lokality",
    EXTENDED: "Rozšírené",
  },
  ModulePricelistTypeArray:
    [
      { "text": "Defaultné ceny", "value": "SIMPLE", },
      { "text": "Ceny pre lokality", "value": "LOCALITY", },
      { "text": "Rozšírené", "value": "EXTENDED", },
    ],

  ModuleAddressSearch: {
    DB_GOOGLE: "Privátna DB (Google)",
    DB_OSM: "Privátna DB (OSM)",
    DB_GEOCODE: "Privátna DB (Geocode)",
    GOOGLE: "Google",
    OSM: "OSM",
    GEOCODE: "Geocode",
  },
  ModuleAddressSearchArray:
    [
      { "text": "Privátna DB (Google)", "value": "DB_GOOGLE", },
      { "text": "Privátna DB (OSM)", "value": "DB_OSM", },
      { "text": "Privátna DB (Geocode)", "value": "DB_GEOCODE", },
      { "text": "Google", "value": "GOOGLE", },
      { "text": "OSM", "value": "OSM", },
      { "text": "Geocode", "value": "GEOCODE", },
    ],

  AccountList: {
    page_title: "Firemní klienti",
    dialogTitle: "Nový firemný klient",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    status: "Stav",
    accountStatus: "Stav firmy",
    name: "Názov v zozname",
    name_hint: "Názov používaný pri bežnej práci v systéme. Použite čo najkratší.",
    companyIdNumber: "IČO",
    companyIdNumber_hint: "IČO nebude možné zmeniť po vytvorení. V každom štáte musí byť číslo jedinečné.",
    companyTaxId: "DIČ",
    companyVatPayer: "Platiteľ DPH",
    companyVatId: "IČ DPH",
    btnOrsr: "OR SR",
    btnNextNoCIN: "Ďalej bez IČO",
    validTo: "Platnosť do",
    country: "Krajina",
    country_hint: "Štát nebude možné zmeniť po vytvorení",
    companyName: "Názov spoločnosti",
    companyName_hint: "Názov určený pre faktúry",
    email: "Email",
    companyAddress1: "Adresa spoločnosti (1. riadok)",
    companyAddress2: "Adresa spoločnosti (2. riadok)",
    companyAddress3: "Adresa spoločnosti (3. riadok)",
    tableHeaders: {
      actions: "Akcie",
      accountId: "ID",
      status: "Stav",
      accountStatus: "Stav firmy",
      validFrom: "Platnosť od",
      validTo: "Platnosť do",
      name: "Názov v zozname",
      companyIdNumber: "IČO",
      country: "Krajina",
      companyName: "Názov spoločnosti",
      email: "Email",
    },
    filterLabel: {
      actions: "Akcie",
      accountId: "ID",
      status: "Stav",
      accountStatus: "Stav firmy",
      validFrom: "Platnosť od",
      validTo: "Platnosť do",
      name: "Názov v zozname",
      companyIdNumber: "IČO",
      country: "Krajina",
      companyName: "Názov spoločnosti",
      email: "Email",
    },
    msgAccountAlreadyExists: "Účet s týmto IČO už existuje", //Company Id Number already exists
  },

  Account: {
    page_title: "Firemný klient",
    tabInfo: "Informácie",
    tabUsers: "Používatelia",
    status: "Stav",
    status_hint: "Dispečerovi sa zobrazí iba aktívny účet",
    accountStatus: "Stav firmy",
    accountStatus_hint: "Stav nie je možné upravovať",
    name: "Názov v zozname",
    companyIdNumber: "IČO",
    companyIdNumber_hint: "IČO nie je možné upravovať",
    companyTaxId: "DIČ",
    companyVatPayer: "Platiteľ DPH",
    companyVatId: "IČ DPH",
    validFrom: "Platnosť od",
    validTo: "Platnosť do",
    country: "Krajina",
    country_hint: "Štát nie je možné upravovať",
    companyName: "Názov spoločnosti",
    email: "Email",
    companyAddress1: "Adresa spoločnosti (1. riadok)",
    companyAddress2: "Adresa spoločnosti (2. riadok)",
    companyAddress3: "Adresa spoločnosti (3. riadok)",
  },
  AccountClient: {
    clientPhone: "Telefón",
    msgPhoneValidation: "Telefónne číslo musí byť v tvare +421123456789",
    btnAddCustomer: "Prideliť zákazníka",
    dialogAssignTitle: "Pridanie zákazníka Firemnému klientovi",
    clientId: "ID zákazníka",
    phone: "Telefón",
    nickname: "Meno",
    mark: "Označenie",
    note: "Poznámka",
    info: "Informácie",
    dialogEditTitle: "Upraviť priradenie zákazníka",
    accountId: "ID firemného klienta",
    status: "Stav",
    validFrom: "Platnosť od",
    validTo: "Platnosť do",
    tableHeaders: {
      actions: "Akcie",
      clientId: "ID",
      phone: "Telefón",
      nickname: "Meno",
      mark: "Označenie",
      status: "Stav",
      validFrom: "Platnosť od",
      validTo: "Platnosť do",
    },
    msgPhoneMissing: "Telefónne číslo musí byť vyplnené",
    msgPhoneInvalid: "Telefónne číslo musí byť v tvare +421123456789",
    msgPhoneShort: "Telefónne číslo musí mať aspoň 11 číslic",
    msgPhoneOnlyNumbers: "Telefónne číslo musí obsahovať iba číslice a znak +",
    msgPhoneSecondCharIsNotNumber: "Druhý znak telefónneho čísla musí byť číslica",
    msgCustomerAlreadyAssigned: "Zákazník je už priradený k tomuto firemnému klientovi",
    msgCustomerNotExists: "Zákazník s týmto telefónnym číslom neexistuje",
    msgCustomerNotAddedNoCustomer: "Zákazník nebol pridaný. Taxislužba o zákazníkovi s takýmto telefónom nemá záznam.",
    msgCustomerNotAddedAlreadyAdded: "Zákazník nebol pridaný. Zákazník bol už pridaný. Ak sa nezobrazuje, skontrolujete jeho stav a čas platnosti",
    msgCustomerSuccessfullyAdded: "Zákazník bol úspešne pridaný",
  },

  DriverVehicleList: {
    page_title: "Pridelené vozidlá",
    enabledDriversSwitch: "Povolení vodiči",
    btnAssignVehicleTitle: "Prideliť vozidlo",
    dialogAssignTitle: "Prajete si prideliť vodičovi vozidlo?",
    dialogEndWorkshiftTitle: "Prajete si ukončiť pracovnú zmenu vodiča?",
    driverId: "ID",
    firstName: "Meno",
    lastName: "Priezvisko",
    fullNameVehicle: "Vozidlo",
    vehicleId: "ID",
    mileages: "Stav tachometra",
    listAssigned: "Pridelené",
    listUsed: "Používané",
    listRemoveVehicle: "- Odobrať pridelené vozidlo - ",
    msgAssigmentSuccesufull: "Vozidlo bolo úspešne pridelené",
    btnEndShift: "Ukončiť pracovnú zmenu",
    msgEndWorkshiftSuccesufull: "Pracovná zmena bola úspešne ukončená",
    msgEndWorkshiftErrorDriverHasOrder: "Pracovná zmena nemôže byť ukončená, pretože vodič má aktívne objednávky",
    tableHeaders: {
      actions: "Akcie",
      driverId: "ID",
      internalName: "Interný názov",
      firstName: "Meno",
      lastName: "Priezvisko",
      active: "Voľba",
      workStatus: "Prac. zmena",
      silence: "Tichý režim",
      vehicleAssignedInternalNameLpn: "Pridelené",
      vehicleAssignedBrandModel: "Model",
      vehicleSelectedInternalNameLpn: "Používané",
      vehicleSelectedBrandModel: "Model",
    },
  },

  VehicleDriverList: {
    page_title: "Zoznam vozidiel a vodičov",
    dialogDetailTitle: "Detail vozidla a vodiča",
    dialogCreateMaintenanceTitle: "Vytvoriť údržbu vozidla",
    dialogUpdateLastMaintenanceTitle: "Nastavenie poslednej údržby vozidla",
    dialogMaintenanceListTitle: "Zoznam údržieb a opráv vozidla",
    tableHeaders: {
      actions: "Akcie",
      internalName: "Interný názov",
      lpn: "ŠPZ",
      brand: "Značka",
      model: "Model",
      active: "Aktívne",
      status: "Stav",
      mileages: "Stav tachometra",
      driverId: "ID",
      driverInternalName: "Interný názov vodiča",
      driverFirstName: "Meno",
      driverLastName: "Priezvisko",
      driverActive: "Voľba",
      driverWorkStatus: "Prac. zmena",
      driverSilence: "Tichý režim",
      driverPhone: "Telefón",
    },
    filterLabel: {
      internalName: "Interný názov",
      lpn: "ŠPZ",
      brand: "Značka",
      model: "Model",
      active: "Aktívne",
      status: "Stav",
      mileages: "Stav tachometra",
      driverId: "ID",
      driverInternalName: "Interný názov vodiča",
      driverFirstName: "Meno",
      driverLastName: "Priezvisko",
      driverActive: "Voľba",
      driverWorkStatus: "Prac. zmena",
      driverSilence: "Tichý režim",
      driverPhone: "Telefón",
    },
    vehicleMaintenance: {
      page_title: "Údržba vozidiel",
      vehicleId: "ID vozidla",
      internalName: "Interný názov",
      lpn: "ŠPZ",
      brand: "Značka",
      model: "Model",
      active: "Aktívne",
      mileages: "Stav tachometra",
      lastMaintenanceAt: "Posledná údržba",
      lastMaintenanceMileage: "Stav kilometrov pri poslednej údržbe",
      maintenanceIntervalDay: "Interval údržby (dni)",
      maintenanceIntervalMileage: "Interval údržby (km)",
      maintenanceNotificationDay: "Upozornenie na údržbu (dni)",
      maintenanceNotificationMileage: "Upozornenie na údržbu (km)",
      plannedAt: "Plánované",
      startedAt: "Začaté",
      startedMileage: "Stav kilometrov na začiatku",
      resolvedAt: "Dokončené",
      resolvedMileage: "Stav kilometrov na konci",
      title: "Názov",
      type: "Typ",
      status: "Stav",
      note: "Poznámka",
      price: "Cena",
      fluidCheck: "Kontrola hladiny kvapalín",
      oilTopUp: "Doliatie oleja",
      oilChange: "Výmena oleja",
      coolantChange: "Výmena chladiva",
      brakeFluidChange: "Výmena brzdovej kvapaliny",
      oilFilterReplacement: "Výmena olejového filtra",
      airFilterReplacement: "Výmena vzduchového filtra",
      airConFilterReplacement: "Výmena filtra klimatizácie",
      sparkOrGlowPlugReplacement: "Výmena sviečok",
      tireReplacement: "Výmena pneumatík",
      drivetrainCheck: "Kontrola pohonu",
      serpentineBeltReplacement: "Výmena remeňa",
      otherFilterReplacement: "Výmena iného filtra",
      otherFluidTopUp: "Doliatie inej kvapaliny",
      lightRepair: "Oprava svetiel",
      brakeRepair: "Oprava brzd",
      bodyRepair: "Oprava karosérie",
      engineRepair: "Oprava motora",
      electronicsRepair: "Oprava elektroniky",
      drivetrainRepair: "Oprava pohonu",
      batteryReplacement: "Výmena batérie",
      otherRepair: "Iná oprava",
      cleaning: "Čistenie",
      btnSetLastMaintenanceAtFromVehicle: "Nastaviť údaje poslednej údržby vozidla",
      btnSetLastMaintenanceAtToNow: "Nastaviť dnešný dátum a posledný stav tachometra",
      btnSetUpdateMaintenanceMileageAtFromVehicle: "Nastaviť kilometre z údajov vozidla (tachometer)",
      btnSetUpdateMaintenanceDatesAtToNow: "Nastaviť dnešný dátum",
    },
    tableMaintenanceHeaders: {
      actions: "Akcie",
      mileages: "Tachometer",
      plannedAt: "Plánované",
      startedAt: "Začaté",
      startedMileage: "Štart KM",
      resolvedAt: "Dokončené",
      resolvedMileage: "Koniec KM",
      title: "Názov",
      type: "Typ",
      status: "Stav",
      note: "Poznámka",
      price: "Cena",
      fluidCheck: "Kontr. kvap.",
      oilTopUp: "Dopl. oleja",
      oilChange: "Vým. oleja",
      coolantChange: "Vým. chladiva",
      brakeFluidChange: "Vým. brzd. kvap.",
      oilFilterReplacement: "Vým. olej. filtra",
      airFilterReplacement: "Vým. vzduch. filtra",
      airConFilterReplacement: "Vým. filtra klim.",
      sparkOrGlowPlugReplacement: "Vým. sviečok",
      tireReplacement: "Vým. pneum.",
      drivetrainCheck: "Kontr. pohonu",
      serpentineBeltReplacement: "Vým. remeňa",
      otherFilterReplacement: "Vým. iného filtra",
      otherFluidTopUp: "Dopl. inej kvap.",
      lightRepair: "Opr. svetiel",
      brakeRepair: "Opr. brzd",
      bodyRepair: "Opr. karos.",
      engineRepair: "Opr. motora",
      electronicsRepair: "Opr. elektr.",
      drivetrainRepair: "Opr. pohonu",
      batteryReplacement: "Vým. batérie",
      otherRepair: "Iná opr.",
      cleaning: "Čistenie",
    },
  },
  VehicleMaintenanceList: {
    page_title: "Údržba vozidiel",
    dialogDetailTitle: "Detail údržby vozidla",
    vehicleId: "ID vozidla",
    internalName: "Interný názov",
    lpn: "ŠPZ",
    brand: "Značka",
    model: "Model",
    active: "Aktívne",
    mileages: "Stav tachometra",
    lastMaintenanceAt: "Posledná údržba",
    lastMaintenanceMileage: "Stav kilometrov pri poslednej údržbe",
    maintenanceIntervalDay: "Interval údržby (dni)",
    maintenanceIntervalMileage: "Interval údržby (km)",
    maintenanceNotificationDay: "Upozornenie na údržbu (dni)",
    maintenanceNotificationMileage: "Upozornenie na údržbu (km)",
    plannedAt: "Plánované",
    startedAt: "Začaté",
    startedMileage: "Stav kilometrov na začiatku",
    resolvedAt: "Dokončené",
    resolvedMileage: "Stav kilometrov na konci",
    btnSetUpdateMaintenanceMileageAtFromVehicle: "Nastaviť kilometre z údajov vozidla (tachometer)",
    btnSetUpdateMaintenanceDatesAtToNow: "Nastaviť dnešný dátum",
    title: "Názov",
    type: "Typ",
    status: "Stav",
    note: "Poznámka",
    price: "Cena",
    fluidCheck: "Kontrola hladiny kvapalín",
    oilTopUp: "Doliatie oleja",
    oilChange: "Výmena oleja",
    coolantChange: "Výmena chladiva",
    brakeFluidChange: "Výmena brzdovej kvapaliny",
    oilFilterReplacement: "Výmena olejového filtra",
    airFilterReplacement: "Výmena vzduchového filtra",
    airConFilterReplacement: "Výmena filtra klimatizácie",
    sparkOrGlowPlugReplacement: "Výmena sviečok",
    tireReplacement: "Výmena pneumatík",
    drivetrainCheck: "Kontrola pohonu",
    serpentineBeltReplacement: "Výmena remeňa",
    otherFilterReplacement: "Výmena iného filtra",
    otherFluidTopUp: "Doliatie inej kvapaliny",
    lightRepair: "Oprava svetiel",
    brakeRepair: "Oprava brzd",
    bodyRepair: "Oprava karosérie",
    engineRepair: "Oprava motora",
    electronicsRepair: "Oprava elektroniky",
    drivetrainRepair: "Oprava pohonu",
    batteryReplacement: "Výmena batérie",
    otherRepair: "Iná oprava",
    cleaning: "Čistenie",
    tableHeaders: {
      actions: "Akcie",
      vehicleInternalName: "Interný názov",
      vehicleLpn: "ŠPZ",
      vehicleBrand: "Značka",
      vehicleModel: "Model",
      vehicleActive: "Aktívne",
      vehicleStatus: "Stav",
      vehicleMileages: "Stav tachometra",
      type: "Typ",
      status: "Stav",
      plannedAt: "Plánované",
      startedAt: "Začaté",
      resolvedAt: "Dokončené",
      oilChange: "Výmena oleja",
      price: "Cena",
    },
    filterLabel: {
      actions: "Akcie",
      vehicleInternalName: "Interný názov",
      vehicleLpn: "ŠPZ",
      vehicleBrand: "Značka",
      vehicleModel: "Model",
      vehicleActive: "Aktívne",
      vehicleStatus: "Stav",
      vehicleMileages: "Stav tachometra",
      type: "Typ",
      status: "Stav",
    },
  },
  MaintenanceType: {
    REPAIR: "Oprava",
    MAINTENANCE: "Údržba",
    CHECKUP: "Kontrola",
    SHORT_TERM_INSPECTION: "Krátkodobá kontrola",
    LONG_TERM_INSPECTION: "Dlhodobá kontrola",
    SEASONAL_INSPECTION: "Sezónna kontrola",
    CLEANING: "Čistenie",
  },
  MaintenanceTypeArray:
    [
      { "text": "Oprava", "value": "REPAIR", },
      { "text": "Údržba", "value": "MAINTENANCE", },
      { "text": "Kontrola", "value": "CHECKUP", },
      { "text": "Krátkodobá kontrola", "value": "SHORT_TERM_INSPECTION", },
      { "text": "Dlhodobá kontrola", "value": "LONG_TERM_INSPECTION", },
      { "text": "Sezónna kontrola", "value": "SEASONAL_INSPECTION", },
      { "text": "Čistenie", "value": "CLEANING", },
    ],
  MaintenanceStatus: {
    PLANNED: "Plánované",
    DELAYED: "Omeškané",
    ONGOING: "Prebieha",
    PAUSED: "Pozastavené",
    COMPLETED: "Dokončené",
    CANCELLED: "Zrušené",
    UNFINISHED: "Nedokončené",
  },
  MaintenanceStatusArray:
    [
      { "text": "Plánované", "value": "PLANNED", },
      { "text": "Omeškané", "value": "DELAYED", },
      { "text": "Prebieha", "value": "ONGOING", },
      { "text": "Pozastavené", "value": "PAUSED", },
      { "text": "Dokončené", "value": "COMPLETED", },
      { "text": "Zrušené", "value": "CANCELLED", },
      { "text": "Nedokončené", "value": "UNFINISHED", },
    ],


  PlaceList: {
    page_title: "Zoznam miest",
    tabList: "Zoznam",
    tabMap: "Mapa",
    //Tab List

    //Tab Map
    toolbar_icon_title: "Prepnúť zobrazenie okna",
    toolbarList_title: "Adresy",
    searchField: "Hľadať",
    searchField_title: "Vyhľadávanie v zozname Adries potvrďte stlačením klávesy Enter.",
    showOnlyNotApprovedAddressesSwitch_title: "Zobraziť iba Adresy určené na schválenie",
    toolbarNew_title: "Vytvoriť nové miesto",
    searchNewGoogle: "Hľadať novú adresu GOOGLE",
    searchNewGeocode: "Hľadať novú adresu OSM",
    toolbarDetails_title: "Detail miesta",
    addressListItem_title: "Adresa zobrazovaná používateľovi",
    addressListItemSubtitle1_title: "GPS poloha miesta",
    addressListItemSubtitle1_text: "GPS súradnice",
    addressListItemSubtitle2_title: "Číslo domu, Cesta/Ulica", //houseNumber, road
    addressListItemSubtitle3_title: "Mesto, PSČ, Štát", //city, country
    addressListItemShowAddressBtn: "Zobraziť adresu na mape",
    displayName: "Názov",
    active: "Aktívny",
    latitude: "Zem. šírka",
    longitude: "Zem. dĺžka",
    houseNumber: "Číslo domu",
    road: "Cesta/Ulica",
    city: "Mesto",
    state: "Kraj",
    postcode: "PSČ",
    country: "Štát",
    countryCode: "Kód štátu",
    more: "Viac...",
    amenityName: "Popis miesta / *Alternatívny názov",
    amenityType: "Typ miesta",
    neighbourhood: "Lokalita mesta",
    suburb: "Štvrť mesta",
    cityDistrict: "Mestská časť",
    county: "Okres",
    ordering: "Poradie",
    priority: "Priorita",
    dialogTitle: "Miesto",
    tableHeaders: {
      actions: "Akcie",
      displayName: "Názov",
      active: "Aktívny",
      categoryName: "Kategória",
      city: "Mesto",
      countryCode: "Kód štátu",
      ordering: "Poradie",
      priority: "Priorita",
    },
  },

  DriverList: {
    page_title: "Zoznam vodičov",
    enabledDriversSwitch: "Povolení vodiči",
    dialogCreateTitle: "Nový vodič",
    dialogEditTitle: "Úprava vodiča",
    dialogEditStatusTitle: "Prajete si zmeniť stav vodiča?",
    dialogPasswordChangeTitle: "Prajete si zmeniť heslo vodičovi?",
    btnEdit: "Upraviť",
    btnEditStatus: "Zmeniť stav",
    btnPasswordChange: "Zmeniť heslo",
    btnDisable: "Zablokovať",
    btnEnable: "Povoliť",
    silenceDisabledTitle: "Tichý režim",
    driverId: "ID",
    id: "ID",
    email: "Email / Login",
    password: "Heslo",
    activeAcc: "Aktívny",
    firstName: "Meno",
    lastName: "Priezvisko",
    internalName: "Interné označenie",
    phone: "Telefón",
    phoneExt: "Telefónna klapka",
    gender: "Pohlavie",
    dispatcher: "Dispečer",
    tag: "Iné",
    driverLicenceNumber: "Číslo vodičského preukazu",
    driverLicenceExpiration: "Platnosť vodičského preukazu",
    driverTaxiLicenceNumber: "Číslo preukazu vodiča taxislužby",
    driverTaxiLicenceExpiration: "Platnosť preukazu vodiča taxislužby",
    language1: "Materinský jazyk",
    language2: "Jazyk 2",
    language3: "Jazyk 3",
    language4: "Jazyk 4",
    msgPasswordChanged: "Heslo bolo úspešne zmenené",
    msgInvalidDataInForm: "Formulár obsahuje neplatné údaje",
    msgDataMissing: "Formulár obsahuje nevyplnené údaje",
    msgDriverCreated: "Vodič bol úspešne vytvorený",
    msgDriverUpdated: "Vodič bol úspešne upravený",
    msgDriverStatusUpdated: "Stav vodiča bol úspešne zmenený",
    tableHeaders: {
      actions: "Akcie",
      email: "Email",
      internalName: "Interný názov",
      firstName: "Meno",
      lastName: "Priezvisko",
      active: "Voľba",
      workStatus: "Prac. zmena",
      silence: "Tichý režim",
      status: "Stav",
      phone: "Telefón",
      phoneExt: "Klapka",
      password: "Heslo",
      language1: "Jazyk 1",
      gender: "Pohlavie",
      dispatcher: "Dispečer",
      licenceStatus: "Stav licencie",
    },
    filterLabel: {
      email: "Email",
      internalName: "Interný názov",
      firstName: "Meno",
      lastName: "Priezvisko",
      active: "Voľba",
      workStatus: "Prac. zmena",
      silence: "Tichý režim",
      status: "Stav",
      phone: "Telefón",
      phoneExt: "Klapka",
    },
  },

  VehicleList: {
    page_title: "Zoznam vozidiel",
    dialogCreateTitle: "Nové vozidlo",
    dialogUpdateLastMaintenanceTitle: "Nastavenie poslednej údržby vozidla",
    btnSetLastMaintenanceAtFromVehicle: "Nastaviť údaje poslednej údržby vozidla",
    btnSetLastMaintenanceAtToNow: "Nastaviť dnešný dátum posledný stav tachometra",
    vehicleId: "ID",
    internalName: "Interné označenie",
    brand: "Značka",
    model: "Model",
    type: "Typ",
    lpn: "ŠPZ",
    color: "Farba",
    mileages: "Prejazdené km",
    lastMaintenanceAt: "Posledná údržba",
    lastMaintenanceMileage: "Posledná údržba (km)",
    tableHeaders: {
      actions: "Akcie",
      internalName: "Interné označenie",
      brand: "Značka",
      model: "Model",
      type: "Typ",
      lpn: "ŠPZ",
      active: "Aktívny",
      status: "Stav",
      color: "Farba",
      phoneExt: "Klapka",
      phone: "Telefón",
      mileages: "Prejazdené km",
      lastMaintenanceAt: "Posl. údržba",
      lastMaintenanceMileage: "Posl. údržba (km)",
      maintenanceIntervalDay: "Int. údržby (dni)",
      maintenanceIntervalMileage: "Int. údržby (km)",
      maintenanceNotificationDay: "Upoz. údržby (dni)",
      maintenanceNotificationMileage: "Upoz. údržby (km)",
    },
    filterLabel: {
      search: "Hľadať",
      internalName: "Interné označenie",
      brand: "Značka",
      model: "Model",
      type: "Typ",
      lpn: "ŠPZ",
      active: "Aktívny",
      status: "Stav",
      phoneExt: "Klapka",
      phone: "Telefón",
      lastMaintenanceAt: "Posledná údržba",
      lastMaintenanceMileage: "Posledná údržba (km)",
      maintenanceIntervalDay: "Interval údržby (dni)",
      maintenanceIntervalMileage: "Interval údržby (km)",
      maintenanceNotificationDay: "Upozornenie údržby (dni)",
      maintenanceNotificationMileage: "Upozornenie údržby (km)",
    },
  },

  Vehicle: {
    page_title: "Vozidlo",
    tabInfo: "Informácie",
    tabFilter: "Filtre",
    tabPhoto: "Fotka",
    internalName: "Interné označenie",
    brand: "Značka",
    model: "Model",
    type: "Typ",
    lpn: "ŠPZ",
    active: "Aktívny",
    status: "Stav",
    color: "Farba",
    phoneExt: "Telefónna klapka",
    phone: "Telefón",
    mileages: "Prejazdené km",
    lastMaintenanceAt: "Posledná údržba",
    lastMaintenanceMileage: "Posledná údržba (km)",
    maintenanceIntervalDay: "Interval údržby (dni)",
    maintenanceIntervalMileage: "Interval údržby (km)",
    maintenanceNotificationDay: "Upozornenie údržby (dni)",
    maintenanceNotificationMileage: "Upozornenie údržby (km)",
    msgInternalNameRule: "Nesmie byť dlhšie ako 3 znaky",  //Name must be no more than 3 characters
    mgsInternalNameHint: "Maximálne 3 znaky, odporúčané max. 2 znaky", //Maximum 3 characters (recommended: max 2)
    maxPerson: "Maximálny počet cestujúcich",
    airconditioning: "Klimatizácia",
    animal: "Zviera",
    autonomous: "Autonómne vozidlo",
    childrenChair: "Preprava dieťaťa",
    driverRating: "Hodnotenie vodiča",
    eco: "Ekologický",
    luggage: "Batožina",
    paymentTerminal: "Platobný terminál",
    smoking: "Fajčenie vo vozidle",
    specialTransport: "Špeciálna preprava",
    actualPicture: "Aktuálna fotka",
    newPicture: "Nová fotka",
    btnSelectPicture: "Vybrať fotku",
    btnSavePicture: "Uložiť fotku",
    examplePicture: "Príklad fotky",
    pictureHint: "Fotka musí byť vo formáte .jpg, .jpeg alebo .png a nesmie byť väčšia ako 2MB. " +
      "Fotka vozidla musí zachytávať pohľad na celé vozidlo z pravej prednej strany (trojštvrtinový pohľad na vozidlo). " +
      "Vozidlo na fotke pôsobí akoby prichádzalo k zákazníkovi z ľavej strany po ceste, a zákazník môže ľahko rozoznať prednú masku vozidla a zároveň pri pohľade z boku aký je to typ vozidla. " +
      "Výsledná fotka musí obsahovať vozidlo, ktoré vidno celé, neorezané. Ideálne so svetlým jednotným pozadím. ",
    uploadPlugin: {
      hint: "Kliknite alebo potiahnite súbor sem, aby ste ho nahrali",
      loading: "Nahrávanie…",
      noSupported: "Prehliadač nie je podporovaný, použite IE10+ alebo iné prehliadače",
      success: "Nahrávanie sa podarilo",
      fail: "Nahrávanie sa nepodarilo",
      preview: "Náhľad",
      btn: {
        off: "Zrušiť",
        close: "Zavrieť",
        back: "Späť",
        save: "Uložiť",
      },
      error: {
        onlyImg: "Iba obrázok",
        outOfSize: "Veľkosť obrázka prekročila limit: ",
        lowestPx: "Veľkosť obrázka je príliš malá. Očakáva sa aspoň: ",
      },
    },
  },
  VehicleEnums: {
    Status: {
      ACTIVE: "Aktívny",
      DISABLED: "Neaktívny",
      BLOCKED: "Zablokovaný",
      ARCHIVED: "Archivovaný",
    },
    StatusArray:
      [
        { "text": "Aktívny", "value": "ACTIVE", },
        { "text": "Neaktívny", "value": "DISABLED", },
        { "text": "Zablokovaný", "value": "BLOCKED", },
        { "text": "Archivovaný", "value": "ARCHIVED", },
      ],
    Type: {
      SEDAN: "Sedan",
      COMBI: "Combi",
      CABRIO: "Cabrio",
      COUPE: "Coupé",
      HATCHBACK: "Hatchback",
      VAN: "Van",
      MICROBUS: "Microbus",
      BUS: "Bus",
      VAN_CARGO: "Dodávka",
      PICKUP: "Pickup",
      SUV: "SUV",
    },
    TypeArray:
      [
        { "text": "Sedan", "value": "SEDAN", },
        { "text": "Combi", "value": "COMBI", },
        { "text": "Cabrio", "value": "CABRIO", },
        { "text": "Coupé", "value": "COUPE", },
        { "text": "Hatchback", "value": "HATCHBACK", },
        { "text": "Van", "value": "VAN", },
        { "text": "Microbus", "value": "MICROBUS", },
        { "text": "Bus", "value": "BUS", },
        { "text": "Dodávka", "value": "VAN_CARGO", },
        { "text": "Pickup", "value": "PICKUP", },
        { "text": "SUV", "value": "SUV", },
      ],
  },

  DispatcherList: {
    page_title: "Zoznam dispečerov",
    editInformation: "Upraviť informácie o dispečerovi",
    changePassword: "Zmeniť heslo dispečerovi",
    changeRole: "Zmeniť rolu dispečerovi",
    dialogCreateTitle: "Vytvoriť dispečera",
    dialogEditTitle: "Upraviť dispečera",
    dialogChangePasswordTitle: "Prajete si zmeniť heslo dispečerovi?",
    dialogChangePasswordText: "Oznámte prosím nové heslo dispečerovi. Dispečerovi nebude heslo zaslané.",
    dialogEditRoleTitle: "Prajete si zmeniť rolu dispečerovi?",
    msgPasswordChanged: "Heslo bolo úspešne zmenené",
    msgInvalidDataInForm: "Formulár obsahuje neplatné údaje",
    msgDispatcherRoleUpdated: "Stav vodiča bol úspešne zmenený",
    emailSameDriverDispatcherWarning: "Nepoužívajte rovnaký email pre vodiča a dispečera, aj keď je to tá istá osoba.",
    dispatcherId: "ID",
    id: "ID",
    email: "Email / Login",
    password: "Heslo",
    activeAcc: "Aktívny",
    firstName: "Meno",
    lastName: "Priezvisko",
    phone: "Telefón",
    role: "Rola",
    tableHeaders: {
      actions: "Akcie",
      email: "Email / Login",
      activeAcc: "Aktívny",
      firstName: "Meno",
      lastName: "Priezvisko",
      role: "Rola",
      phone: "Telefón",
    },
    filterLabel: {
      email: "Email",
      firstName: "Meno",
      lastName: "Priezvisko",
      phone: "Telefón",
    },
  },
  DispatcherRole: {
    ADMIN: "Admin",
    SUPPORT: "Podpora",
    //Broker
    BROKER_MANAGER: "Broker Manažér",
    BROKER_SUPPORT: "Broker Podpora",
    BROKER_ACCOUNTANT: "Broker Účtovník",
    BROKER_DISPATCHER: "Broker Dispečer",
    //Taxiservice
    OWNER: "Vlastník",
    MANAGER: "Manažér",
    ACCOUNTANT: "Účtovník",
    MAINTAINER: "Údržbár",
    DISPATCHER: "Dispečer",
  },
  DispatcherRoleArray: [
    {
      "text": "Admin",
      "value": "ADMIN",
    },
    {
      "text": "Podpora",
      "value": "SUPPORT",
    },
    /* {
      "text": "Broker Manažér",
      "value": "BROKER_MANAGER",
    },
    {
      "text": "Broker Podpora",
      "value": "BROKER_SUPPORT",
    },
    {
      "text": "Broker Účtovník",
      "value": "BROKER_ACCOUNTANT",
    },
    {
      "text": "Broker Dispečer",
      "value": "BROKER_DISPATCHER",
    }, */
    {
      "text": "Vlastník",
      "value": "OWNER",
    },
    {
      "text": "Manažér",
      "value": "MANAGER",
    },
    {
      "text": "Účtovník",
      "value": "ACCOUNTANT",
    },
    {
      "text": "Údržbár vozidiel",
      "value": "MAINTAINER",
    },
    {
      "text": "Dispečer",
      "value": "DISPATCHER",
    },
  ],
  DispatcherRoleForBrokerArray: [
    {
      "text": "Broker Podpora",
      "value": "BROKER_SUPPORT",
    },
    {
      "text": "Broker Účtovník",
      "value": "BROKER_ACCOUNTANT",
    },
    {
      "text": "Broker Dispečer",
      "value": "BROKER_DISPATCHER",
    },
    {
      "text": "Manažér",
      "value": "MANAGER",
    },
    {
      "text": "Účtovník",
      "value": "ACCOUNTANT",
    },
    {
      "text": "Údržbár vozidiel",
      "value": "MAINTAINER",
    },
    {
      "text": "Dispečer",
      "value": "DISPATCHER",
    },
  ],
  DispatcherRoleForOwnerArray: [
    {
      "text": "Manažér",
      "value": "MANAGER",
    },
    {
      "text": "Účtovník",
      "value": "ACCOUNTANT",
    },
    {
      "text": "Údržbár",
      "value": "MAINTAINER",
    },
    {
      "text": "Dispečer",
      "value": "DISPATCHER",
    },
  ],

  LocalityList: {
    page_title: "Zoznam lokalít",
    tabLocalityPickup: "Vyzdvihnutie",
    tabLocalityPrice: "Cenové",
    tabLocalityCheckin: "Prihlasovacie",
    tabStands: "Stanovištia",
    activeOnlySwitch: "Zobraziť len aktívne",
    btnCreateCircle: "Vytvoriť kruh",
    btnCreatePolygon: "Vytvoriť polygón",
    dialogTitle: "Lokalita",
    id: "ID",
    priority: "Priorita",
    name: "Názov",
    active: "Aktívny",
    type: "Typ",
    latitude: "Zemepisná šírka",
    longitude: "Zemepisná dĺžka",
    radius: "Polomer",
    path: "Trasa",
    distance: "Vzdialenosť",
    capacity: "Kapacita",
    defaultStand: "Hlavné stanovište",
    showPhone: "Vodičovi zobraziť telefónne číslo zákazníka",
    showDriverPhoneButton: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses: "Vodičovi zobraziť všetky adresy",
    onlyManualSending: "Iba manuálne zaslanie objednávky",
    forcedPickupRadius: "Vynútenie vyzdvihnutia v okruhu (0 = vypnuté)",
    standId: "Stanovište",
    internalName: "Interný názov",
    city: "Mesto",
    country: "Krajina",
    street1: "Ulica",
    street2: "Ulica (2)",
    msgMax10Points: "Maximálny počet bodov v polygóne je 10. Prosím, nepridávajte viac bodov, inak systém nebude fungovať správne.",
    msgMax10PointsOver: "Príliš veľa bodov!!! Maximálny počet bodov v polygóne je 10. Prosím, odstráňte pomocou pravého tlačidla nepotrebné body, inak systém nebude fungovať správne.",
    editPolygonHelpText: "V polygóne je možné mať maximálne 10 bodov. Ak chcete pridať nový bod, kliknite a presuňte manipulačný bod, ktorý sa nachádza v strede medzi existujúcimi bodmi. Ak chcete odstrániť nepotrebný bod, kliknite naňho pravým tlačidlom myši.",
    msgErrorToEditLocality: "Chyba pri editácii lokality",
    msgDataMissing: "Chýbajúce údaje.",
    msgDefaultStandNotSet: "Hlavné stanovište nie je nastavené!",
    msgDefaultStandMoreThenOne: "Hlavné stanovište je viac ako jedno!",
    tableHeaders: {
      actions: "Akcie",
      id: "ID",
      priority: "Priorita",
      name: "Názov",
      active: "Aktívny",
      type: "Typ",
      latitude: "Zem. šírka",
      longitude: "Zem. dĺžka",
      radius: "Polomer",
      path: "Trasa",
      distance: "Vzdialenosť",
      showDriverPhoneButton: "Tl. telefon",
      showPhone: "Telefón",
      showAllAddresses: "Adresy",
      onlyManualSending: "Manuálne zaslanie",
      forcedPickupRadius: "Vynútený polomer",
      standId: "Stanovište",
      capacity: "Kapacita",
      defaultStand: "Hlavné",
    },
    showMarkers: "Zobraziť značky",
    editPolygonPointsTitle: "Upraviť body polygónu",
    pathLatitude: "Zemepisná šírka",
    pathLongitude: "Zemepisná dĺžka",
    closePathChange: "Upraviť bod podľa blízkeho bodu iného polygónu",
  },

  PricelistList: {
    page_title: "Zoznam cenníkov",
    newPricelistDialogTitle: "Nový cenník",
    name: "Názov",
    validFrom: "Platný od",
    service: "Služba",
    tableHeaders: {
      actions: "Akcie",
      name: "Názov",
      active: "Aktívny",
      validFrom: "Platný od",
      service: "Služba",
      currency: "Mena",
    },
  },
  Pricelist: {
    page_title: "Cenník",
    tabBasic: "Základné informácie",
    tabPriceSettings: "Nastavenia cien",
    tabPrices: "Cenové položky",
    tabExtras: "Príplatky",
    //TAB BASIC
    id: "ID",
    name: "Názov",
    note: "Poznámka",
    active: "Aktívny",
    validFrom: "Platný od",
    service: "Služba",
    showPrice: "Zobraziť cenu",
    currency: "Mena",
    currencySymbol: "Symbol meny",
    priceRound: "Zaokrúhľovanie ceny",
    //TAB PRICE SETTINGS
    fareType: "Typ sadzby",
    fareChangeToDefault: "Zmena sadzby na Default",
    fixation: "Fixácia",
    waypointInProgressRecalcPrice: "Medzizastávka - prepočet ceny počas jazdy",
    fareStartSubheader: "Cena za vyzdvihnutie zákazníka",
    fareStartType: "Typ sadzby za vyzdvihnutie zákazníka",
    fareStart: "Sazba za vyzdvihnutie zákazníka",
    fareWaitingSubheader: "Čakanie na zákazníka pred začatím prepravy",
    fareWaitingLimit: "Časový limit čakania (min.)",
    fareWaiting: "Sadzba za čakanie",
    fareDriveSubheader: "Cena za prepravu",
    fareDriveType: "Typ sadzby za prepravu",
    fareDriveDistance: "Sadzba za vzdialenosť prepravy",
    fareDriveTime: "Sadzba za čas prepravy",
    fareJourneyMinimumSubheader: "Minimálna cena za jazdu",
    fareJourneyMinimumType: "Typ minimálnej ceny za jazdu",
    fareJourneyMinimum: "Minimálna cena za jazdu",
  },
  PricelistEnums: {
    showPrice: {
      ALL: "Zobraziť všetkým",
      ONLY_DRIVER: "Zobraziť iba vodičovi",
      HIDDEN: "Nezobraziť nikomu",
    },
    showPriceArray: [
      { "text": "Zobraziť všetkým", "value": "ALL", },
      { "text": "Zobraziť iba vodičovi", "value": "ONLY_DRIVER", },
      { "text": "Nezobraziť nikomu", "value": "HIDDEN", },
    ],

    FareType: {
      DEFAULT: "Defaultné sadzby",
      LOCALITY: "Sadzby podľa lokality",
    },
    FareTypeArray:
      [
        { "text": "Defaultné sadzby", "value": "DEFAULT", },
        { "text": "Sadzby podľa lokality", "value": "LOCALITY", },
      ],

    FareJourneyMinimumType: {
      DEFAULT: "Defaultná minimálna cena",
      LOCALITY: "Minimálna cena podľa lokality",
    },
    FareJourneyMinimumTypeArray:
      [
        { "text": "Defaultná minimálna cena", "value": "DEFAULT", },
        { "text": "Minimálna cena podľa lokality", "value": "LOCALITY", },
      ],

    FareDriveType: {
      FIXED: "Pevná cena",
      DISTANCE: "Vzdialenosť a čas",
    },
    FareDriveTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Vzdialenosť a čas", "value": "DISTANCE", },
      ],

    FareStartType: {
      FIXED: "FIXED",
      FROM_STAND: "FROM_STAND",
      TO_STAND: "TO_STAND",
      BETWEEN_STAND: "BETWEEN_STAND",
    },
    FareStartTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Stanovište->Vyzdvihnutie", "value": "FROM_STAND", },
        { "text": "Cieľ->Stanovište", "value": "TO_STAND", },
        { "text": "Stanovište->Vyzdvihnutie, Cieľ->Stanovište", "value": "BETWEEN_STAND", },
      ],

    FareChangeToDefault: {
      NONE: "NONE",
      WAITING: "Čakačka",
      WAYPOINT: "Medzizastávka",
      ACCOUNT: "Firemná jazda",
      WAITING_WAYPOINT: "Čakačka a Medzizastávka",
      WAITING_ACCOUNT: "Čakačka a firemná jazda",
      WAYPOINT_ACCOUNT: "Medzizastávka a firemná jazda",
      WAITING_WAYPOINT_ACCOUNT: "Čakačka, Medzizastávka a firemná jazda",
    },
    FareChangeToDefaultArray:
      [
        { "text": "Žiadna", "value": "NONE", },
        { "text": "Čakačka", "value": "WAITING", },
        { "text": "Medzizastávka", "value": "WAYPOINT", },
        { "text": "Firemná jazda", "value": "ACCOUNT", },
        { "text": "Čakačka a Medzizastávka", "value": "WAITING_WAYPOINT", },
        { "text": "Čakačka a firemná jazda", "value": "WAITING_ACCOUNT", },
        { "text": "Medzizastávka a firemná jazda", "value": "WAYPOINT_ACCOUNT", },
        { "text": "Čakačka, Medzizastávka a firemná jazda", "value": "WAITING_WAYPOINT_ACCOUNT", },
      ],

    Service: {
      PERSONAL: "Osobná preprava",
      LIMO: "Limuzínová preprava",
      CARGO: "Nákladná preprava",
      DRINK: "Drinktaxi služba",
      IMMOBILE: "Preprava imobilných osôb",
      COURIER: "Kuriérska služba",
      DELIVERY: "Doručovacia služba",
      AIRPORT: "Letiskové taxi",
      SOCIAL: "Sociálne taxi",
    },
    ServiceArray:
      [
        { "text": "Osobná preprava", "value": "PERSONAL", },
        { "text": "Limuzínová preprava", "value": "LIMO", },
        { "text": "Nákladná preprava", "value": "CARGO", },
        { "text": "Drinktaxi služba", "value": "DRINK", },
        { "text": "Preprava imobilných osôb", "value": "IMMOBILE", },
        { "text": "Kuriérska služba", "value": "COURIER", },
        { "text": "Doručovacia služba", "value": "DELIVERY", },
        { "text": "Letiskové taxi", "value": "AIRPORT", },
        { "text": "Sociálne taxi", "value": "SOCIAL", },
      ],
  },
  Prices: {
    btnGenerate: "Generovať ceny",
    btnRegenerate: "Pregenerovať ceny",
    btnSave: "Uložiť",
    btnCancel: "Zrušiť",
    btnEdit: "Upraviť",
    activeOnlySwitch: "Iba aktívne",
    generatePricesDialogTitle: "Prajete si pregenerovať ceny?",
    generatePricesDialogText: "Pri pregenerovaní cien sa vytvoria cenové položky zo všetkých kombinácií Cenových lokalít. Ak bola niektorá lokalita deaktivovaná alebo pridaná, adekvátne som sa zablokujú alebo pridajú cenové položky. Odporúčame pregenerovať ceny pri každej zmene v Cenových lokalitách.",
    msgGeneratePricesSuccess: "Ceny boli úspešne pregenerované.",
    msgGeneratePricesError: "Pri pregenerovaní cien nastala chyba.",
    msgUpdatePricesSuccess: "Ceny boli úspešne aktualizované.",
    msgUpdatePricesError: "Pri aktualizovaní cien nastala chyba.",
    tableHeaders: {
      name: "Názov",
      active: "Aktívny",
      fareStartType: "Typ ceny za vyzdvihnutie",
      fareStart: "Cena za vyzdvihnutie",
      fareWaitingLimit: "Limit čakania pred prepravou",
      fareWaiting: "Cena za čakanie pred prepravou",
      fareDriveType: "Typ ceny za prepravu",
      fareDriveDistance: "Cena za vzdialenosť",
      fareDriveTime: "Cena za čas",
      fareJourneyMinimum: "Minimálna cena za jazdu",
      note: "Poznámka",
      localityPriceFromId: "Z",
      localityPriceToId: "Do",
    },
  },
  PricesEnums: {
    FareStartType: {
      FIXED: "Pevná cena", // Pevná suma
      FROM_STAND: "Cena za vzdialenosť Stanovište->Vyzdvihnutie", // PRÍSTAVNÉ obsahuje jazdu zo Stanovišťa
      TO_STAND: "Cena za vzdialenosť Cieľ->Stanovište", // PRÍSTAVNÉ obsahuje jazdu na Stanovište
      BETWEEN_STAND: "Cena za vzdialenosť Stanovište->Vyzdvihnutie, Cieľ->Stanovište", // PRÍSTAVNÉ obsahuje jazdu z aj na Stanovište
    },
    FareStartTypeShort: {
      FIXED: "Pevná", // Pevná suma
      FROM_STAND: "S>V", // PRÍSTAVNÉ obsahuje jazdu zo Stanovišťa
      TO_STAND: "C>S", // PRÍSTAVNÉ obsahuje jazdu na Stanovište
      BETWEEN_STAND: "S>V|C>S", // PRÍSTAVNÉ obsahuje jazdu z aj na Stanovište
    },
    FareStartTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Stanovište->Vyzdvihnutie", "value": "FROM_STAND", },
        { "text": "Cieľ->Stanovište", "value": "TO_STAND", },
        { "text": "Stanovište->Vyzdvihnutie, Cieľ->Stanovište", "value": "BETWEEN_STAND", },
      ],
    FareDriveType: {
      FIXED: "Pevná cena",
      DISTANCE: "Cena za vzdialenosť a čas prepravy",
    },
    FareDriveTypeShort: {
      FIXED: "Pevná",
      DISTANCE: "Vzdialenosť",
    },
    FareDriveTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Vzdialenosť a čas", "value": "DISTANCE", },
      ],
  },
  PriceExtra: {
    btnCreate: "Vytvoriť",
    btnCreateObligatory: "Vytvoriť povinnú položku",
    priceExtraDialogTitle: "Príplatok",
    priceExtraObligatoryDialogTitle: "Povinná položka cenníka",
    name: "Názov",
    summaryOrAutomatic: "Manuálny / Automaticky",
    driverSummary: "Pridávaný vodičom",
    automatic: "Vytváraný automaticky",
    active: "Aktívny",
    type: "Typ",
    price: "Cena/hodnota",
    operation: "Spôsob výpočtu ceny príplatku",
    resetToDefaultPrice: "Resetuje na defaultnú cenu",
    cancelPriceFixation: "Zruší fixáciu ceny",
    editable: "Editovateľný",
    occurenceLimit: "Maximálny počet vytvorených príplatkov",
    ordering: "Poradie v prehľade vodiča",
    closeBtn: "Zavrieť",
    saveBtn: "Uložiť",
    msgSaveSuccess: "Príplatok bol úspešne uložený",
    msgSaveError: "Príplatok sa nepodarilo uložiť",
    msgGetDataError: "Och, chyba.... Niečo sa pokazilo pri načítaní dát",
    msgObligatoryPriceExtraWarning: "Vytvor najprv povinné položky 'Cena za jazdu' (PRICE_JOURNEY) a 'Cena za čakanie pred jazdou' (PRICE_WAITING)!",
    tableHeaders: {
      actions: "Akcie",
      name: "Názov",
      active: "Aktívny",
      type: "Typ",
      price: "Cena",
      resetToDefaultPrice: "Resetuje na default",
      cancelPriceFixation: "Zruší fixáciu",
      operation: "Výpočet",
      automatic: "Automaticky",
      driverSummary: "Sumarizácia",
      editable: "Editovateľný",
      occurenceLimit: "Max. výskytov",
      ordering: "Poradie",
    },
  },
  PriceExtraEnums: {
    Operation: {
      NONE: "Nezadaný", 	// Nezadaný (0)
      ONE_OFF: "Jednorázový", 	// Jednorázový (1)
      PERC_PRICE_DRIVE: "Percento k cene prepravy", 	//Percento k cene prepravy (2)  ==> premenovať z PERC_TRANSFER na PERC_PRICE_DRIVE
      PERC_PRICE_JOURNEY: "Percento k sumárnej cene", 	// Percento k sumárnej cene (3) ==> premenovať z PERC_SUMMARY na PERC_PRICE_JOURNEY
      DRIVE_TIME: "Za minútu jazdy", 	// Za minútu jazdy (4)
      DRIVE_DISTANCE: "Za kilometer jazdy", 	// Za kilometer jazdy (5)
      WAITING_TIME: "Za min. čakania pri preprave", 	// Za minútu čakania v stave 6 - za kazdu zacatu minutu (6)
      WAITING_TIME_EXACT: "Za min. čakania pri preprave po sek.", 	// Za minútu čakania v stave 6 presne na sekundy, resp. min 3 desatinne miesta(6)
      //WAITING_PICKUP_TIME: "WAITING_PICKUP_TIME", 	// Za minútu čakania v stave 5 (5)
    },
    OperationArray:
      [
        { "text": "Nezadaný", "value": "NONE", },
        { "text": "Jednorázový", "value": "ONE_OFF", },
        { "text": "Percento k cene prepravy", "value": "PERC_PRICE_DRIVE", },
        { "text": "Percento k sumárnej cene", "value": "PERC_PRICE_JOURNEY", },
        { "text": "Za minútu jazdy", "value": "DRIVE_TIME", },
        { "text": "Za kilometer jazdy", "value": "DRIVE_DISTANCE", },
        { "text": "Za minútu čakania počas prepravy", "value": "WAITING_TIME", },
        { "text": "Za minútu čakania počas prepravy presne na sekundy", "value": "WAITING_TIME_EXACT", },
        //{ "text": "Za minútu čakania v stave 5", "value": "WAITING_PICKUP_TIME", },
      ],
    Type: {
      //Prve 2 su obligatorne, a v arrany sa nachadzaju samostatne, lebo iné dialogove okno
      PRICE_WAITING: "Cena za čakanie pred jazdou (P)",
      PRICE_JOURNEY: "Cena za jazdu (P)",
      NONE: "Nezadaný", 	// Nezadaný (0)
      GENERAL: "Všeobecný poplatok", 	// Všeobecný poplatok (1)
      TYPE_INSTANT: "Objednávka okamžite (A)", 	// Objednávka okamžite (2)
      TYPE_TIME: "Objednávka na čas (A)",
      TYPE_STREET: "Objednávka z ulice (A)",
      //TYPE_DIRECT: "Objednávka priama (A)",
      //TYPE_REPEATED: "Objednávka opakovaná (A)",
      //TYPE_AIRPORT: "Objednávka letisko (A)",
      WAITING: "Čakačka (A)", 	// Poplatok za čakanie (2)
      PREORDER: "Predobjednávka", 	// Objednávka na čas (3)
      NIGHT: "Nočný príplatok", 	// Nočný príplatok (4)
      WEEKEND: "Víkendový príplatok", 	// Víkendový príplatok (5)
      OFF_TIME: "Nočný/víkendový príplatok", 	// Nočný/víkendový príplatok (6)
      AIRPORT_TRANSPORT: "Letiskový príplatok", 	// Letiskový príplatok (7)
      STATION_TRANSPORT: "Staničný príplatok", 	// Staničný príplatok (8)
      ABROAD_TRANSPORT: "Zahraničný príplatok", 	// Zahraničný príplatok (9)
      PICKUP: "Poplatok za vyzdvihnutie", 	// Poplatok za vyzdvihnutie 
      PICKUP_CITY: "Vyzdvihnutie v meste", 	// Vyzdvihnutie v meste (10)
      PICKUP_OUT_CITY: "Vyzdvihnutie mimo mesta", 	// Vyzdvihnutie mimo mesta (11)
      DRINK_TRANSPORT: "Príplatok za prepravu vozidla zákazníka", 	// Príplatok za prepravu vozidla zákazníka
      LUGGAGE_SMALL: "Batožina malá (A)", 	// Malá batožina (12)
      LUGGAGE_MEDIUM: "Batožina stredná (A)", 	// Stredná batožina (12)
      LUGGAGE_BIG: "Batožina veľká (A)", 	// Veľká batožina (12)
      PAYMENT_TERMINAL: "Platobný terminál (A)", 	// Platobný terminál vo vozidle
      SPECIAL_TRANSPORT_OLD: "Preprava starších ľudí (A)", 	// Preprava starších ľudí
      SPECIAL_TRANSPORT_CHAIR: "Preprava ľudí na vozíčku (A)", 	// Preprava ľudí na vozíčku 
      AIRCONDITION: "Klimatizácia", 	// Klimatizácia (14)
      BOOSTER: "Detský podsedák (A)", 	// Detský podsedák (15)
      SEAT: "Detská sedačka (A)", 	// Detská sedačka (16)
      ANIMAL_SMALL: "Zviera malé (A)", 	// Preprava zvieraťa (17)
      ANIMAL_MEDIUM: "Zviera stredné (A)", 	// Preprava zvieraťa (17)
      ANIMAL_BIG: "Zviera veľké (A)", 	// Preprava zvieraťa (17)
      PREFERRED_DRIVER: "Preferovaný šofér (A)", 	// Preferovaný šofér (18)
      FOULING: "Znečistenie", 	// Znečistenie (18)
      STAINING: "Veľké znečistenie", 	// Veľké znečistenie (19)
      /*VEHICLE_SEDAN: "Sedan", 	// Sedan (21)
      VEHICLE_COMBI: "Combi", 	// Combi (22)
      VEHICLE_CABRIO: "Cabrio", 	// Cabrio (23)
      VEHICLE_COUPE: "Coupé", 	// Coupé (24)
      VEHICLE_HATCHBACK: "Hatchback", 	// Hatchback (25)
      VEHICLE_VAN: "Van", 	// Van (26)
      VEHICLE_MICROBUS: "Microbus", 	// Microbus (27)
      VEHICLE_BUS: "Bus", 	// Bus (28)
      VEHICLE_VAN_CARGO: "Dodávka", 	// Dodávka (29)
      VEHICLE_PICKUP: "Pickup", 	// Pickup (30)
      VEHICLE_SUV: "SUV", 	// SUV (31)
      */
      PERSONS_2: "Požadovaný počet osôb 2 (A)", 	// Maximálny požadovaný počet osôb 2 (32)
      PERSONS_3: "Požadovaný počet osôb 3 (A)", 	// Maximálny požadovaný počet osôb 3 (32)
      PERSONS_4: "Požadovaný počet osôb 4 (A)", 	// Maximálny požadovaný počet osôb 4 (32)
      PERSONS_5: "Požadovaný počet osôb 5 (A)", 	// Maximálny požadovaný počet osôb 5 (32)
      PERSONS_6: "Požadovaný počet osôb 6 (A)", 	// Maximálny požadovaný počet osôb 6 (32)
      PERSONS_7: "Požadovaný počet osôb 7 (A)", 	// Maximálny požadovaný počet osôb 7 (32)
      PERSONS_8: "Požadovaný počet osôb 8 (A)", 	// Maximálny požadovaný počet osôb 8 (32)
      SERVICE_PERSONAL: "Služba osobnej prepravy (A)", 	// Osobná preprava (41)
      //SERVICE_LIMO: "Služba limuzínovej prepravy (A)", 	// Limuzínová preprava (42)
      //SERVICE_CARGO: "Služba nákladnej prepravy (A)", 	// Nákladná preprava (43)
      //SERVICE_DRINK: "Služba Drink taxi (A)", 	// Drink taxi (44)
      //SERVICE_IMMOBILE: "Služba prepravy imobilných (A)", 	// Preprava imobilných (45)
      //SERVICE_COURIER: "Kuriérska služba (A)", 	// Kurierská služba (46)
      //SERVICE_DELIVERY: "Donášková služba (A)", 	// Donášková služba (47)
      //SERVICE_AIRPORT: "Služba prepravy na letisko (A)", 	// Letiskové taxi (48)
      //SERVICE_SOCIAL: "Služba sociálneho taxi (A)", 	// Sociálne taxi (49)
      PAYMENT_CASH: "Platba v hotovosti (A)", 	// Hotovosť (51)
      PAYMENT_CARD: "Platba kartou vo vozidle (A)", 	// Karta vo vozidle (52)
      //PAYMENT_VIAMO: "Platba cez VIAMO (A)", 	// Viamo (53)
      //PAYMENT_COUPON: "Platba kupónom (A)", 	// Kupón (54)
      PAYMENT_CARD_ONLINE: "Platba kartou online (A)", 	// Karta online (55)
      //PAYMENT_PREPAID: "Platba z kreditu (A)", 	// Preplatená jazda (56)
      PAYMENT_POSTPAID: "Platba po jazde/postpaid/faktúra (A)", 	// Platba po jazde/postpaid (57)
      //PAYMENT_INVOICE: "Platba na faktúru (A)", 	// Platba na faktúru (58)
      PICKUP_PLACE_CHANGED: "Zmena miesta vyzdvihnutia",
      DESTINATION_PLACE_CHANGED: "Zmena cieľa cesty",
      WAYPOINT: "Medzizastávka/Prejazdový bod (A)", //AUTOMATICALLY ADDED
      WAYPOINT_STOP: "Medzizastávka", 	// Zastávka 
      //WAYPOINT_INFO: "WAYPOINT_INFO", 	// Informácia o cene za medzizastávky (0)
      WAYPOINT_DISTRICT: "Medzizastávka vo štvrti", 	// Extra zastávka vo štvrti (61)
      WAYPOINT_CITY: "Medzizastávka v meste", 	// Extra zastávka v meste (62)
      BATTERY: "Nabitie batérie", 	// Nabitie batérie (67)
      FUEL: "Poskytnutie paliva", 	// Poskytnutie paliva (68)
      DRAG_VEHICLE: "Odtiahnutie vozidla", 	// Odtiahnutie vozidla (69)
      REGISTRATION: "Registrácia", 	// Registrácia (71)
      BLOCKING: "Zablokovanie", 	// Zablokovanie (72)
      UNBLOCKING: "Odblokovanie", 	// Odblokovanie (73)
      SOURCE_DISPATCH_TAXISERVICE: "Zdroj dispečing (A)", 	// Objednávka z dispečingu (74)
      //SOURCE_DISPATCH_BROKER: "Zdroj dispečing brokera (A)", 	// Objednávka z dispečingu (74)
      //SOURCE_DISPATCH_TAXXIMO: "Zdroj dispečing taxximo (A)", 	// Objednávka z dispečingu (74)
      SOURCE_DRIVER: "Zdroj vodič (A)", 	// Objednávka od vodiča z ulice (75)
      SOURCE_APP: "Zdroj appka zákazníka (A)", 	// Objednávka z aplikácie zákazníka (76)
      SOURCE_BRANDAPP: "Zdroj brandovaná appka zákazníka (A)", 	// Objednávka od brandovanej aplikácie zákazníka (77)
      ACCOUNT: "Firemná jazda", 	// Zmluvná/firemný jazda (78)
      //STORNO_PREREGISTERED: "Storno predregistrovanej objednávky", 	 	// STORNO PREREGISTERED -> Odregistrovaná/Unregistered (88)
      //STORNO_NEW: "Storno novej objednávky", 	 	// STORNO Nová -> Nerealizovaná/Expired  (81)
      //STORNO_PLANNED: "Storno plánovanej objednávky", 	 	// STORNO Plánovaná -> Odvolaná/Revoked  (82)
      //STORNO_PENDING: "Storno čakajúcej objednávky", 	 	// STORNO Čakajúca -> Odmietnutá/Declined  (83)
      //STORNO_ACCEPTED: "Storno akceptovanej objednávky", 	 	// STORNO Akcpetovaná -> Zrušená/Canceled  (84)
      //STORNO_WAITING: "Storno objednávky na mieste", 	 	// STORNO Na mieste -> Zamietnutá/Dismissed  (85)
      //STORNO_IN_PROGRESS: "Storno prepravovanej objednávky", 	 	// STORNO Realizovaná -> Nedokončená/Unfinished  (86)
      //STORNO_FINISHED: "Storno ukončenej objednávky", 	 	// STORNO Ukončená -> Nenaúčtovaná/Uncharged  (87)
      CUSTOMER_NEW: "Zákazník nový (A)", 	// Nový zákazník
      CUSTOMER_REGULAR: "Zákazník stály (A)", 	// Stály zákazník
      CUSTOMER_PRIORITY: "Zákazník prioritný (A)", 	// Prioritný zákazník
      CUSTOMER_FAMOUS: "Zákazník známa osobnosť (A)", 	// Známa osobnosť
      CUSTOMER_VIP: "Zákazník VIP (A)", 	// VIP klient
      CUSTOMER_COLLEAGUE: "Zákazník je kolega (A)", 	// Kolega z taxislužby
      CUSTOMER_STUDENT: "Zákazník študent (A)", 	// Študent
      CUSTOMER_CHILD: "Zákazník dieťa (A)", 	// Dieťa
      CUSTOMER_JUNIOR: "Zákazník junior (A)", 	// Junior
      CUSTOMER_SENIOR: "Zákazník Senior (A)", 	// Senior
      CUSTOMER_DEBTOR: "Zákazník je dlžník (A)", 	// Dlžník
      CUSTOMER_BLOCKED: "Zákazník je blokovaný (A)", 	// Na zozname zakázaných zákazníkov
      SOURCE_BRANDAPP_FIRST_TWO_ORDERS: "Zdroj appka zákazníka max.2 obj (A)", 	// Objednávka od brandovanej aplikácie zákazníka (77)
      SOURCE_BRANDAPP_EVERY_FIVE_ORDERS: "Zdroj appka zákazníka každých 5 obj (A)", 	// Objednávka od brandovanej aplikácie zákazníka (77)
      SHOPPING: "Nákup"
    },
    TypeArray: [
      { "text": "Nezadaný", "value": "NONE", },
      { "text": "Všeobecný poplatok", "value": "GENERAL", },
      { "text": "Objednávka okamžite (A)", "value": "TYPE_INSTANT", },
      { "text": "Objednávka na čas (A)", "value": "TYPE_TIME", },
      { "text": "Objednávka z ulice (A)", "value": "TYPE_STREET", },
      //{ "text": "Objednávka priama (A)", "value": "TYPE_DIRECT", },
      //{ "text": "Objednávka opakovaná (A)", "value": "TYPE_REPEATED", },
      //{ "text": "Objednávka letisko (A)", "value": "TYPE_AIRPORT", },
      { "text": "Čakačka (A)", "value": "WAITING", },
      { "text": "Predobjednávka", "value": "PREORDER", },
      { "text": "Nočný príplatok", "value": "NIGHT", },
      { "text": "Víkendový príplatok", "value": "WEEKEND", },
      { "text": "Nočný/víkendový príplatok", "value": "OFF_TIME", },
      { "text": "Letiskový príplatok", "value": "AIRPORT_TRANSPORT", },
      { "text": "Staničný príplatok", "value": "STATION_TRANSPORT", },
      { "text": "Zahraničný príplatok", "value": "ABROAD_TRANSPORT", },
      { "text": "Poplatok za vyzdvihnutie", "value": "PICKUP", },
      { "text": "Vyzdvihnutie v meste", "value": "PICKUP_CITY", },
      { "text": "Vyzdvihnutie mimo mesta", "value": "PICKUP_OUT_CITY", },
      { "text": "Príplatok za prepravu vozidla zákazníka (Drink taxi)", "value": "DRINK_TRANSPORT", },
      { "text": "Batožina malá (A)", "value": "LUGGAGE_SMALL", },
      { "text": "Batožina stredná (A)", "value": "LUGGAGE_MEDIUM", },
      { "text": "Batožina veľká (A)", "value": "LUGGAGE_BIG", },
      { "text": "Platobný terminál (A)", "value": "PAYMENT_TERMINAL", },
      { "text": "Preprava starších ľudí (A)", "value": "SPECIAL_TRANSPORT_OLD", },
      { "text": "Preprava ľudí na vozíčku (A)", "value": "SPECIAL_TRANSPORT_CHAIR", },
      { "text": "Klimatizácia", "value": "AIRCONDITION", },
      { "text": "Detský podsedák (A)", "value": "BOOSTER", },
      { "text": "Detská sedačka (A)", "value": "SEAT", },
      { "text": "Zviera malé (A)", "value": "ANIMAL_SMALL", },
      { "text": "Zviera stredné (A)", "value": "ANIMAL_MEDIUM", },
      { "text": "Zviera veľké (A)", "value": "ANIMAL_BIG", },
      { "text": "Preferovaný šofér (A)", "value": "PREFERRED_DRIVER", },
      { "text": "Znečistenie", "value": "FOULING", },
      { "text": "Veľké znečistenie", "value": "STAINING", },
      /*{ "text": "Sedan", "value": "VEHICLE_SEDAN", }, 	//  (21)
      { "text": "Combi", "value": "VEHICLE_COMBI", }, 	//  (22)
      { "text": "Cabrio", "value": "VEHICLE_CABRIO", }, 	//  (23)
      { "text": "Coupé", "value": "VEHICLE_COUPE", }, 	//  (24)
      { "text": "Hatchback", "value": "VEHICLE_HATCHBACK", }, 	//  (25)
      { "text": "Van", "value": "VEHICLE_VAN", }, 	//  (26)
      { "text": "Microbus", "value": "VEHICLE_MICROBUS", }, 	//  (27)
      { "text": "Bus", "value": "VEHICLE_BUS", }, 	//  (28)
      { "text": "Dodávka", "value": "VEHICLE_VAN_CARGO", }, 	//  (29)
      { "text": "Pickup", "value": "VEHICLE_PICKUP", }, 	//  (30)
      { "text": "SUV", "value": "VEHICLE_SUV", }, 	//  (31)
      */
      { "text": "Požadovaný počet osôb 2 (A)", "value": "PERSONS_2", },
      { "text": "Požadovaný počet osôb 3 (A)", "value": "PERSONS_3", },
      { "text": "Požadovaný počet osôb 4 (A)", "value": "PERSONS_4", },
      { "text": "Požadovaný počet osôb 5 (A)", "value": "PERSONS_5", },
      { "text": "Požadovaný počet osôb 6 (A)", "value": "PERSONS_6", },
      { "text": "Požadovaný počet osôb 7 (A)", "value": "PERSONS_7", },
      { "text": "Požadovaný počet osôb 8 (A)", "value": "PERSONS_8", },
      { "text": "Služba osobnej prepravy (A)", "value": "SERVICE_PERSONAL", },
      //{ "text": "Služba limuzínovej prepravy (A)", "value": "SERVICE_LIMO", }, 	
      //{ "text": "Služba nákladnej prepravy (A)", "value": "SERVICE_CARGO", }, 
      //{ "text": "Služba Drink taxi (A)", "value": "SERVICE_DRINK", }, 	
      //{ "text": "Služba prepravy imobilných (A)", "value": "SERVICE_IMMOBILE", }, 	
      //{ "text": "Kuriérska služba (A)", "value": "SERVICE_COURIER", }, 
      //{ "text": "Donášková služba (A)", "value": "SERVICE_DELIVERY", }, 
      //{ "text": "Služba prepravy na letisko (A)", "value": "SERVICE_AIRPORT", }, 
      //{ "text": "Služba sociálneho taxi (A)", "value": "SERVICE_SOCIAL", }, 
      { "text": "Platba v hotovosti (A)", "value": "PAYMENT_CASH", },
      { "text": "Platba kartou vo vozidle (A)", "value": "PAYMENT_CARD", },
      //{ "text": "Platba cez VIAMO (A)", "value": "PAYMENT_VIAMO", },
      //{ "text": "Platba kupónom (A)", "value": "PAYMENT_COUPON", }, 
      { "text": "Platba kartou online (A)", "value": "PAYMENT_CARD_ONLINE", },
      //{ "text": "Platba z kreditu (A)", "value": "PAYMENT_PREPAID", }, 
      { "text": "Platba po jazde/postpaid/faktúra (A)", "value": "PAYMENT_POSTPAID", },
      //{ "text": "Platba na faktúru (A)", "value": "PAYMENT_INVOICE", }, 	
      { "text": "Zmena miesta vyzdvihnutia", "value": "PICKUP_PLACE_CHANGED", },
      { "text": "Zmena cieľa cesty", "value": "DESTINATION_PLACE_CHANGED", },
      { "text": "Medzizastávka/Prejazdový bod (A)", "value": "WAYPOINT", },
      { "text": "Medzizastávka", "value": "WAYPOINT_STOP", }, 	// Zastávka 
      //{ "text": "WAYPOINT_INFO", "value": "WAYPOINT_INFO", }, 	
      { "text": "Medzizastávka vo štvrti", "value": "WAYPOINT_DISTRICT", },
      { "text": "Medzizastávka v meste", "value": "WAYPOINT_CITY", },
      { "text": "Nabitie batérie", "value": "BATTERY", },
      { "text": "Poskytnutie paliva", "value": "FUEL", },
      { "text": "Odtiahnutie vozidla", "value": "DRAG_VEHICLE", },
      { "text": "Registrácia", "value": "REGISTRATION", },
      { "text": "Zablokovanie", "value": "BLOCKING", },
      { "text": "Odblokovanie", "value": "UNBLOCKING", },
      { "text": "Zdroj dispečing (A)", "value": "SOURCE_DISPATCH_TAXISERVICE", },
      //{ "text": "Zdroj dispečing brokera (A)", "value": "SOURCE_DISPATCH_BROKER", }, 
      //{ "text": "Zdroj dispečing taxximo (A)", "value": "SOURCE_DISPATCH_TAXXIMO", }, 
      { "text": "Zdroj vodič (A)", "value": "SOURCE_DRIVER", },
      { "text": "Zdroj appka zákazníka (A)", "value": "SOURCE_APP", },
      { "text": "Zdroj brandovaná appka zákazníka (A)", "value": "SOURCE_BRANDAPP", },
      { "text": "Firemná jazda", "value": "ACCOUNT", },
      //{ "text": "Storno predregistrovanej objednávky", "value": "STORNO_PREREGISTERED", }, 	 
      //{ "text": "Storno novej objednávky", "value": "STORNO_NEW", }, 	 	
      //{ "text": "Storno plánovanej objednávky", "value": "STORNO_PLANNED", }, 
      //{ "text": "Storno čakajúcej objednávky", "value": "STORNO_PENDING", }, 
      //{ "text": "Storno akceptovanej objednávky", "value": "STORNO_ACCEPTED", }, 	
      //{ "text": "Storno objednávky na mieste", "value": "STORNO_WAITING", }, 	 
      //{ "text": "Storno prepravovanej objednávky", "value": "STORNO_IN_PROGRESS", }, 	 	
      //{ "text": "Storno ukončenej objednávky", "value": "STORNO_FINISHED", }, 	 	
      { "text": "Zákazník nový (A)", "value": "CUSTOMER_NEW", },
      { "text": "Zákazník stály (A)", "value": "CUSTOMER_REGULAR", },
      { "text": "Zákazník prioritný (A)", "value": "CUSTOMER_PRIORITY", },
      { "text": "Zákazník známa osobnosť (A)", "value": "CUSTOMER_FAMOUS", },
      { "text": "Zákazník VIP (A)", "value": "CUSTOMER_VIP", },
      { "text": "Zákazník je kolega (A)", "value": "CUSTOMER_COLLEAGUE", },
      { "text": "Zákazník študent (A)", "value": "CUSTOMER_STUDENT", },
      { "text": "Zákazník dieťa (A)", "value": "CUSTOMER_CHILD", },
      { "text": "Zákazník junior (A)", "value": "CUSTOMER_JUNIOR", },
      { "text": "Zákazník Senior (A)", "value": "CUSTOMER_SENIOR", },
      { "text": "Zákazník je dlžník (A)", "value": "CUSTOMER_DEBTOR", },
      { "text": "Zákazník je blokovaný (A)", "value": "CUSTOMER_BLOCKED", },
      { "text": "Zdroj appka zákazníka max.2 obj (A)", "value": "SOURCE_BRANDAPP_FIRST_TWO_ORDERS", },
      { "text": "Zdroj appka zákazníka každých 5 obj (A)", "value": "SOURCE_BRANDAPP_EVERY_FIVE_ORDERS", },
      { "text": "Nákup", "value": "SHOPPING", },
    ],
    ObligatoryTypeArray: [
      { "text": "Cena za jazdu (P)", "value": "PRICE_JOURNEY", },
      { "text": "Cena za čakanie pred jazdou (P)", "value": "PRICE_WAITING", },
    ],
  },

  Settings: {
    page_title: "Nastavenia taxislužby",
    tabInfo: "Info",
    tabAutomat: "Automat",
    tabSettings: "Nastavenia",
    tabContacts: "Kontakty",
    tabGateway: "Gateway",
    tabOrderTypes: "Typy objednávok",
    tabPaymentOptions: "Platobné možnosti",
    TabSms: "SMS",
    TabModules: "Moduly",
    //tabInfo
    id: "ID",
    brokerId: "ID brokera",
    name: "Názov",
    company: "Názov spoločnosti",
    country: "Krajina",
    city: "Mesto",
    taxiLicenceNumber: "Číslo koncesie",
    taxiLicenceExpiration: "Dátum expirácie koncesie",
    type: "Typ",
    status: "Stav",
    descriptionShort: "Krátky popis",
    description: "Popis",
    //tabAutomat
    subheaderActivationTimeOrder: " Aktivácia časovej objednávky na odosielanie",
    activationTimeOrderDescription: "Čas kedy sa má aktivovať objednávka na odoslanie sa zapíše individuálne do každej časovej objednávky pri jej vytvorení. "
      + "Samotné aktivovanie automatom je možné globálne povolit alebo zakázať podľa typu objednávky (pozri záložku Typy objednavok). ",
    activeOrderBeforeSend: "Posielať vodičovi s 1 aktívnou objednávkou",
    activeOrderBeforeSendTitle: "Posielať objednávky aj vodičovi, ktorý má 1 objednávku v stave VO_VOZIDLE",
    subheaderImmediateAutomat: "Nastavenia okamžitého automatu",
    immediateAutomatDescription: "Okamžitý automat posiela objednávky vodičom hneď po vytvorení objednávky, alebo jej aktivácii.",
    immediateAutomat: "Okamžitý automat",
    priorityZero: "Prednostne posielať preferovanému vodičovi",
    priorityZeroTitle: "Objednávky s preferovaným vodičom posielať prednostne a garantovať ich doručenie",
    defaultPresendTime: "Prednastavený čas odoslania",
    defaultPresendTimeTitle: "Čas kedy sa majú začať vopred odosielať objednávky na vodičov - pri časových objednávkach",
    defaultPresendTime_hint: "Odporúčaný čas je 15 minút pred odoslaním objednávky",
    subheaderDistanceAutomat: "Nastavenia jednoduchého automatu (DISTANCE)",
    radiusDirect: "Radius u priamo zasielanej objednávky",
    radiusDirectTitle: "Pri priamo zasielanej objednávke sa vodiči dostanú do radiusu",
    delay: "Oneskorenie",
    delayTitle: "Časový interval v sekundách, kedy sa má opakovať odosielanie konkrétnej objednávky",
    subheaderPriorityAutomat: "Nastavenia pokročilého automatu (PRIORITY)",
    priorityAutomatDescription: "Objednávka prechádza prioritami od 5 do 1. Priorita 1 a 2 je určená pre časové objednávky. Priorita 3, 4 a 5 je určená pre okamžité objednávky. Najprv automat rozdeľuje objednávky priority 1, potom 2 až 5. "
      + "Objednávka postupuje spravidla z priority 5 do 4 a do 3, ak nie je najdený vhodný vodič. Resp. od 2 do 1. Priorita 5 a 2 by mala mať najmenší rádius (spravidla do 3km podľa veľkosti mesta). "
      + "Priorita 3 a 1 by mala mať čo najširší rádius (viac ako 10 km). Rádius je určený v metroch a čas v minútach (od času vytvorenia objednávky alebo požadovaného času).",
    automat: "Zapnutý automat",
    radius1: "Radius 1",
    radius1_hint: "Odporúčaná hodnota väčšia ako 50000m",
    automatPriorityTime1: "Čas 2",
    automatPriorityTime1_hint: "Odporúčaná hodnota 5min",
    radius2: "Radius 2",
    radius2_hint: "Odporúčaná hodnota 10000m",
    radius3: "Radius 3",
    radius3_hint: "Odporúčaná hodnota väčšia ako 50000m",
    automatPriorityTime2: "Čas 4",
    automatPriorityTime2_hint: "Odporúčaná hodnota 5min",
    automatPriorityTime2_validation: "Čas 4 musí byť väčší ako Čas 5",
    radius4: "Radius 4",
    radius4_hint: "Odporúčaná hodnota 20000m",
    automatPriorityTime3: "Čas 5",
    automatPriorityTime3_hint: "Odporúčaná hodnota 3min",
    automatPriorityTime3_validation: "Čas 5 musí byť menší ako Čas 4",
    radius5: "Radius 5",
    radius5_hint: "Odporúčaná hodnota menšia ako 2000m",
    automatBonusMaxMinuteDescription: "Automat začne prioritizovať vodičov, ktorí nemajú žiadnu objednávku viac ako 10 minút.  "
      + "Hodnota prioritizácie sa zvyšuje s každou minútou, po ktorú vodič nemá objednávku, po maximálnu hodnotu v minútach. "
      + "Vodiča to virtuálne priblíži o počet metrov k objednávke, ktoré sú vypočítané na základe vzorca Vzdialenosť_zvýhodnenia / (Čas_zvýhodnenia - 10). "
      + "Ak zvýhodňovanie nepožadujete, nastavenie obidve hodnoty na 1 minútu. "
      + "Zvýhodňovanie je vhodné najmä v noci, keď majú vodiči menej objednávok a stáva sa, že vzhľadom na polohu, kde stoja bez objednávky, niektorí vodiči nemusia dostávať objednávky."
      + "Toto nastavenie umožňuje rovnomernejšie rozdelenie objednávok medzi vodičov pri malom zvýšení prejazdov.",
    automatBonusMaxMinute: "Čas zvýhodnenia bez objednávky",
    automatBonusMaxMinute_hint: "Odporúčaná hodnota 40min",
    automatBonusMaxRadius: "Vzdialenosť zvýhodnenia bez objednávky",
    automatBonusMaxRadius_hint: "Odporúčaná hodnota 300m",
    //tabSettings
    subheaderDriverApp: "Nastavenia aplikácie pre vodičov",
    allowSilence: "Povoliť tichý režim",
    allowSilenceTitle: "Povoliť tlačidlo na tichý režim v aplikácii pre vodičov",
    allowStreetOrder: "Povoliť objednávky na ulici",
    allowStreetOrderTitle: "Povolenie tlačidla objednávky z ulice v aplikácii vodiča, ktorým si môže vodič vytvoriť sám objednávku z ulice",
    showClientPhoneDriverDispatch: "Zobraziť telefónne číslo zákazníka pre dispečovanie",
    showClientPhoneDriverDispatchTitle: "Zobraziť telefónne číslo zákazníka v objednávke z ulice pre dispečovanie objednávok",
    showRouteInfoNotif: "Zobraziť v notifikácii vzdialenosť vyzdvihnutia",
    showRouteInfoNotifTitle: "Zobrazenie odhadovanej vzdialenosti vodiča k miestu vyzdvihnutia v notifikácii o novej objednávke",
    showClientMarkInNotif: "Zobraziť v notifikácii označenie zákazníka",
    showClientMarkInNotifTitle: "Zobrazenie označenia zákazníka v notifikácii o novej objednávke",
    showClientNicknameInNotif: "Zobraziť v notifikácii prezývku zákazníka",
    showClientNicknameInNotifTitle: "Zobrazenie prezývky zákazníka v notifikácii o novej objednávke",
    showFiltersInNotif: "Zobraziť v notifikácii filtre",
    showFiltersInNotifTitle: "Zobrazenie filtrov/požiadaviek na jazdu v notifikácii o novej objednávke",
    allowOrderCancel: "Povoliť zrušenie objednávky",
    allowOrderCancelTitle: "Povolenie tlačidla zrušenia a vrátenia objednávky v aplikácii vodiča",
    reorderOrders: "Povoliť zmenu poradia objednávok",
    reorderOrdersTitle: "Povolenie zmeny poradia objednávok v aplikácii vodiča",
    popupTime: "Čas zobrazovania notifikácie",
    popupTimeTitle: "Čas v sekundách, ako dlho sa zobrazuje notifikácia vodičovi o novej objednávke",
    subheaderWaiting: "Čakanie na zákazníka počas jazdy",
    allowWaiting: "Povoliť čakanie",
    allowWaitingTitle: "Povolenie tlačidla Čakačky na zákazníka počas jazdy",
    waitingAfterStartLimit: "Časový limit na čakačku po štarte jazdy",
    waitingAfterStartLimitTitle: "Čas v sekundách, po ktorom môže vodič zapnúť tlačidlo na čakanie počas jazdy",
    waitingAfterStartLimit_hint: "Odporúčaná hodnota 120 sekúnd",
    waitingAfterLastLimit: "Časový limit medzi čakačkami",
    waitingAfterLastLimitTitle: "Čas v sekundách, po ktorom môže vodič opätovne zapnúť tlačidlo na čakanie počas jazdy",
    waitingAfterLastLimit_hint: "Odporúčaná hodnota 60 sekúnd",
    subheaderWorkshift: "Pracovná smena",
    vehicleAllocation: "Prideľovanie vozidiel",
    approvalWorklog: "Schvaľovanie pracovnej zmeny",
    approvalWorklogTitle: "Ukončenie pracovnej zmeny a prestávku schvaľuje dispečer",
    validateMileages: "Validácia kilometrov",
    validateMileagesTitle: "Validácia kilometrov v aplikácii vodiča pri začiatku a konci jazdy podľa pôvodného kilometražného stavu",
    subheaderPbxCallRouting: "Bezdispečerský režim",
    pbxCallRouting: "Smerovanie hovorov na vodičov",
    pbxCallRoutingTitle: "Spôsob smerovania hovorov zákazníkov na vodičov v bezdispečerskom režime",
    driverPhoneExt: "Klapka telefónu vodiča",
    driverPhoneExtTitle: "Telefónnej ústredni zasielaná klapka na vodiča",
    driverPhone: "Telefónu vodiča",
    driverPhoneTitle: "Telefónnej ústredni zasielané telefónne číslo na vodiča",
    showDriversDriverMap: "Zobraziť vodičov na mape vodiča",
    showDriversDriverMapTitle: "Zobrazenie všetkých vodičov na mape v aplikácii vodiča",
    showDriverCalllog: "Zobraziť záznamy hovorov vodiča",
    showDriverCalllogTitle: "Zobrazenie záznamov hovorov vodiča/vodičov v aplikácii vodiča",
    showStreetOrderCreateBtn: "Zobraziť tlačidlo vytvorenia objednávky",
    showStreetOrderCreateBtnTitle: "Zobrazenie tlačidla vytvorenia objednávky z ulice v aplikácii vodiča",
    subheaderOther: "Iné",
    smsCancellation: "SMS storno",
    smsCancellationTitle: "Začatím jazdy zruší staršie SMS vo fronte (negarantované)",
    realDistanceCorrection: "Korekcia reálnej vzdialenosti",
    realDistanceCorrectionTitle: "Korekcia reálnej vzdialenosti prepravy podľa GPS polohy vodiča",
    realDistanceCorrection_hint: "Odporúčaná hodnota 2.0%",
    msgRealDistanceCorrectionValueOutOfRange: "Hodnota korekcie reálnej vzdialenosti musí byť v rozsahu od -15.0 do 15.0",
    msgOneDecimalAllowed: "Povolené je len jedno desatinné miesto",
    subheaderPayment: "Platby",
    paymentProfileId: "Profil pre vystavovanie účtenky",
    profileId: "Fakturačný profil",
    profileIdTitle: "Profil, na ktorý vystavuje faktúru taxximo za služby pre taxislužbu",
    subheaderLocalityCheck: "Zaznamenávanie lokalít",
    localityCheck: "Zaznamenávanie prihlasovacích lokalít",
    standCheck: "Zaznamenávanie na stanovišti",
    driverAvailability: "Dostupnosť vodičov v appke zákazníka",
    driverAvailabilityTitle: "Dostupnosť vodičov v appke vodiča, ktorá podmieňuje výber taxislužby pri objednávaní jazdy zákazníkom",
    //tabContacts
    subheaderContacts: "Kontaktné údaje",
    email: "Kontaktný email",
    emailTitle: "Kontaktný email, na ktorý môžu písať zákazníci",
    problemEmail: "Email pre problémy",
    problemEmailTitle: "Kontaktný email, na ktorý budú prichádzať notifikácie o zaevidovaných problémoch",
    problemPhone: "Telefón pre problémy",
    problemPhoneTitle: "Kontaktný telefón, na ktorý budú prichádzať sms notifikácie o zaevidovaných problémoch",
    maintenanceVehicleEmail: "Email pre údržbu vozidiel",
    maintenanceVehicleEmailTitle: "Kontaktný email, na ktorý budú prichádzať notifikácie o blížiacich sa údržbách vozidiel",
    subheaderDispatchPhones: "Telefónne čísla na dispečing",
    phone1: "Telefón 1",
    phone2: "Telefón 2",
    phone3: "Telefón 3",
    phone4: "Telefón 4",
    phone5: "Telefón 5",
    phone6: "Telefón 6",
    phone7: "Telefón 7",
    phone8: "Telefón 8",
    phone9: "Telefón 9",
    phone10: "Telefón 10",
    phone11: "Telefón 11",
    phone12: "Telefón 12",
    //tabGateway
    subheaderSmsGateway: "Nastavenia SMS brány",
    smsGate: "SMS brána",
    smsIdentity: "Identita SMS brány",
    smsLogin: "Prihlasovacie meno",
    smsPassword: "Heslo",
    subheaderPhoneGateway: "Nastavenia telefónnej ústredne",
    phoneGate: "Telefónna ústredňa",
    phoneGateLogin: "Prihlasovacie meno",
    phoneGatePassword: "Heslo",
    subheaderPhoneExtensions: "Klapky pre telefónnu ústredňu",
    phoneExt1: "Klapka 1",
    phoneExt2: "Klapka 2",
    phoneExt3: "Klapka 3",
    phoneExt4: "Klapka 4",
    phoneExt5: "Klapka 5",
    phoneExt6: "Klapka 6",
    phoneExt7: "Klapka 7",
    phoneExt8: "Klapka 8",
    phoneExt9: "Klapka 9",
    phoneExt10: "Klapka 10",
    phoneExt11: "Klapka 11",
    phoneExt12: "Klapka 12",
    //tabOrderTypes
    tableHeadersSupportOrderTypes: {
      actions: "Akcie",
      id: "ID",
      type: "Typ",
      accept: "Prijímať",
      send: "Odoslať",
      sendType: "Typ odosielania",
      moveAutomat: "Aktivovať na odoslanie automaticky",
    },
    dialogEditSuportOrderTitle: "Upraviť typ objednávky",
    dialogCreateSuportOrderTitle: "Vytvoriť nový typ objednávky",
    dialogDeleteSuportOrderTitle: "Vymazať typ objednávky",
    supportOrderType: "Typ objednávky",
    supportOrderAccept: "Prijímať",
    supportOrderSend: "Odoslať",
    supportOrderSendType: "Typ odosielania",
    supportOrderMoveAutomat: "Aktivovať na odoslanie automaticky",
    //There can be inpact for functioning other system components, in case you delete this object. Please consider your action!
    supportOrderDeleteWarning: "V prípade, že vymažete typ objednávky, môže mať vplyv na fungovanie ostatných systémových komponentov. Prosím, zvážte svoju akciu!",
    supportOrderdeleteSubheader: "Posuňte slider doprava, ak chcete vymazať typ objednávky", // Move slider to right if you want to delete object.
    //tabPaymentOptions - importované
    //TabSms - importované
    //TabModules
    servicePlan_page_title: "Program služieb",
    servicePlan_totalPrice: "Celková cena",
    tableHeadersSupportModules: {
      actions: "Akcie",
      icon: "",
      name: "Názov modulu",
      desctription: "Popis",
      status: "Stav",
      unitPrice: "Jednotková cena",
      actualPrice: "Aktuálna cena",
      changeValue: "Požadovaná hodnota",
      newPrice: "Nová cena",
    },
    tableRowsSupportModules: {
      limitWorkshift: "Vodiči na pracovnej zmene",
      limitWorkshiftDescription: "Maximálny počet súbežne prihlásených vodičov na pracovnej zmene",
      cardPayments: "Platobná brána",
      cardPaymentsDescription: " Realizácia platieb kartou online",
      discount: "Zľavy",
      discountDescription: "Vytváranie zľavových kupónov",
      dispatchingChat: "Dispečing chat",
      dispatchingChatDescription: "Chat komunikácia iba navzájom medzi dispečermi",
      limitLocalityPrice: "Počet cenových lokalít",
      limitLocalityPriceDescription: "Maximálny počet lokalít určených pre cenníkove ceny medzi jednotlivými lokalitami",
      limitStand: "Počet stanovišť",
      limitStandDescription: "Maximálny počet stanovíšť pre vozidlá",
      pricelistType: "Typ cenníka",
      pricelistTypeDescription: "Typ cenníka umožňujúci rôzne spôsoby výpočtu ceny za objednávku",
      report: "Reporty",
      reportDescription: "Zobrazovanie reportov",
      timeOrder: "Časové objednávky",
      timeOrderDescription: "Možnosť vytvoriť objednávku na konkrétny čas",
      account: "Firemné jazdy",
      accountDescription: "Možnosť vytvoriť objednávku s fakturáciou pre firmy",
      worklog: "Pracovné zmeny vodičov",
      worklogDescription: "Záznam pracovných zmien vodičov",
      addressSearch: "Adresy v dispečingu",
      addressSearchDescription: "Zdroj adresných údajov",
      brandApp: "Brandovaná aplikácia",
      brandAppDescription: "Vlastná aplikácia pre zákazníkov",
      commercialAds: "Reklamný modul",
      commercialAdsDescription: "Zobrazovanie vlastných reklám",
    },
    limitWorkshiftRules1: "This field is required",
    limitWorkshiftRules2: "Value should be above 3",
    limitWorkshiftRules3: "Max should not be above 500",

    limitStandRules1: "This field is required",
    limitStandRules2: "Value should be above 0",
    limitStandRules3: "Value should be above your current limit",
    limitStandRules4: "Max should not be above 20",

    limitLocalityPriceRules1: "This field is required",
    limitLocalityPriceRules2: "Value should be above 0",
    limitLocalityPriceRules3: "Value should be above your current limit",
    limitLocalityPriceRules4: "Max should not be above 20",

    ImmediateAutomatArray: [
      {
        "text": "Vypnúť",
        "value": "NONE",
      },
      {
        "text": "Lokalita",
        "value": "LOCALITY",
      },
      {
        "text": "Vzdialenosť",
        "value": "DISTANCE",
      },
      /* {
        "text": "Preferovaný vodič",
        "value": "PREFERRED",
      }, */
    ],
    AllowOrderCancelArray: [
      {
        "text": "Povoliť",
        "value": "ENABLED",
      },
      {
        "text": "Zakázať",
        "value": "REQUEST",
      },
    ],
    VehicleAllocationTypeArray: [
      {
        "text": "Zobrazené iba priradené vozidlo",
        "value": "ASSIGNED",
      },
      {
        "text": "Všetky vozidlá na výber",
        "value": "ALL",
      },
    ],
    DriverAvailability:
    {
      FREE_ONLY: "Iba voľní",
      FREE_AND_FINISHING: "Voľní a dokončujúci jazdu",
      ACTIVE: "Aktívni",
    },
    DriverAvailabilityArray:
      [
        {
          "text": "Iba voľní",
          "value": "FREE_ONLY",
        },
        {
          "text": "Voľní a dokončujúci jazdu",
          "value": "FREE_AND_FINISHING",
        },
        {
          "text": "Aktívni",
          "value": "ACTIVE",
        },
      ],
    AcceptSupportOrderType: {
      AUTO: "Automaticky",
      MANUAL: "Manuálne",
      UNDEFINED: "",
    },
    AcceptSupportOrderTypeArray:
      [
        {
          "text": "Automaticky",
          "value": "AUTO",
        },
        {
          "text": "Manuálne",
          "value": "MANUAL",
        },
      ],

    SendSupportOrderType: {
      AUTOMAT: "Automatom",
      MANUAL: "Manuálne",
      UNDEFINED: "",
    },
    SendSupportOrderTypeArray:
      [
        {
          "text": "Automatom",
          "value": "AUTOMAT",
        },
        {
          "text": "Manuálne",
          "value": "MANUAL",
        },
      ],

    PbxCallRoutingType: {
      SLOWEST: "Na najpomalšieho voľného vodiča",
      FREE_EARLIEST_DRIVE: "Na voľného vodiča najdlhšie bez objednávky",
      EARLIEST_CALL: "Rovnomerné rozdeľovanie (na vodiča najdlhšie bez telefonátu)",
      EARLIEST_CALL_PREF_FREE: "Rovnomerné rozdeľovanie, preferovný voľný",
      EARLIEST_CALL_PREF_UNOCCUPIED: "Rovnomerné rozdeľovanie, preferovaný voľný a neobsadený",
      UNDEFINED: "",
    },

    PbxCallRoutingTypeArray: [
      {
        "text": "Na najpomalšieho voľného vodiča",
        "value": "SLOWEST",
      },
      {
        "text": "Na voľného vodiča najdlhšie bez objednávky",
        "value": "FREE_EARLIEST_DRIVE",
      },
      {
        "text": "Rovnomerné rozdeľovanie (na vodiča najdlhšie bez telefonátu)",
        "value": "EARLIEST_CALL",
      },
      {
        "text": "Rovnomerné rozdeľovanie, preferovný voľný",
        "value": "EARLIEST_CALL_PREF_FREE",
      },
      {
        "text": "Rovnomerné rozdeľovanie, preferovaný voľný a neobsadený",
        "value": "EARLIEST_CALL_PREF_UNOCCUPIED",
      },
    ],

    DriverPhoneExtType: {
      VEHICLE: "Vozidlo",
      DRIVER: "Vodič",
      DRIVER_SELECT: "Výber vodičom",
    },
    DriverPhoneExtTypeArray: [
      {
        "text": "Vozidlo",
        "value": "VEHICLE",
      },
      {
        "text": "Vodič",
        "value": "DRIVER",
      },
      /* {
        "text": "Výber vodičom",
        "value": "DRIVER_SELECT",
      }, */
    ],

    DriverPhoneType: {
      VEHICLE: "Vozidlo",
      DRIVER: "Vodič",
      DRIVER_SELECT: "Výber vodičom",
    },
    DriverPhoneTypeArray: [
      {
        "text": "Vozidlo",
        "value": "VEHICLE",
      },
      {
        "text": "Vodič",
        "value": "DRIVER",
      },
      /*  {
         "text": "Výber vodičom",
         "value": "DRIVER_SELECT",
       }, */
    ],

    ShowDriversDriverMapType: {
      NONE: "Nikdy",
      ALWAYS: "Vždy",
      DISPATCH_ONLY: "Iba pre dispečera",
      AUTO_DISPATCH_ONLY: "Iba pre dispečera v bezdispečingu",
    },
    ShowDriversDriverMapTypeArray: [
      {
        "text": "Nikdy",
        "value": "NONE",
      },
      {
        "text": "Vždy",
        "value": "ALWAYS",
      },
      {
        "text": "Iba pre dispečera",
        "value": "DISPATCH_ONLY",
      },
      {
        "text": "Iba pre dispečera v bezdispečingu",
        "value": "AUTO_DISPATCH_ONLY",
      },
    ],

    ShowDriverCalllogType: {
      NONE: "Žiadne",
      ALL: "Všetky",
      OWN: "Iba vlastné",
    },
    ShowDriverCalllogTypeArray: [
      {
        "text": "Nikdy",
        "value": "NONE",
      },
      {
        "text": "Všetky",
        "value": "ALL",
      },
      {
        "text": "Iba vlastné",
        "value": "OWN",
      },
    ],

    ShowStreetOrderCreateBtnType: {
      STREET: "Iba Z ulice",
      MY: "Iba Moja",
      OTHER: "Iba Prerozdeliť",
      STREET_OTHER: "Z ulice a Prerozdeliť",
      MY_OTHER: "Moja a Prerozdeliť",
      STREET_MY: "Z ulice a Moja",
      ALL: "Všetky",
    },
    ShowStreetOrderCreateBtnTypeArray: [
      {
        "text": "Iba Z ulice",
        "value": "STREET",
      },
      {
        "text": "Iba Moja",
        "value": "MY",
      },
      {
        "text": "Iba Prerozdeliť",
        "value": "OTHER",
      },
      {
        "text": "Z ulice a Prerozdeliť",
        "value": "STREET_OTHER",
      },
      {
        "text": "Moja a Prerozdeliť",
        "value": "MY_OTHER",
      },
      {
        "text": "Z ulice a Moja",
        "value": "STREET_MY",
      },
      {
        "text": "Všetky",
        "value": "ALL",
      },
    ],

    SendtypeSupportOrderType: {
      DIRECT_DRIVER: "Priamo na vodiča",
      DIRECT_LOCALITY: "Priamo na lokalitu",
      DIRECT_STAND: "Priamo na stanovište",
      DISTANCE: "Najbližšia vzdialenosť",
      PRIORITY: "Prioritizovaný",
      RADIUS: "Po rádiusoch",
      UNOCCUPIED: "Na neobsadených",
      UNDEFINED: "",
    },
    SendtypeSupportOrderTypeArray:
      [
        /*{
          "text": "Priamo na vodiča",
          "value": "DIRECT_DRIVER",
        },
        {
          "text": "Priamo na lokalitu",
          "value": "DIRECT_LOCALITY",
        },
        {
          "text": "Priamo na stanovište",
          "value": "DIRECT_STAND",
        },
        {
          "text": "Najbližšia vzdialenosť",
          "value": "DISTANCE",
        },*/
        {
          "text": "Prioritizovaný (odporúčaný)",
          "value": "PRIORITY",
        },
        /*{
          "text": "Po rádiusoch",
          "value": "RADIUS",
        },
        {
          "text": "Na neobsadených",
          "value": "UNOCCUPIED",
        },*/
      ],
  },
  SmsTemplateList: {
    tableHeaders: {
      actions: "Akcie",
      enabled: "Povolené",
      template: "Šablóna",
      language: "jazyk",
      type: "Typ",
    },
    dialogTitle: "SMS šablóna",
    enabled: "Povolené",
    template: "Šablóna",
    language: "Jazyk",
    type: "Typ",
    blockPaymentInvoice: "Neposielať pre platby faktúrou",
    templateExample: "Príklad šablóny",
    dialogText1: "Odporúčame používať SMS bez diakritiky a špeciálnych znakov, pretože môžu skrátiť dĺžku jednej SMS (jeden znak zaberie miesto viacerých znakov v správe). Ak správa prekročí limit 160 znakov, môže byť rozdelená do viacerých SMS, čo zvýši finančné náklady na zasielanie. Ak používate premenné, uistite sa, že zahrniete aj ich dĺžku v skutočnej podobe, aby ste predišli problémom s limitom znakov.",
    dialogText2: "Ak chcete vložiť do textu informácie z objednávky, použite nasledujúce premenné:",
    VAR_clientPhone: "Telefónne číslo klienta",
    VAR_driverNickname: "Prezývka vodiča",
    VAR_driverFirstName: "Meno vodiča",
    VAR_driverLastName: "Priezvisko vodiča",
    VAR_driverPhone: "Telefónne číslo vodiča",
    VAR_vehicleBrand: "Značka vozidla",
    VAR_vehicleModel: "Model vozidla",
    VAR_vehicleLpn: "ŠPZ vozidla",
    VAR_vehicleColor: "Farba vozidla",
    VAR_requestedTime: "Požadovaný čas",
    VAR_distance: "Vzdialenosť",
    VAR_currency: "Mena",
    VAR_estimatedPriceTotal: "Odhadovaná cena",
    VAR_finalPriceTotal: "Konečná cena",
    VAR_finalPriceExtra: "Konečný príplatok",
    VAR_finalPriceDiscount: "Konečná zľava",
    VAR_sms_url_id_short: "Link na online sledovanie objednávky",
    msgErrorSelectType: "Vyberte typ šablóny",
    msgErrorAlreadyUsedType: "Tento typ šablóny už existuje",
    exampleAccepted1: "Vasa objednávka bola prijata. Vozidlo ",
    exampleAccepted2: " pride po vas o niekolko minut.",
    exammpleApproaching1: "Vozidlo ",
    exammpleApproaching2: " je o chvilu na pozadovanom mieste. Cakame na Vas.",
    exampleWaiting1: "Vozidlo ",
    exampleWaiting2: " dorazilo na pozadovane miesto. Cakame na Vas.",
    exampleWaitingApproaching1: "Vozidlo ",
    exampleWaitingApproaching2: " dorazilo na pozadovane miesto. Cakame na Vas.",
    exampleFinished1: "Jazda bola ukoncena. Dakujeme za pouzitie nasich sluzieb.",
    exampleSettled1: "Jazda bola ukoncena. Cena za jazdu je ",
    exampleSettled2: ". Dakujeme za pouzitie nasich sluzieb.",
    exampleCancelled1: "Jazda bola zrusena. V pripade otazok nas prosim kontaktujte na cisle dispecingu +4219XXXXXXXX.",
  },
  PaymentOptionList: {
    tableHeaders: {
      actions: "Akcie",
      active: "Aktívne",
      paymentType: "Typ platby",
      receiptType: "Typ dokladu",
      receiptStatus: "Stav dokladu",
      note: "Poznámka",
    },
    dialogTitle: "Platobná možnosť",
    active: "Aktívne",
    paymentType: "Typ platby",
    receiptType: "Typ dokladu",
    receiptStatus: "Stav dokladu",
    note: "Poznámka",
    msgErrorAlreadyUsedType: "Tento typ platobnej možnosti už existuje",
  },

  receiptType: {
    DISABLED: "Bez dokladu", 	// Neaktívny, negenerovať doklad (0)
    INVOICE: "Faktúra", 	// Faktúra daňový doklad (1)
    RECEIPT: "Pokladničný blok", 	// Cash Receipt / Pokladničný blok, daňový doklad (2)
    CREDIT_NOTE: "Dobropis", 	// Dobropis, daňový doklad (3)
    NF_INVOICE: "Nedaňová faktúra", 	// Non Fiscal Invoice / Faktúra NEdaňový doklad (1)
    NF_RECEIPT: "Nedaňový pokladničný blok", 	// Non Fiscal Cash Receipt / Pokladničný blok, NEdaňový doklad (2)
    NF_CREDIT_NOTE: "Nedaňový dobropis", 	// Non Fiscal Credit Note / Dobropis, NEdaňový doklad (3)
    PRO_FORMA: "Zálohová faktúra" 	// Zálohová faktúra, NEdaňový doklad (14+4)
  },
  receiptTypeArray:
    [
      { "text": "Bez dokladu", "value": "DISABLED", },
      { "text": "Faktúra", "value": "INVOICE", },
      { "text": "Pokladničný blok", "value": "RECEIPT", },
      { "text": "Dobropis", "value": "CREDIT_NOTE", },
      { "text": "Nedaňová faktúra", "value": "NF_INVOICE", },
      { "text": "Nedaňový pokladničný blok", "value": "NF_RECEIPT", },
      { "text": "Nedaňový dobropis", "value": "NF_CREDIT_NOTE", },
      { "text": "Zálohová faktúra", "value": "PRO_FORMA", },
    ],

  receiptStatus: {
    CANNED: "Predpripravená", 	// Predpripravená (1)
    ISSUED: "Vystavená", 	// Vystavená (2)
    POSTED: "Vyúčtovaná", 	// Vyúčtovaná (3)
    PAID: "Zaplatená", 	// Zaplatená (4)
    ACCOUNTED: "Zaúčtovaná", 	// Zaúčtovaná (5)
    CREDIT: "Dobropisovaná", 	// Dobropisovaná (6)
    RECLAIMED: "Reklamovaná", 	// Reklamovaná (7)
    DELAYED: "V omeškaní", 	// V omeškaní (8)
    CLAIMED: "Vymáhaná", 	// Vymáhaná (8)
    CANCELLED: "Zrusena" 	// Zrusena (10)
  },
  receiptStatusArray:
    [
      { "text": "Predpripravená", "value": "CANNED", },
      { "text": "Vystavená", "value": "ISSUED", },
      { "text": "Vyúčtovaná", "value": "POSTED", },
      { "text": "Zaplatená", "value": "PAID", },
      { "text": "Zaúčtovaná", "value": "ACCOUNTED", },
      { "text": "Dobropisovaná", "value": "CREDIT", },
      { "text": "Reklamovaná", "value": "RECLAIMED", },
      { "text": "V omeškaní", "value": "DELAYED", },
      { "text": "Vymáhaná", "value": "CLAIMED", },
      { "text": "Zrusena", "value": "CANCELLED", },
    ],

  TaxiserviceList: {
    page_title: "Zozname taxislužieb",
    dialogCreateTitle: "Nová taxislužba",
    name: "Názov",
    country: "Krajina",
    city: "Mesto",
    tableHeaders: {
      actions: "Akcie",
      id: "ID",
      name: "Názov",
      country: "Krajina",
      city: "Mesto",
      status: "Stav",
      type: "Typ",
    },
  },
  Taxiservice: {
    page_title: "Taxislužba",
    //Ostatne zo "Settings" 
  },
  //FEEDBACK
  FeedbackList: {
    page_title: "Zoznamy feedbackov",
    id: "ID",
    checked: "Vyriešené",
    createdAt: "Vytvorené",
    fromEmail: "Email",
    message: "Správa",
    dialogTitle: "Úprava feedbacku",
    tableHeaders: {
      actions: "Akcie",
      id: "ID",
      checked: "Vyriešené",
      createdAt: "Vytvorené",
      fromEmail: "Email",
      message: "Správa",
    },

  },
  //REPORTS
  ReportDispatcherAccessLog: {
    page_title: "Záznamy o prihlásení dispečerov",
    actions: "Akcie",
    createdAt: "Vytvorené",
    ipAddress: "IP adresa",
    role: "Rola",
    firstName: "Meno",
    lastName: "Priezvisko",
    info: "Info",
    btnFindIpAddress: "Nájsť IP adresu v mapovej službe",
  },
  ReportOverview: {
    page_title: "Prehľad",
    allRealized: "Zrealizované",
    allCanceledRealized: "Zrušené počas realizácie",
    allCanceledUnrealized: "Zrušené pred realizáciou",
    sourceStatistic: "Počty objednávok podľa zdroja vytvorenia v aktuálnom mesiaci",
    sourceStatisticPreviousMonth: "Počty objednávok podľa zdroja vytvorenia v predchádzajúcom mesiaci",
  },
  ReportSalesCompany: {
    page_title: "Firemné jazdy",
    actions: "Akcie",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    id: "ID",
  },
  ReportSalesDriver: {
    page_title: "Tržby vodičov",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    startTime: "Začiatok pracovnej zmeny",
    id: "ID",
  },
  ReportStatusDay: {
    page_title: "Stavy objednávok podľa dní",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    startTime: "Začiatok pracovnej zmeny",
    dateRange: "Obdobie",
    settled: "Zúčtované",
    canceled: "Zrušené",
  },
  ReportStatusHour: {
    page_title: "Stavy objednávok podľa hodín",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    dateRange: "Obdobie",
  },
  ReportSettledHour: {
    page_title: "Zúčtované objednávky podľa hodín",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    dateRange: "Obdobie",
  },
  ReportStatusMonth: {
    page_title: "Stavy objednávok podľa mesiacov",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    dateRange: "Obdobie",
  },
  ReportStatusMonthPerSource: {
    page_title: "Stavy objednávok podľa zdroja",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    dateRange: "Obdobie",
  },
  ReportStatusDailyVehicles: {
    page_title: "Počty vozidiel podľa dní",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    startTime: "Začiatok pracovnej zmeny",
    dateRange: "Obdobie",
    year: "Rok",
    month: "Mesiac",
    day: "Deň",
    monthName: "Názov mesiaca",
    dayName: "Názov dňa",
    total: "Počet vozidiel",
  },
  ReportWorkshift: {
    page_title: "Pracovné zmeny",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    startTime: "Začiatok pracovnej zmeny",
    id: "ID",
    driverId: "ID vodiča",
    worklogDetailDialog_title: "Detail pracovnej zmeny",
    worklogDetailDialogItemList_title: "Zoznam zmien počas pracovnej zmeny",
    worklogDetailDialogRequestList_title: "Zoznam požiadaviek vodiča",
    worklogOrdersDialog_title: "Zoznam objednávok počas pracovnej zmeny",
    worklogId: "ID pracovnej zmeny",
    createdAt: "Vytvorené",
    processedAt: "Spracované",
    updatedAt: "Zmenené",
    breakCounter: "Počet prestávok",
    breakDuration: "Trvanie prestávok",
    itemDuration: "Trvanie",
    status: "Stav",
    type: "Typ",
    firstName: "Meno",
    lastName: "Priezvisko",
    tableHeaders: {
      id: "ID",
      status: "Stav",
      source: "Zdroj",
      type: "Typ",
      paymentType: "Typ platby",
      acceptedAt: "Akceptované",
      finalPriceTotal: "Celková cena",
      driverId: "ID vodiča",
    },
  },
  ReportWorkshiftSummary: {
    page_title: "Sumarizácia pracovných zmien",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    startTime: "Začiatok pracovnej zmeny",
    driverId: "ID vodiča",
  },
  ReportCallLog: {
    page_title: "Záznamy volaní na vodičov v bezdispečerskom režime",
    actions: "Akcie",
    taxiserviceId: "Taxislužba",
    orderId: "Objednávka",
    status: "Stav",
    callerPhone: "Telefón",
    clientNickname: "Zákazník",
    driverFirstName: "Meno vodiča",
    driverLastName: "Priezvisko vodiča",
    driverId: "ID vodiča",
    createdAt: "Vytvorené",
  },
  ReportOrderSentLog: {
    page_title: "Zoznam odoslaných objednávok na vodičov",
    actions: "Akcie",
    taxiserviceId: "Taxislužba",
    orderId: "Objednávka",
    sentStatus: "Stav",
    fromBy: "Zasielateľ",
    distance: "Vzdialenosť",
    driverFirstName: "Meno vodiča",
    driverLastName: "Priezvisko vodiča",
    driverId: "ID vodiča",
    createdAt: "Vytvorené",
    message: "Poznámka",
  },
  ReportOrderSentManually: {
    page_title: "Manuálne odoslané objednávky",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    actions: "Akcie",
    dateRange: "Obdobie",
    fromBy: "ID zasielateľa",
    dispatcherName: "Meno zasielateľa",
    countUniqueOrders: "Počet unikátnych objednávok",
  },
  ReportOrderEstimatedWaitingTime: {
    page_title: "Odhadovaný a reálny čas čakania na vozidlo",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    actions: "Akcie",
    orderId: "Objednávka",
    type: "Typ",
    requestedTime: "Požadovaný čas",
    acceptedAt: "Prijaté",
    waitingAt: "Čakanie",
    estimatedWaitingTime: "Odhadovaný čas čakania (sec)",
    realWaitingTime: "Reálny čas čakania (sec)",
    waitingTimeDifference: "Rozdiel času čakania (min)",
  },
  ReportOrderIgnoredAccepted: {
    page_title: "Zoznam ignorovaných a prijatých objednávok",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    actions: "Akcie",
    taxiserviceId: "Taxislužba",
    orderId: "Objednávka",
  },
  ReportOrderNotificationAcceptance: {
    page_title: "Počty vodičmi spracovaných notifikácií o novej objednávke",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    actions: "Akcie",
    dateRange: "Obdobie",
    driverId: "ID vodiča",
    firstName: "Meno",
    lastName: "Priezvisko",
    totalNotificationsSent: "Počet odoslaných notifikácií",
    ordersSent: "Počet odoslaných objednávok",
    ordersAccepted: "Počet prijatých objednávok",
    ordersNotAccepted: "Počet neprijatých objednávok",
  },
  ReportOrderStatusSpanHourly: {
    page_title: "Stavy objednávok podľa hodín",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    actions: "Akcie",
    taxiserviceId: "Taxislužba",
    orderId: "Objednávka",
  },
  OrdersGoogleMap: {
    enableBoardMarkers: "Značky vyzdvihnutia",
    enableDestinationMarkers: "Značky cieľov",
    enableOrderPath: "Trasa",
    enableBoardHeatmap: "Heatmap vyzdvihnutia",
    enableDestinationHeatmap: "Heatmap cieľov",
    heatmapSettings: "Nastavenia heatmapy",
    heatmapOpacity: "Priehľadnosť",
    heatmapRadius: "Polomer",
    heatmapBoardGradient: "Farba vyzdvihnutia",
    heatmapDestinationGradient: "Farba cieľov",
  },

  AmenityTypeArray: [
    {
      "text": "Účtovníctvo",
      "alternativeText": "Daňové poradenstvo, Finančné poradenstvo, Účtovnícke kancelárie",
      "value": "accounting",
      "icon": "wallet-outline",
    },
    {
      "text": "Letisko",
      "alternativeText": "Letisková budova, Terminál, Airport",
      "value": "airport",
      "icon": "airport",
    },
    {
      "text": "Zábavný park",
      "alternativeText": "Zábavné centrum, Lunapark, Fun park, Detský park, Detské ihrisko",
      "value": "amusement_park",
      "icon": "roller-skate",
    },
    {
      "text": "Akvarium",
      "alternativeText": "Morský svet, Morský život",
      "value": "aquarium",
      "icon": "fish",
    },
    {
      "text": "Galéria",
      "alternativeText": "Výstava, Výstavná sieň, Výstavný priestor",
      "value": "art_gallery",
      "icon": "palette",
    },
    {
      "text": "Bankomat",
      "alternativeText": "ATM, Vkladomat, Bankomatová pobočka",
      "value": "atm",
      "icon": "cash",
    },
    {
      "text": "Pekáreň",
      "alternativeText": "Pečivo",
      "value": "bakery",
      "icon": "bread-slice",
    },
    {
      "text": "Banka",
      "alternativeText": "Banková pobočka, Sporiteľňa",
      "value": "bank",
      "icon": "bank",
    },
    {
      "text": "Bar",
      "alternativeText": "Krčma, Hostinec, Pohostinstvo, Piváreň, Klubovňa",
      "value": "bar",
      "icon": "glass-cocktail",
    },
    {
      "text": "Kozmetický salón",
      "alternativeText": "Kozmetika, Kozmetické služby, Salón krásy",
      "value": "beauty_salon",
      "icon": "face-woman-shimmer-outline",
    },
    {
      "text": "Obchod s bicyklami",
      "alternativeText": "Cyklistické potreby",
      "value": "bicycle_store",
      "icon": "bike",
    },
    {
      "text": "Kníhkupectvo",
      "alternativeText": "Knižnica, Predaj kníh",
      "value": "book_store",
      "icon": "book",
    },
    {
      "text": "Bowling",
      "alternativeText": "Bowlingová hala, Bovling, Kolky, Kolkáreň",
      "value": "bowling_alley",
      "icon": "bowling",
    },
    {
      "text": "Autobusová stanica",
      "alternativeText": "Autobusové zastávka, Autobusový terminál, Bus station",
      "value": "bus_station",
      "icon": "bus",
    },
    {
      "text": "Kaviareň",
      "alternativeText": "Káva, Kaviareň s cukrárňou",
      "value": "cafe",
      "icon": "coffee",
    },
    {
      "text": "Kemping",
      "alternativeText": "Kemp, Táborisko, Tábor",
      "value": "campground",
      "icon": "tent",
    },
    {
      "text": "Auto predajca",
      "alternativeText": "Auto showroom, Autosalón",
      "value": "car_dealer",
      "icon": "car",
    },
    {
      "text": "Požičovňa áut",
      "value": "car_rental",
      "icon": "car",
    },
    {
      "text": "Autoservis",
      "alternativeText": "Autoopraváreň, Autoservis, Autodielňa, Pneuservis",
      "value": "car_repair",
      "icon": "car",
    },
    {
      "text": "Autoumývareň",
      "value": "car_wash",
      "icon": "car-wash",
    },
    {
      "text": "Kasíno",
      "alternativeText": "Hazard, Ruleta, Automaty, Herňa",
      "value": "casino",
      "icon": "cards",
    },
    {
      "text": "Cintorín",
      "value": "cemetery",
      "icon": "grave-stone",
    },
    {
      "text": "Mestská radnica",
      "alternativeText": "Obecný úrad, Radnica, Mestský úrad",
      "value": "city_hall",
      "icon": "city",
    },
    {
      "text": "Obchod s oblečením",
      "value": "clothing_store",
      "icon": "tshirt-crew",
    },
    {
      "text": "Potraviny",
      "value": "convenience_store",
      "icon": "store",
    },
    {
      "text": "Súd",
      "value": "courthouse",
      "icon": "gavel",
    },
    {
      "text": "Zubár",
      "value": "dentist",
      "icon": "tooth",
    },
    {
      "text": "Obchod so spotrebnou elektronikou",
      "value": "electronics_store",
      "icon": "cellphone",
    },
    {
      "text": "Veľvyslanectvo",
      "value": "embassy",
      "icon": "flag",
    },
    {
      "text": "Hasičská stanica",
      "value": "fire_station",
      "icon": "fire-station",
    },
    {
      "text": "Kvetinárstvo",
      "value": "florist",
      "icon": "flower",
    },
    {
      "text": "Dom smútku",
      "alternativeText": "Pohrebný ústav, Pohrebný dom",
      "value": "funeral_home",
      "icon": "grave-stone",
    },
    {
      "text": "Nábytok",
      "value": "furniture_store",
      "icon": "sofa",
    },
    {
      "text": "Benzínová pumpa",
      "value": "gas_station",
      "icon": "gas-station",
    },
    {
      "text": "Všeobecný obchod",
      "value": "generic_business",
      "icon": "store",
    },
    {
      "text": "Geocode",
      "value": "geocode",
      "icon": "map-marker",
    },
    {
      "text": "Posilňovňa",
      "value": "gym",
      "icon": "dumbbell",
    },
    {
      "text": "Kaderníctvo",
      "value": "hair_care",
      "icon": "hair-dryer",
    },
    {
      "text": "Obchod s kovovým tovarom",
      "value": "hardware_store",
      "icon": "hammer",
    },
    {
      "text": "Hinduistický chrám",
      "value": "hindu_temple",
      "icon": "temple-hindu",
    },
    {
      "text": "Domáce potreby",
      "value": "home_goods_store",
      "icon": "home",
    },
    {
      "text": "Nemocnica",
      "alternativeText": "Poliklinika, Ambulancia, Zdravotné stredisko, Klinika",
      "value": "hospital",
      "icon": "hospital",
    },
    {
      "text": "Poisťovňa",
      "alternativeText": "Poistenie, Poisťovací agent",
      "value": "insurance_agency",
      "icon": "shield",
    },
    {
      "text": "Zlatníctvo",
      "alternativeText": "Zlatník, Zlatnícka dielňa",
      "value": "jewelry_store",
      "icon": "diamond",
    },
    {
      "text": "Práčovňa",
      "value": "laundry",
      "icon": "washing-machine",
    },
    {
      "text": "Advokát",
      "alternativeText": "Právnik, Právna kancelária, Advokátska kancelária",
      "value": "lawyer",
      "icon": "gavel",
    },
    {
      "text": "Knižnica",
      "alternativeText": "Knihovňa",
      "value": "library",
      "icon": "library",
    },
    {
      "text": "Mestská železničná stanica",
      "value": "light_rail_station",
      "icon": "train",
    },
    {
      "text": "Liehoviny",
      "value": "liquor_store",
      "icon": "bottle-wine",
    },
    {
      "text": "Miestna vládna kancelária",
      "alternativeText": "Okresný úrad, Krajský úrad",
      "value": "local_government_office",
      "icon": "office-building",
    },
    {
      "text": "Zámočník",
      "value": "locksmith",
      "icon": "key",
    },
    {
      "text": "Ubytovanie",
      "alternativeText": "Hotel, Penzión, Hostel, Motel",
      "value": "lodging",
      "icon": "bed",
    },
    {
      "text": "Doručenie jedla",
      "value": "meal_delivery",
      "icon": "food",
    },
    {
      "text": "Výdaj jedla",
      "alternativeText": "Jedlo so sebou, Fast food, Takeaway",
      "value": "meal_takeaway",
      "icon": "food",
    },
    {
      "text": "Moslimský chrám",
      "alternativeText": "Mešita",
      "value": "mosque",
      "icon": "mosque",
    },
    {
      "text": "Požičovňa filmov",
      "value": "movie_rental",
      "icon": "movie",
    },
    {
      "text": "Kino",
      "alternativeText": "Amfiteáter, Autokino, Kinematograf, Multiplex",
      "value": "movie_theater",
      "icon": "filmstrip",
    },
    {
      "text": "Firma na presťahovanie",
      "value": "moving_company",
      "icon": "truck",
    },
    {
      "text": "Múzeum",
      "value": "museum",
      "icon": "bank",
    },
    {
      "text": "Nočný klub",
      "alternativeText": "Diskotéka, Klub, Hudobný klub, Tančiareň",
      "value": "night_club",
      "icon": "music",
    },
    {
      "text": "Maliar",
      "alternativeText": "Maľovanie, Maľovanie interiérov a exteriérov, Natierač",
      "value": "painter",
      "icon": "format-paint",
    },
    {
      "text": "Park",
      "alternativeText": "Záhrada, Lesopark",
      "value": "park",
      "icon": "tree",
    },
    {
      "text": "Parkovisko",
      "alternativeText": "Parkovanie, Parkovací dom",
      "value": "parking",
      "icon": "parking",
    },
    {
      "text": "Zvieracie potreby",
      "alternativeText": "Zoodom, Zverinec",
      "value": "pet_store",
      "icon": "paw",
    },
    {
      "text": "Lekáreň",
      "value": "pharmacy",
      "icon": "pill",
    },
    {
      "text": "Fyzioterapeut",
      "value": "physiotherapist",
      "icon": "stethoscope",
    },
    {
      "text": "Vodoinštalatér",
      "value": "plumber",
      "icon": "pipe-wrench",
    },
    {
      "text": "Policajná stanica",
      "alternativeText": "Polícia, Útvar policajného zboru",
      "value": "police",
      "icon": "police-badge",
    },
    {
      "text": "Pošta",
      "value": "post_office",
      "icon": "mailbox",
    },
    {
      "text": "Základná škola",
      "value": "primary_school",
      "icon": "school",
    },
    {
      "text": "Realitná kancelária",
      "value": "real_estate_agency",
      "icon": "home-city",
    },
    {
      "text": "Reštaurácia",
      "alternativeText": "Bistro, Jedáleň, Kuchyňa, Pizzeria",
      "value": "restaurant",
      "icon": "silverware-fork-knife",
    },
    {
      "text": "Pokrývač",
      "value": "roofing_contractor",
      "icon": "home-roof",
    },
    {
      "text": "Kempingové miesto",
      "value": "rv_park",
      "icon": "tent",
    },
    {
      "text": "Škola",
      "value": "school",
      "icon": "school",
    },
    {
      "text": "Stredná škola",
      "value": "secondary_school",
      "icon": "school",
    },
    {
      "text": "Obuvníctvo",
      "value": "shoe_store",
      "icon": "shoe-heel",
    },
    {
      "text": "Nákupné centrum",
      "alternativeText": "Obchodné centrum, Obchodná galéria, Obchodné stredisko",
      "value": "shopping_mall",
      "icon": "shopping",
    },
    {
      "text": "Kúpele",
      "alternativeText": "Wellness, Saua, Masáže",
      "value": "spa",
      "icon": "shower",
    },
    {
      "text": "Štadión",
      "alternativeText": "Športová hala, Športový areál, Športový štadión, Futbalový, Hokejový",
      "value": "stadium",
      "icon": "stadium",
    },
    {
      "text": "Sklad",
      "value": "storage",
      "icon": "warehouse",
    },
    {
      "text": "Obchod",
      "alternativeText": "Predajňa, Obchod s tovarom",
      "value": "store",
      "icon": "store",
    },
    {
      "text": "Stanica metra",
      "value": "subway_station",
      "icon": "subway",
    },
    {
      "text": "Supermarket",
      "alternativeText": "Veľký obchod, Hypermarket",
      "value": "supermarket",
      "icon": "store",
    },
    {
      "text": "Stanovište taxi",
      "alternativeText": "Taxi, Taxi služba, Taxislužba",
      "value": "taxi_stand",
      "icon": "taxi",
    },
    {
      "text": "Turistická atrakcia",
      "alternativeText": "Turistické miesto, Turistická destinácia",
      "value": "tourist_attraction",
      "icon": "binoculars",
    },
    {
      "text": "Železničná stanica",
      "alternativeText": "Železničný terminál, Železničná zastávka",
      "value": "train_station",
      "icon": "train",
    },
    {
      "text": "Tranzitná stanica",
      "alternativeText": "Tranzit, Tranzitná zastávka, Tranzitný terminál",
      "value": "transit_station",
      "icon": "bus",
    },
    {
      "text": "Cestovná kancelária",
      "alternativeText": "Cestovný ruch, Cestovná agentúra",
      "value": "travel_agency",
      "icon": "airplane",
    },
    {
      "text": "Univerzita",
      "value": "university",
      "icon": "school",
    },
    {
      "text": "Veterinár",
      "value": "veterinary_care",
      "icon": "paw",
    },
    {
      "text": "ZOO",
      "value": "zoo",
      "icon": "elephant",
    },
    {
      "text": "Vodné plochy",
      "alternativeText": "Jazero, Vodný svet",
      "value": "aqua_zones",
      "icon": "zodiac-aquarius",
    },
    {
      "text": "Tenisové kurty",
      "value": "tenis",
      "icon": "tennis",
    },
    {
      "text": "Kamióny",
      "value": "truck",
      "icon": "truck",
    },
    {
      "text": "Vináreň",
      "alternativeText": "Víno a tapas, Viecha, Vínotéka",
      "value": "wine",
      "icon": "wine",
    },
    {
      "text": "Synagóga",
      "value": "synagogue",
      "icon": "synagogue",
    },
    {
      "text": "Kostol",
      "value": "church",
      "icon": "church",
    },
    {
      "text": "Buddhistický chrám",
      "value": "worship_buddhist",
      "icon": "temple-buddhist",
    },
    {
      "text": "Sikhský chrám",
      "value": "worship_sikh",
      "icon": "temple-sikh",
    },
    {
      "text": "Šintoistický chrám",
      "value": "worship_shinto",
      "icon": "temple-shinto",
    },
    {
      "text": "Ski areál",
      "alternativeText": "Lyžiarske stredisko, Zjadovka",
      "value": "ski",
      "icon": "ski",
    },
    {
      "text": "Pamiatka",
      "alternativeText": "Pamätník, monument",
      "value": "monument",
      "icon": "monument",
    },
    {
      "text": "Hora",
      "alternativeText": "Les, Horská oblasť",
      "value": "mountain",
      "icon": "mountain",
    },
    {
      "text": "Historická pamiatka",
      "value": "historic",
      "icon": "historic",
    },
    {
      "text": "Golfové ihrisko",
      "value": "golf",
      "icon": "golf",
    },
    {
      "text": "Lekár",
      "alternativeText": "Doktor",
      "value": "doctor",
      "icon": "doctor",
    },
    {
      "text": "Úrad",
      "alternativeText": "Úradná budova, Verejná budova",
      "value": "civid_building",
      "icon": "office-building",
    },
    {
      "text": "Kaderník",
      "alternativeText": "Kaderníctvo, Barber",
      "value": "barber",
      "icon": "hair-dryer",
    },
    {
      "text": "Pláž",
      "value": "beach",
      "icon": "beach",
    },
  ],
};