<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <!--<v-toolbar-title>{{ $t("OrderListView.page_title") }}</v-toolbar-title>-->
      <v-toolbar-title>{{ $t("ReportSettledHour.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-chart-pie</v-icon>
    </v-toolbar>
    <v-row class="mt-1" justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch v-model="mobileBreakpointSwitch" :label="$t('ReportSettledHour.mobileBreakpointSwitch_label')"
          hide-details></v-switch> </v-col>
    </v-row>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="8">
        <datetime-range-picker filled v-model="dateRange" eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" large @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col></v-row>
    <v-data-table :headers="hourHeaders" :items="gridItems" :loading="loadingData" class="elevation-1" dense
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items-per-page="62">
      <template v-slot:item="{ item }">
        <tr>
          <td :class="getDayNameClass(item.dayName)">{{ item.day }}</td>
          <td :class="getDayNameClass(item.dayName)">{{ item.dayName }}</td>
          <td v-for="hour in Array.from({length: 24}, (_, i) => i)" :key="hour" :class="getCellClass(item[`SETTLED_${hour}`])"
            :style="getCellStyle(item[`SETTLED_${hour}`])">
            <span :class="getBoldClass(item[`SETTLED_${hour}`])">{{ item[`SETTLED_${hour}`] }}</span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-spacer></v-spacer>
    <br />
    <export-data :jsonData="gridItems" :jsonFields="jsonFields" :outlined="true" dense>
      <v-btn color="info" outlined> {{ $t("btnDownloadData") }} </v-btn>
    </export-data>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" right value="true" :color="snackbar.color">
      {{ snackbar.message }} </v-snackbar><br />
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import ExportData from "../components/ExportData.vue";

export default {
  components: {
    ExportData,
  },
  data() {
    return {
      module: moduleDefaultSettings,
      localeLanguage: "sk",
      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        date: "",
      },

      gridItems: [],
      sortBy: "day",
      sortDesc: true,

      //Zoznam stlpcov pre export do excelu
      jsonFields: {
        Obdobie: { field: "day" },
        "Deň": { field: "dayName" },
        "00": { field: "SETTLED_0" },
        "01": { field: "SETTLED_1" },
        "02": { field: "SETTLED_2" },
        "03": { field: "SETTLED_3" },
        "04": { field: "SETTLED_4" },
        "05": { field: "SETTLED_5" },
        "06": { field: "SETTLED_6" },
        "07": { field: "SETTLED_7" },
        "08": { field: "SETTLED_8" },
        "09": { field: "SETTLED_9" },
        "10": { field: "SETTLED_10" },
        "11": { field: "SETTLED_11" },
        "12": { field: "SETTLED_12" },
        "13": { field: "SETTLED_13" },
        "14": { field: "SETTLED_14" },
        "15": { field: "SETTLED_15" },
        "16": { field: "SETTLED_16" },
        "17": { field: "SETTLED_17" },
        "18": { field: "SETTLED_18" },
        "19": { field: "SETTLED_19" },
        "20": { field: "SETTLED_20" },
        "21": { field: "SETTLED_21" },
        "22": { field: "SETTLED_22" },
        "23": { field: "SETTLED_23" },
      },

      loadingData: false,
      //DateRangePicker - vyber rozhadu datumov pre zoznam
      dateRange: [], //["2021-07-22","2021-07-24"],
      onlyRealized: true,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
      hourHeaders: [
        { text: "Day", value: "day", align: "start", sortable: true },
        { text: "Day Name", value: "dayName", align: "start", sortable: true },
        { text: "00", value: "SETTLED_0" },
        { text: "01", value: "SETTLED_1" },
        { text: "02", value: "SETTLED_2" },
        { text: "03", value: "SETTLED_3" },
        { text: "04", value: "SETTLED_4" },
        { text: "05", value: "SETTLED_5" },
        { text: "06", value: "SETTLED_6" },
        { text: "07", value: "SETTLED_7" },
        { text: "08", value: "SETTLED_8" },
        { text: "09", value: "SETTLED_9" },
        { text: "10", value: "SETTLED_10" },
        { text: "11", value: "SETTLED_11" },
        { text: "12", value: "SETTLED_12" },
        { text: "13", value: "SETTLED_13" },
        { text: "14", value: "SETTLED_14" },
        { text: "15", value: "SETTLED_15" },
        { text: "16", value: "SETTLED_16" },
        { text: "17", value: "SETTLED_17" },
        { text: "18", value: "SETTLED_18" },
        { text: "19", value: "SETTLED_19" },
        { text: "20", value: "SETTLED_20" },
        { text: "21", value: "SETTLED_21" },
        { text: "22", value: "SETTLED_22" },
        { text: "23", value: "SETTLED_23" },
      ],
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    this.getLocaleLanguageInISOFormat();
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //console.log("dispatcherId", dispatcherId);
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //console.log("mounted - this.dateRange", this.dateRange);

    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }

    //Niekedy pri prvom nacitany stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },

  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  methods: {
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      //console.log("getAll - dateRange ", this.dateRange);
      //Ulozit si nastavenie rozsahu datumov do cookies
      //this.setCookie("dateRange", JSON.stringify(this.dateRange), 1);
      //console.log("getAll - setCookie - dateRange",JSON.stringify(this.dateRange));
      //console.log("getAll - setCookie - showHeadersReportSettledHour", JSON.stringify(this.showHeaders));
      var localeLanguage = this.localeLanguage;
      //console.log("getAll - localeLanguage", localeLanguage);
      var dayName;
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          `/dispatching/report/order-status-hours?from=${this.dateRange[0]}&to=${this.dateRange[1]}&onlyRealized=${this.onlyRealized}`
        )
        .then((response) => {
          this.loadingData = false;
          const tempResponseData = response.data;
          // Transform each record into a { day, dayName, SETTLED_0 ... SETTLED_23 } row
          const groupedByDay = {};
          tempResponseData.forEach((item) => {
            const dayKey = item.date.substring(0, 10);
            const hour = parseInt(item.date.substring(11, 13), 10); // Parse hour as integer
            const dayName = this.$t(`weekdayName.${new Date(dayKey).getDay()}`);
            if (!groupedByDay[dayKey]) {
              groupedByDay[dayKey] = {
                day: dayKey,
                dayName: dayName,
                SETTLED_0: 0, SETTLED_1: 0, SETTLED_2: 0, SETTLED_3: 0,
                SETTLED_4: 0, SETTLED_5: 0, SETTLED_6: 0, SETTLED_7: 0,
                SETTLED_8: 0, SETTLED_9: 0, SETTLED_10: 0, SETTLED_11: 0,
                SETTLED_12: 0, SETTLED_13: 0, SETTLED_14: 0, SETTLED_15: 0,
                SETTLED_16: 0, SETTLED_17: 0, SETTLED_18: 0, SETTLED_19: 0,
                SETTLED_20: 0, SETTLED_21: 0, SETTLED_22: 0, SETTLED_23: 0,
              };
            }
            if (item.SETTLED) {
              const hourKey = `SETTLED_${hour}`;
              if (groupedByDay[dayKey][hourKey] !== undefined) {
                groupedByDay[dayKey][hourKey] += item.SETTLED;
              }
            }
          });
          this.gridItems = Object.values(groupedByDay);
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    getLocaleLanguageInISOFormat() {
      var localeLanguage = this.$store.state.appLocale;
      if (localeLanguage == "en" || localeLanguage == "us") {
        localeLanguage = "en-US";
      } else if (localeLanguage == "cs" || localeLanguage == "cz") {
        localeLanguage = "cs-CZ";
      } else if (localeLanguage == "sk") {
        localeLanguage = "sk-SK";
      } else {
        localeLanguage = "en-US";
      }
      this.localeLanguage = localeLanguage;
      //console.log("localeLanguage", localeLanguage);
      //console.log("this.localeLanguage", this.localeLanguage);
    },
    getCellClass(value) {
      return value === 0 ? 'grey--text' : '';
    },
    getCellStyle(value) {
      if (value === 0) return '';
      const min = this.getMinValue();
      const max = this.getMaxValue();
      const intensity = (value - min) / (max - min);
      const color = `rgba(255, 165, 0, ${intensity})`; // Orange color with varying opacity
      return { backgroundColor: color };
    },
    getMinValue() {
      let min = Infinity;
      this.gridItems.forEach(item => {
        for (let hour = 0; hour < 24; hour++) {
          const value = item[`SETTLED_${hour}`];
          if (value !== 0 && value < min) {
            min = value;
          }
        }
      });
      return min;
    },
    getMaxValue() {
      let max = -Infinity;
      this.gridItems.forEach(item => {
        for (let hour = 0; hour < 24; hour++) {
          const value = item[`SETTLED_${hour}`];
          if (value > max) {
            max = value;
          }
        }
      });
      return max;
    },
    getDayNameClass(dayName) {
      return (dayName === this.$t('weekdayName.6') || dayName === this.$t('weekdayName.0')) ? 'font-weight-bold' : '';
    },
    getBoldClass(value) {
      const min = this.getMinValue();
      const max = this.getMaxValue();
      return (value === min || value === max) ? 'font-weight-bold' : '';
    },
  },
};
</script>
