<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <!--<v-toolbar-title>{{ $t("OrderListView.page_title") }}</v-toolbar-title>-->
      <v-toolbar-title>{{
        $t("ReportOrderNotificationAcceptance.page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-chart-pie</v-icon>
    </v-toolbar>
    <v-row justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="
            $t('ReportOrderNotificationAcceptance.mobileBreakpointSwitch_label')
          "
          hide-details
        ></v-switch>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          :label="$t('ReportOrderNotificationAcceptance.selectedHeaders_label')"
          multiple
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption"
              >(+{{ selectedHeaders.length - 2 }})</span
            >
          </template>
        </v-select></v-col
      >
    </v-row>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="8">
        <datetime-range-picker filled v-model="dateRange" hideStartTime eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" large @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn></v-col
      ></v-row
    >
    <v-data-table
      :loading="loadingData"
      :headers="showHeaders"
      :items="gridItems"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 250, -1],
      }"
      class="elevation-1"
      :search="filter.search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      dense
      :mobile-breakpoint="mobileBreakpointTable"
    >
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td :hidden="dateHidden">
            <v-text-field
              v-model="filter.date"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.dateRange')"
            ></v-text-field>
          </td>
          <td :hidden="driverIdHidden">
            <v-text-field
              v-model="filter.driverId"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.driverId')"
            ></v-text-field>
          </td>
          <td :hidden="firstNameHidden">
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.firstName')"
            ></v-text-field>
          </td>
          <td :hidden="lastNameHidden">
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.lastName')"
            ></v-text-field>
          </td>
          <td :hidden="totalNotificationsSentHidden"></td>
          <td :hidden="ordersSentHidden"></td>
          <td :hidden="ordersAcceptedHidden"></td>
          <td :hidden="ordersNotAcceptedHidden"></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6" :hidden="dateHidden">
            <v-text-field
              v-model="filter.date"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.dateRange')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="driverIdHidden">
            <v-text-field
              v-model="filter.driverId"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.driverId')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="firstNameHidden">
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.firstName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="lastNameHidden">
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('ReportOrderNotificationAcceptance.lastName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-spacer></v-spacer>
    <br />
    <!--<download-excel
      :data="gridItems"
      worksheet="txm_export"
      name="txm_expor.xls"
      ><v-btn color="info" outlined> Download Data</v-btn>
    </download-excel>-->
    <export-data
      :jsonData="gridItems"
      :jsonFields="jsonFields"
      :outlined="true"
      dense
    >
    </export-data>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import ExportData from "../components/ExportData.vue";
import LineChart from "../components/LineChart.vue";

export default {
  components: {
    ExportData,
    LineChart,
  },
  data() {
    return {
      //Nastavenia modulov
      module: moduleDefaultSettings,

      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        date: "",
        driverId: "",
        firstName: "",
        lastName: "",
      },

      // gridHeaders: [
      //   {
      //     text: "Id",
      //     align: "start",
      //     sortable: false,
      //     value: "id",
      //   },
      //   { text: this.$t("Status"), value: "status" },
      //   { text: this.$t("Type"), value: "type" },
      //   { text: this.$t("Note"), value: "note" },
      //   {
      //     text: this.$t("CreatedAt"),
      //     value: "createdAt",
      //   },
      // ],
      gridItems: [],
      headers: [],
      selectedHeaders: [],
      headersList: [], //lista stlpcov pre vyber stlpcov
      sortBy: "date",
      sortDesc: true,
      defaultHiddenColumns: ["driverId"],
      slotList: [
        "date",
        "driverId",
        "firstName",
        "lastName",
        "totalNotificationsSent",
        "ordersSent",
        "ordersAccepted",
        "ordersNotAccepted",
      ],
      //nazvy stlpcov v tabulke, aby sa skryvali filtrovacie polia
      dateHidden: false,
      driverIdHidden: false,
      firstNameHidden: false,
      lastNameHidden: false,
      totalNotificationsSentHidden: false,
      ordersSentHidden: false,
      ordersAcceptedHidden: false,
      ordersNotAcceptedHidden: false,

      //Zoznam stlpcov pre export do excelu
      jsonFields: {
        dateRange: { field: "date" },
        driverId: { field: "driverId" },
        firstName: { field: "firstName" },
        lastName: { field: "lastName" },
        totalNotificationsSent: {
          field: "totalNotificationsSent",
          type: "decimal",
        },
        ordersSent: { field: "ordersSent", type: "decimal" },
        ordersAccepted: { field: "ordersAccepted", type: "decimal" },
        ordersNotAccepted: { field: "ordersNotAccepted", type: "decimal" },
      },

      loadingData: false,
      //DateRangePicker - vyber rozhadu datumov pre zoznam
      dateRange: [], //["2021-07-22","2021-07-24"],

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    var headersMap = this.headersMap;
    this.headers = Object.values(headersMap);
    //Potrebujeme sa zbavit hodnoty "divide" v headersList. Ale upravu som nepozil vo v-select, musel som hodnotu odstranit z headerMap. Doplnala do v-select oddelovace miesto textu. Mozno sa este zamyslim co s tym urobit.
    //divide bolo treba za ucelom formatovania v tabulke, aby boli oddlene stlpce ciarami
    /*headersMap.forEach((header) => {
      this.headersList.push({
        text: header.text,
        value: header.value,
      });
    });*/
    //this.headersList = Object.values(headersMap);
    this.selectedHeaders = this.headers;

    //Zistenie zoznamu skrytych stlpcov z Cookies
    var tempHiddenColumns = this.getCookie(
      "actualHiddenColumnsReportOrderNotificationAcceptance"
    )
      ? JSON.parse(
          this.getCookie("actualHiddenColumnsReportOrderNotificationAcceptance")
        )
      : [];
    //ak existuje cookie, nacitat ulozenu hodnotu (zoznam stlpcov, ktore sa maju skryt)
    if (tempHiddenColumns.length > 0) {
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !tempHiddenColumns.includes(el.value)
      );
    } else {
      //ak neexistuje cookie, nastavit defaultne hodnoty
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !this.defaultHiddenColumns.includes(el.value)
      );
    }
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");

    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }
    //Niekedy pri prvom nacitany stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },

  watch: {
    showHeaders: function () {
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      var tempShowHearders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          tempShowHearders.some(function (e) {
            return e.value == item;
          })
        ) {
          this[item + "Hidden"] = false;
        } else {
          this[item + "Hidden"] = true;
        }
      });
    },
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },

    headersMap() {
      return [
        {
          text: this.$t("ReportOrderNotificationAcceptance.dateRange"),
          value: "date",
          align: "start",
          sortable: true,
          filter: (value) => {
            if (!this.filter.date) {
              return value;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.date.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderNotificationAcceptance.driverId"),
          value: "driverId",
          align: "end",
          sortable: true,
          filter: (value) => {
            if (!this.filter.driverId) {
              return value;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.driverId.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderNotificationAcceptance.firstName"),
          value: "firstName",
          align: "end",
          sortable: true,
          filter: (value) => {
            if (!this.filter.firstName) {
              return value;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.firstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderNotificationAcceptance.lastName"),
          value: "lastName",
          align: "end",
          sortable: true,
          filter: (value) => {
            if (!this.filter.lastName) {
              return value;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.lastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t(
            "ReportOrderNotificationAcceptance.totalNotificationsSent"
          ),
          value: "totalNotificationsSent",
          align: "end",
          sortable: true,
        },
        {
          text: this.$t("ReportOrderNotificationAcceptance.ordersSent"),
          value: "ordersSent",
          align: "end",
        },
        {
          text: this.$t("ReportOrderNotificationAcceptance.ordersAccepted"),
          value: "ordersAccepted",
          align: "end",
        },
        {
          text: this.$t("ReportOrderNotificationAcceptance.ordersNotAccepted"),
          value: "ordersNotAccepted",
          align: "end",
        },
      ];
    },
  },

  methods: {
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      //Ulozit si nastavenie rozsahu datumov do cookies
      //this.setCookie("dateRange", JSON.stringify(this.dateRange), 1);
      this.setHiddenColumnListCookie();
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/order-notification-acceptance?from=${this.dateRange[0]}&to=${this.dateRange[1]}`
        )
        .then((response) => {
          //console.log("response", response);
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },

    setHiddenColumnListCookie() {
      //aktualny zoznam poli, ktore sa nemaju zobrazit, ulozit do cookies
      var actualHiddenColumns = [];
      //compare slotList and showHeaders
      var tempShowHeaders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          !tempShowHeaders.some(function (e) {
            return e.value == item;
          })
        ) {
          actualHiddenColumns.push(item);
        }
      });
      this.setCookie(
        "actualHiddenColumnsReportOrderNotificationAcceptance",
        JSON.stringify(actualHiddenColumns),
        30
      );
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },

    //Konverzia mesiaca na nazov
    toMonthName(monthNumber) {
      //console.log("toMonthName: " + monthNumber);
      if (isNaN(monthNumber)) {
        //Ak nie je cislo, konvertova na cislo
        monthNumber = parseInt(monthNumber);
      }
      var localeLanguage = this.$store.state.appLocale;
      if (localeLanguage == "en" || localeLanguage == "us") {
        localeLanguage = "en-US";
      } else if (localeLanguage == "cs" || localeLanguage == "cz") {
        localeLanguage = "cs-CZ";
      } else if (localeLanguage == "sk") {
        localeLanguage = "sk-SK";
      } else {
        localeLanguage = "en-US";
      }
      //console.log("localeLanguage", localeLanguage);
      const date = new Date();
      date.setMonth(monthNumber - 1);
      //console.log("date", date);
      return date.toLocaleString(localeLanguage, {
        month: "long",
      });
    },
  },
};
</script>
